import { LabelInValue } from 'domain/app/types'
import { getFieldName } from 'domain/createOrder/shared'
import { MaskedField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput, NumberInput } from 'ui'
import config from '../pesquisaDividaAtivaPGEConfig'

interface Props {
  itemId: number
  selectedType: LabelInValue
  label?: string
}

export default function RegisterInput({ itemId, selectedType }: Props) {
  const { label, value } = selectedType

  if (value === 'cpf') {
    return <CPFInput itemId={itemId} required />
  }

  if (value === 'cnpj') {
    return <CNPJInput itemId={itemId} required />
  }

  if (value === 'inscricao-estadual') {
    return <MaskedField itemId={itemId} field={inscricao_estadual} required />
  }

  return (
    <NumberInput
      label={label}
      name={getFieldName('inscricao', itemId)}
      maxLength={15}
      required
    />
  )
}

const { inscricao_estadual } = config.dynamicFields
