/*
 * Adiciona em massa ao carrinho os resultados de pesquisa
 */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, MenuProps } from 'antd'

import { errorHandler } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { MissingFieldsError } from 'domain/createOrder'
import { MissingFields } from 'domain/createOrder/types'
import { useGetOrderItem } from 'domain/orderItem'
import { useResultsToOrder } from 'domain/orderItem/research'
import { ResearchResult } from 'domain/orderItem/types'
import { Button } from 'ui'

import useAddResearchResultsToCart from '../services/useAddResearchResultsToCart'
import OrderMissingFieldsModal from './OrderMissingFieldsModal'
import { ResultCode } from 'domain/orders/types'

export default function OrderAllResearchResultsButton() {
  const [loading, setLoading] = useState(false)
  const [missingFields, setMissingFields] = useState([] as MissingFields[])
  const history = useHistory()
  const orderItem = useGetOrderItem()
  const addResearchResultsToCart = useAddResearchResultsToCart()

  const onClick = async (results: ResearchResult[]) => {
    try {
      setLoading(true)
      await addResearchResultsToCart(results as any, orderItem)
      history.push(RoutePaths.SHOPPING_CART)
    } catch (error) {
      if (error instanceof MissingFieldsError) {
        setMissingFields(error.data)
      } else {
        setLoading(false)
        errorHandler(error, { code: '0x695' })
      }
    }
  }

  const onClose = () => {
    setLoading(false)
    setMissingFields([])
  }

  return (
    <>
      <OrderButtonByResult onClick={onClick} loading={loading} />
      <OrderMissingFieldsModal missingFields={missingFields} onClose={onClose} />
    </>
  )
}

function OrderButtonByResult({
  onClick,
  loading,
}: {
  onClick: (results: ResearchResult[]) => void
  loading: boolean,
}) {
  const orderItem = useGetOrderItem()
  const settings = orderItem.researchResultsOrderSettings!
  const resultsToOrder = useResultsToOrder(orderItem)
  const { any, positive, negative } = resultsToOrder

  if (settings.negative && settings.positive) {
    const items: MenuProps['items'] = [
      {
        key: 'positive',
        label: 'Resultados Positivos',
        onClick: () => {
          onClick(positive.resultsToOrder)
        },
        disabled: !positive.canOrder,
        className: !positive.canOrder ? 'text-gray-400' : undefined,
        title: !positive.canOrder ? positive.reason : undefined,
      },
      {
        key: 'negative',
        label: 'Resultados Negativos',
        onClick: () => {
          onClick(negative.resultsToOrder)
        },
        disabled: !negative.canOrder,
        className: !negative.canOrder ? 'text-gray-400' : undefined,
        title: !negative.canOrder ? negative.reason : undefined,
      },
    ]

    return (
      <Dropdown menu={{ items }} disabled={!any.canOrder}>
        <Button
          onClick={e => e.preventDefault()}
          loading={loading}
          icon="registro-civil"
          type="link"
          className="text-gray-700 font-bold"
          title={any.canOrder ? any.reason : undefined}
        >
          Pedir todas as certidões
        </Button>
      </Dropdown>
    )
  }

  const resultCode = orderItem.result! as (ResultCode.POSITIVE | ResultCode.NEGATIVE)

  return (
    <Button
      onClick={() => onClick(resultsToOrder[resultCode].resultsToOrder)}
      loading={loading}
      disabled={!any.canOrder}
      icon="registro-civil"
      type="link"
      className="text-gray-700 font-bold"
      title={any.canOrder ? any.reason : undefined}
    >
      Pedir todas as certidões
    </Button>
  )
}
