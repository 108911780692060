import React from 'react'
import { Button, ButtonProps } from 'antd'

import { useOpenAIDownload } from 'domain/openAI/reports'
import { Icon } from 'ui'

interface Props extends Omit<ButtonProps, 'onClick'> {
  orderNumber: number
  orderId: number
  label?: string
  showIcon?: boolean
}

export default function DownloadAIResultsButton({
  orderNumber,
  orderId,
  label = 'Baixar todos os dados extraídos',
  className = '',
  showIcon,
  ...rest
}: Props) {
  const { download, loading } = useOpenAIDownload({
    orderNumber: orderNumber,
    orderId: orderId,
  })

  return (
    <Button
      type="primary"
      onClick={download}
      loading={loading}
      title="Download dos dados extraídos pela Extração de Dados"
      icon={showIcon && <Icon name="robot" className="mr-2" size={18} />}
      className={`flex flex-row items-center ${className}`}
      {...rest}
    >
      {label}
    </Button>
  )
}
