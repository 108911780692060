import React, { useReducer } from 'react'

import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { OrderItem } from 'domain/orderItem/types'
import { CreateRecurrence } from 'domain/recurrences'
import { Button } from 'ui'

interface Props {
  orderItem: OrderItem
}

export default function RecurrenceButton({ orderItem }: Props) {
  const [openModal, toggleModal] = useReducer(prev => !prev, false)
  const hasPermission = useHasRecurrencePermission()

  return (
    <>
      <Button
        icon="calendar-time"
        onClick={toggleModal}
        disabled={orderItem.isFromPassportWithoutAllData || !hasPermission}
        title={
          orderItem.isFromPassportWithoutAllData
            ? 'Este pedido não tem todos os dados necessários para criar uma Recorrência'
            : getNoPermissionText(hasPermission)
        }
        type="link"
        className="text-gray-700 font-bold"
        size="small"
      >
        Criar Recorrência
      </Button>

      <CreateRecurrence
        openModal={openModal}
        onCloseModal={toggleModal}
        items={[orderItem]}
        kind="order-items"
      />
    </>
  )
}
