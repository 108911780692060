import { useServiceForm } from 'domain/createOrder/kit'
import { useSetDynamicFields } from 'domain/createOrder/kit/state'
import { useExtraInformationQuery } from 'domain/orderItem/shared'
import { CreateOrderForm } from 'domain/createOrder/types'
import { KitItem } from 'domain/kits/types'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { getServiceConfig } from 'domain/servicesCBRdoc/products/services'

import CertidaoImovelType from './CertidaoImovelType'
import CertidaoImovelSearchFor from './CertidaoImovelSearchFor'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoImovel({ kitItem }: Props) {
  const { id, service } = kitItem
  const [formData, updateForm] = useServiceForm(id)
  const { buscar_por, tipo, url_cartorio, url_cidade, url_uf } = formData
  const location = { url_cartorio, url_cidade, url_uf }

  if (!url_cartorio) return null

  return (
    <>
      <CertidaoImovelType
        itemId={id}
        service={service}
        location={location}
        onChange={tipo => {
          updateForm({ tipo, buscar_por: undefined })
        }}
      />

      {tipo && (
        <CertidaoImovelSearchFor
          itemId={id}
          service={service}
          location={location}
          type={tipo}
          onChange={buscar_por => updateForm({ buscar_por })}
        />
      )}

      {buscar_por && <KitImovelDynamicFields kitItem={kitItem} formData={formData} />}
    </>
  )
}

function KitImovelDynamicFields({ kitItem, formData }: { kitItem: KitItem; formData: CreateOrderForm }) {
  const { id, service } = kitItem
  const { buscar_por, tipo, ...selectedLocation } = formData
  const location = getLocationUrl(selectedLocation)
  const setDynamicFields = useSetDynamicFields(id)

  useExtraInformationQuery<FieldName[]>({
    service,
    information: 'campos-necessarios',
    params: {
      ...location,
      tipo,
      buscar_por,
    },
    itemId: id,
    onSuccess: (data) => {
      const serviceConfig = getServiceConfig(service.code)
      const fields = data?.map(fieldName => serviceConfig.getField(fieldName)) ?? []
      setDynamicFields(fields as FieldConfig[])
    },
  })

  return null
}
