import { SVGProps } from 'react'
import {
  AiOutlineAudit,
  AiOutlineBell,
  AiOutlineCar,
  AiOutlineDelete,
  AiOutlineDollarCircle,
  AiOutlineDownload,
  AiOutlineEdit,
  AiOutlineFileDone,
  AiOutlineFileSearch,
  AiOutlineFileSync,
  AiOutlineFileText,
  AiOutlineFolderOpen,
  AiOutlineRedo,
  AiOutlineInfoCircle,
  AiOutlineLineChart,
  AiOutlineMail,
  AiOutlineMinus,
  AiOutlinePaperClip,
  AiOutlinePlus,
  AiOutlineAlert,
  AiOutlineRetweet,
  AiOutlineMore,
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineQuestionCircle,
  AiOutlineRobot,
  AiOutlineSetting,
  AiOutlineShoppingCart,
  AiOutlineTags,
  AiOutlineWallet,
  AiOutlineWhatsApp,
  AiOutlineSelect,
  AiOutlineFilter,
  AiOutlineBarChart,
  AiOutlinePlayCircle,
  AiOutlineHome,
  AiOutlineExclamationCircle,
  AiOutlineDown,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineUp,
} from 'react-icons/ai'
import { BiSolidStar } from 'react-icons/bi'
import { FcGoogle } from 'react-icons/fc'
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiArrowLeft,
  FiArrowRight,
  FiAward,
  FiCheck,
  FiCheckCircle,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiCopy,
  FiClock,
  FiEdit3,
  FiEye,
  FiFileMinus,
  FiFilePlus,
  FiFilter,
  FiFlag,
  FiFolderPlus,
  FiFolderMinus,
  FiHelpCircle,
  FiLink,
  FiList,
  FiMail,
  FiMapPin,
  FiMenu,
  FiMoreHorizontal,
  FiMoreVertical,
  FiMinusCircle,
  FiPlay,
  FiPlusCircle,
  FiPrinter,
  FiRepeat,
  FiShare2,
  FiTag,
  FiUpload,
  FiUser,
  FiX,
  FiStar,
} from 'react-icons/fi'
import { HiOutlineHome } from 'react-icons/hi2'
import { ImHourGlass } from 'react-icons/im'
import { IoIosSearch } from 'react-icons/io'
import { IoRefresh } from 'react-icons/io5'
import { LiaBarcodeSolid } from 'react-icons/lia'
import { LuRectangleHorizontal } from 'react-icons/lu'
import {
  PiChecksLight,
  PiDotsSixVertical,
  PiEnvelopeSimpleLight,
  PiEnvelopeSimpleOpenLight,
  PiExport,
  PiFilePdf,
  PiFileXls,
  PiFolderSimplePlus,
  PiGavelThin,
  PiMegaphoneLight,
} from 'react-icons/pi'
import { RiDriveLine } from 'react-icons/ri'
import { RxCross1 } from 'react-icons/rx'
import { SlBriefcase, SlClose } from 'react-icons/sl'
import {
  TbCalendar,
  TbCalendarCancel,
  TbCalendarTime,
  TbFileCertificate,
  TbMenuDeep,
  TbFileTextAi,
  TbFolderShare,
  TbFilePencil,
} from 'react-icons/tb'
import { TfiLightBulb, TfiCrown } from 'react-icons/tfi'
import { VscHome } from 'react-icons/vsc'

import { ReactComponent as IconFile } from '../../assets/icons/icon-file.svg'
import { ReactComponent as IconLogout } from '../../assets/icons/icon-logout.svg'
import { ReactComponent as IconPaperPlane } from '../../assets/icons/icon-paper-plane.svg'
import { ReactComponent as IconFederaisEstaduais } from '../../assets/icons/icon-federais-estaduais.svg'
import { ReactComponent as IconRelatorio } from '../../assets/icons/icon-relatorio.svg'
import { ReactComponent as IconPencilLine } from '../../assets/icons/icon-pencil-line.svg'
import { ReactComponent as IconMoneyHands } from '../../assets/icons/icon-money-hands.svg'
import { ReactComponent as IconSerasa } from '../../assets/icons/icon-serasa.svg'
import { ReactComponent as IconMagnifyingFile } from '../../assets/images/ilustration-magnifying-file.svg'
import { ReactComponent as IconWarningFile } from '../../assets/images/ilustration-warning-file.svg'
import { ReactComponent as IconErrorFile } from '../../assets/images/ilustration-error-file.svg'
import { ReactComponent as IconResultadoPosivito } from '../../assets/icons/icone-resultado-positivo.svg'
import { ReactComponent as IconResultadoNegativo } from '../../assets/icons/icone-resultado-negativo.svg'
import { ReactComponent as IconPdfFile } from '../../assets/icons/icon-pdf-file.svg'
import MicrosoftIcon from './icons/MicrosoftIcon'

interface Props extends SVGProps<any> {
  name: IconName | undefined
  size?: number
  className?: string
}

export default function Icon({
  name = 'registro-civil',
  color = 'currentColor',
  size = 20,
  strokeWidth = 1.3,
  className,
  ...props
}: Props) {
  const Component = availableIcons[name] ?? availableIcons['registro-civil']

  return (
    <Component
      color={color}
      size={size}
      strokeWidth={strokeWidth}
      className={'inline ' + className}
      {...props}
    />
  )
}

export type IconName = keyof typeof availableIcons

const availableIcons = {
  alert: FiAlertTriangle,
  'alert-circle': AiOutlineExclamationCircle,
  attachment: AiOutlinePaperClip,
  'arrow-left': FiArrowLeft,
  'arrow-right': FiArrowRight,
  award: FiAward,
  barChart: AiOutlineBarChart,
  barcode: LiaBarcodeSolid,
  bell: AiOutlineBell,
  briefcase: SlBriefcase,
  calendar: TbCalendar,
  'calendar-cancel': TbCalendarCancel,
  'calendar-time': TbCalendarTime,
  cart: AiOutlineShoppingCart,
  chart: AiOutlineLineChart,
  check: FiCheck,
  'checkmark-circle': FiCheckCircle,
  'check-all': PiChecksLight,
  'chevron-down': FiChevronDown,
  'chevron-down-alt': AiOutlineDown,
  'chevron-left': FiChevronLeft,
  'chevron-left-alt': AiOutlineLeft,
  'chevron-right': FiChevronRight,
  'chevron-right-alt': AiOutlineRight,
  'chevron-up': FiChevronUp,
  'chevron-up-alt': AiOutlineUp,
  close: FiX,
  clock: FiClock,
  'cross-circle': SlClose,
  cross: RxCross1,
  crown: TfiCrown,
  copy: FiCopy,
  diligencias: SlBriefcase,
  document: AiOutlineFileText,
  download: AiOutlineDownload,
  'drag-handler': PiDotsSixVertical,
  edit: AiOutlineEdit,
  envelope: PiEnvelopeSimpleLight,
  'envelope-open': PiEnvelopeSimpleOpenLight,
  email: AiOutlineMail,
  ellipsis: FiMoreHorizontal,
  'exclamation-circle': FiAlertCircle,
  export: PiExport,
  external: AiOutlineSelect,
  eye: FiEye,
  file: IconFile,
  'file-pdf': PiFilePdf,
  'file-xls': PiFileXls,
  'file-plus': FiFilePlus,
  'file-loading': AiOutlineFileSync,
  'file-done': AiOutlineFileDone,
  filter: AiOutlineFilter,
  flag: FiFlag,
  folder: AiOutlineFolderOpen,
  'folder-plus': PiFolderSimplePlus,
  'folder-move': TbFolderShare,
  'folder-minus': FiFolderMinus,
  google: FcGoogle,
  'google-drive': RiDriveLine,
  'help-circle': AiOutlineQuestionCircle,
  'help-tooltip': FiHelpCircle,
  home: AiOutlineHome,
  hourglass: ImHourGlass,
  'ia-analise-documento': AiOutlineRobot,
  'ia-documento': TbFileTextAi,
  invoice: AiOutlineDollarCircle,
  info: AiOutlineInfoCircle,
  link: FiLink,
  'light-bulb': TfiLightBulb,
  logout: IconLogout,
  mail: FiMail,
  'menu-left': TbMenuDeep,
  microsoft: MicrosoftIcon,
  municipais: IconFederaisEstaduais,
  money: AiOutlineWallet,
  'money-hands': IconMoneyHands,
  'minus-circle': FiMinusCircle,
  minus: AiOutlineMinus,
  plus: AiOutlinePlus,
  'magnifying-file': IconMagnifyingFile,
  'warning-file': IconWarningFile,
  'error-file': IconErrorFile,
  'alert-alt': AiOutlineAlert,
  'caret-left': AiFillCaretLeft,
  'caret-right': AiFillCaretRight,
  estaduais: IconFederaisEstaduais,
  federais: IconFederaisEstaduais,
  'federais-estaduais': IconFederaisEstaduais,
  'more-vertical': FiMoreVertical,
  'more-vertical-alt': AiOutlineMore,
  nav: FiMenu,
  news: PiMegaphoneLight,
  notas: TbFilePencil,
  'outras-certidoes': TbFileCertificate,
  'p-bens-imoveis': HiOutlineHome,
  'p-bens-moveis': AiOutlineCar,
  'p-debitos-pendencias': FiFileMinus,
  'p-pessoas-empresas': FiUser,
  'paper-plane': IconPaperPlane,
  'pencil-line': IconPencilLine,
  'pin-map': FiMapPin,
  pesquisa: IoIosSearch,
  play: AiOutlinePlayCircle,
  'plus-circle': FiPlusCircle,
  print: FiPrinter,
  protesto: PiGavelThin,
  'registro-civil': TbFileCertificate,
  'registro-imoveis': HiOutlineHome,
  'registro-titulos-documentos': AiOutlineAudit,
  registros: AiOutlineAudit,
  relatorio: IconRelatorio,
  repeat: FiRepeat,
  'repeat-alt': AiOutlineRetweet,
  redo: IoRefresh,
  'redo-alt': AiOutlineRedo,
  'resultado-positivo': IconResultadoPosivito,
  'resultado-negativo': IconResultadoNegativo,
  'pdf-file': IconPdfFile,
  robot: AiOutlineRobot,
  search: IoIosSearch,
  'search-file': AiOutlineFileSearch,
  send: FiUser,
  settings: AiOutlineSetting,
  serasa: IconSerasa,
  share: FiShare2,
  stamp: AiOutlineAudit,
  star: FiStar,
  'star-filled': BiSolidStar,
  tag: FiTag,
  tags: AiOutlineTags,
  trash: AiOutlineDelete,
  upload: FiUpload,
  user: FiUser,
  'view-card': LuRectangleHorizontal,
  'view-table': FiList,
  warning: FiAlertTriangle,
  wallet: AiOutlineWallet,
  whatsapp: AiOutlineWhatsApp,
}
