import React, { useEffect, useMemo } from 'react'
import { Divider, Form } from 'antd'
import { FieldData } from 'rc-field-form/es/interface'

import { ApiStatus } from 'api/types'
import {
  CreateRecurrenceForm,
  Recurrence,
  RecurrenceForm as RecurrenceFormType,
} from 'domain/recurrences/types'
import { CheckboxInput, SubmitButton, TextInput } from 'ui'
import { displayDay } from 'utils/dateTime'

import AlertLastDayOfMonth from './AlertLastDayOfMonth'
import FrequencyDaysInput from './FrequencyDaysInput'
import FrequencySelect from './FrequencySelect'
import FrequencyMessage from './FrequencyMessage'
import DaysOfWeekInput from './DaysOfWeekInput'
import GroupsInput from './GroupsInput'
import StartAtInput from './StartAtInput'
import { CertificateCode } from 'domain/servicesCBRdoc/types'
import { useAllServices } from 'domain/servicesCBRdoc'

type Props = {
  header?: React.ReactNode
  buttonLabel: string
  formData: RecurrenceFormType
  setFormData: React.Dispatch<React.SetStateAction<RecurrenceFormType>>
  recurrence?: Recurrence
  onFinish: (data: RecurrenceFormType) => void
  status: ApiStatus
}

export default function RecurrenceForm({
  header,
  buttonLabel,
  formData,
  setFormData,
  onFinish,
  status,
  recurrence,
}: Props) {
  const [form] = Form.useForm()
  const services = useAllServices()

  const isMonthlyAnd31thDay =
    formData.frequency === 'monthly' && formData.startsAt && displayDay(formData.startsAt) === '31'

  const onFieldsChange = (field: FieldData[]) => {
    const [prop] = field[0].name
    const value = field[0].value

    setFormData({ ...formData, [prop]: value } as any)
  }

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData)
    }
  }, [formData])

  const showCheckbox = useMemo(() => {
    const fromRecurrence = !!recurrence?.items.find(i => i.serviceCanBeMonitored)
    const findService = (formData as CreateRecurrenceForm).items && services?.filter(s =>
      (formData as CreateRecurrenceForm).items?.map((i: any) => i.service?.id).includes(s?.id)
    )
    const serviceCanBeMonitored = !!findService?.find(f => f.canBeMonitored)
    return fromRecurrence || serviceCanBeMonitored
  }, [recurrence, formData, services])

  return (
    <Form
      form={form}
      initialValues={formData as any}
      requiredMark={false}
      layout="vertical"
      onFinish={() => {
        onFinish(formData)
        form.resetFields()
      }}
      onFieldsChange={onFieldsChange}
    >
      {header}

      <TextInput
        label="Nome da recorrência"
        name="name"
        placeholder="Nome da recorrência"
        maxLength={45}
        required
        autoFocus
      />

      <StartAtInput onChange={startsAt => setFormData({ ...formData, startsAt })} />

      {isMonthlyAnd31thDay && <AlertLastDayOfMonth />}

      <FrequencySelect />

      {formData.frequency === 'every_x_days' && <FrequencyDaysInput />}

      {formData.frequency === 'weekly' && (
        <DaysOfWeekInput
          value={formData.daysOfWeek}
          onChange={daysOfWeek => setFormData({ ...formData, daysOfWeek })}
        />
      )}

      {formData.startsAt && !!formData.frequency && (
        <FrequencyMessage
          daysOfWeek={formData.daysOfWeek}
          everyXDays={formData.everyXDays}
          frequency={formData.frequency}
          startsAt={formData.startsAt}
        />
      )}

      <Divider />

      <GroupsInput groups={formData.groups} onChange={groups => setFormData({ ...formData, groups })} />

      {showCheckbox && (
        <>
          <Divider />
          <CheckboxInput name="notify">
            Desejo ser notificado caso existam atualizações na Pesquisa de Junta Comercial
          </CheckboxInput>
        </>
      )}

      <SubmitButton label={buttonLabel} loading={status === 'loading'} />
    </Form>
  )
}

const SERVICES_THAT_CAN_BE_MONITORED = [CertificateCode.CERTIDAO_JUNTA_COMERCIAL]
