import React, { useEffect } from 'react'

import { FORBIDDEN, NOT_FOUND } from 'api/errors'
import { useCurrentUser } from 'domain/auth'
import { OrderItem } from 'domain/orderItem/types'
import { OrderLatestView } from 'domain/orders/types'
import { productsViews } from 'domain/servicesCBRdoc/products'
import { ResearchCode, ServiceType } from 'domain/servicesCBRdoc/types'
import { Loading } from 'ui'
import { getStorageData, setStorageData } from 'utils/localStorage'

import ViewAI from '../../ai/components/ViewAI'
import ViewCertificate from '../../certificate/components/ViewCertificate'
import ViewDocRegistration from '../../documentRegistration/components/ViewDocRegistration'
import ViewDueDiligence from '../../dueDiligence/components/ViewDueDiligence'
import ViewResearch from '../../research/components/ViewResearch'
import useOrderItemQuery from '../services/useOrderItemQuery'
import { useGetOrderItem, useOrderItem } from '../state/viewOrderItem'
import AlertViewOrderPermission from './error/AlertViewOrderPermission'
import OrderItemError from './error/OrderItemError'

function ViewOrderItem({ orderItemId }: { orderItemId: number }) {  
  const { data, isLoading, isError, error } = useOrderItemQuery(orderItemId)

  if (isLoading || !data?.id) {
    return <Loading message="Carregando dados do pedido..." />
  }

  if (isError) {
    if ((error as any).code === FORBIDDEN) {
      return <AlertViewOrderPermission />
    }

    return (
      <OrderItemError
        message={
          isError && (error as any).code === NOT_FOUND ? 'Pedido inexistente' : 'Erro ao carregar o pedido'
        }
      />
    )
  }

  return <OrderItemProvider orderItem={data} />
}

function OrderItemProvider({ orderItem }: { orderItem: OrderItem }) {
  const [currentItem, setOrderItem] = useOrderItem()

  useEffect(() => {
    setOrderItem(orderItem)
  }, [orderItem])

  if (!currentItem?.id) return null

  return <ViewOrderItemService />
}

function ViewOrderItemService() {
  const orderItem = useGetOrderItem()
  const isDueDiligence = orderItem.service.type === ServiceType.DILIGENCE
  const isCertificate = orderItem.service.type === ServiceType.CERTIFICATE
  const isDocRegistration = orderItem.service.type === ServiceType.DOCUMENT_REGISTRATION
  const isAI = orderItem.service.type === ServiceType.AI

  useSaveLatestView()

  const ProductView = productsViews[orderItem.service.code as ResearchCode]

  if (ProductView) {
    return <ProductView />
  }

  if (isDueDiligence) {
    return <ViewDueDiligence />
  }

  if (isAI) {
    return <ViewAI />
  }

  if (isCertificate) {
    return <ViewCertificate />
  }

  if (isDocRegistration) {
    return <ViewDocRegistration />
  }

  return <ViewResearch />
}

function useSaveLatestView() {
  const user = useCurrentUser()
  const orderItem = useGetOrderItem()

  useEffect(() => {
    const localKey = `purchasesLatestViews_${user.id}`
    const latestView: OrderItem[] = getStorageData(localKey, [])
    const validItems = latestView.filter(o => o.id !== orderItem.id)

    const currentView: OrderLatestView = {
      date: new Date(),
      id: orderItem.id,
      name: orderItem.name,
      serviceCode: orderItem.service.code,
    }
    const newList = [currentView, ...validItems].slice(0, 5)

    setStorageData(localKey, newList)
  }, [orderItem.id])
}

export default ViewOrderItem
