import { federalRegions } from 'domain/app/data'
import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { REGIAO, TIPO_PESSOA } = FieldName

type CertidaoDistribuicaoFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.ORGAO
  | FieldName.REGIAO
  | FieldName.TIPO,
  FieldConfig
>

type CertidaoDistribuicaoDynamicFields = Record<FieldName.FILTRO | FieldName.INSTANCIA, FieldConfig>

class CertidaoTRFDistribuicao extends Certificate {
  formFields: CertidaoDistribuicaoFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: {
      ...Fields.nome_pf,
      placeholder: 'Nome do outorgado na distribuição',
    },
    nome_pj: {
      ...Fields.nome_pj,
      placeholder: 'Nome da empresa outorgada na distribuição',
    },
    orgao: Fields.orgao,
    regiao: {
      ...Fields.regiao,
      apiExtraInfo: undefined,
      options: federalRegions,
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
      label: 'Região do Tribunal Federal',
      stateKey: 'selectedRegion'
    },
    tipo: {
      ...Fields.tipo,
      kind: 'checkboxGroup',
    },
  }

  dynamicFields: CertidaoDistribuicaoDynamicFields = {
    filtro: {
      ...Fields.filtro,
      kind: 'radio',
    },
    instancia: Fields.instancia,
  }

  constructor() {
    super({
      availableFormatsFields: [REGIAO],
      extraInfoFields: [REGIAO],
      id: 24,
      priceByFields: [TIPO_PESSOA, REGIAO],
      shortName: 'TRF Distribuição',
      splittableField: FieldName.TIPO,
      steps: ['dados'],
      placeKitDynamicFieldsAtBottom: true,
    })
  }
}

const service = new CertidaoTRFDistribuicao().initialize()

export default service
