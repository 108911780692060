import React, { useState } from 'react'
import { notification } from 'antd'

import {
  CreateRecurrenceItem,
  CreateRecurrenceForm as CreateRecurrenceFormType,
  CreateRecurrenceKind,
} from 'domain/recurrences/types'
import { Modal } from 'ui'
import { removeFromArrayById } from 'utils/transformData'

import useCreateRecurrence from '../../services/useCreateRecurrence'
import RecurrenceForm from './RecurrenceForm'
import CreateRecurrenceItems from './CreateRecurrenceItems'

type Props = {
  kind: CreateRecurrenceKind
  items: CreateRecurrenceItem[]
  openModal: boolean
  onCloseModal: () => void
  onSuccess?: () => void
}

export default function CreateRecurrence({ items, kind, openModal, onCloseModal, onSuccess }: Props) {
  return (
    <Modal title="Criar Recorrência" open={openModal} onCancel={onCloseModal} destroyOnClose>
      <CreateRecurrenceForm
        onSuccess={() => {
          onCloseModal()

          notification.success({
            message: 'Recorrência criada com sucesso',
            description: 'Você pode visualizar e editar os pedidos recorrentes no menu lateral em “Recorrências”',
          })

          onSuccess && onSuccess()
        }}
        items={items}
        kind={kind}
      />
    </Modal>
  )
}

function CreateRecurrenceForm({
  items,
  kind,
  onSuccess,
}: Pick<Props, 'items' | 'kind'> & { onSuccess: () => void }) {
  const [formData, setFormData] = useState<CreateRecurrenceFormType>({
    name: '',
    startsAt: undefined,
    frequency: '',
    items,
    daysOfWeek: [],
    groups: [],
    notify: false,
  })

  const { status, submit } = useCreateRecurrence({
    kind,
    onSuccess,
  })

  const onRemoveItem = (itemId: number) => {
    const items = removeFromArrayById(formData.items, itemId)
    setFormData({ ...formData, items })
  }

  return (
    <RecurrenceForm
      header={
        kind === 'order-items' && (
          <CreateRecurrenceItems items={formData.items} kind={kind} onRemove={onRemoveItem} />
        )
      }
      formData={formData}
      setFormData={setFormData as any}
      status={status}
      onFinish={submit as any}
      buttonLabel="Criar recorrência"
    />
  )
}
