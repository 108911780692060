import { LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { ExtraInfoRadioGroup, RadioGroupField, RadioGroupFieldProps } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoJusticaEstadualConfig'

interface Props extends Omit<RadioGroupFieldProps, 'field'> {
  service: SelectedService
  federativeUnit: string
  legalEntity: LegalEntity | undefined
  options?: any[]
}

export default function InstanciaField({
  service,
  federativeUnit,
  legalEntity,
  itemId,
  onChange,
  options,
  ...props
}: Props) {
  if (options) {
    return (
      <RadioGroupField
        field={config.dynamicFields.instancia}
        options={options}
        itemId={itemId}
        onChange={onChange}
        {...props}
      />
    )
  }

  const params: any = {
    tipo_pessoa: legalEntity,
    url_uf: federativeUnit,
  }

  return (
    <ExtraInfoRadioGroup
      field={config.dynamicFields.instancia}
      service={service}
      params={params}
      itemId={itemId}
      onChange={onChange}
      {...props}
    />
  )
}
