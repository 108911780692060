import { DocRegistrationProcessing } from 'domain/orderItem/documentRegistration'
import { OrderSuccessfulMessage } from 'domain/createOrder/shared'
import { useGetOrderItem } from '../../state/viewOrderItem'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { Icon } from 'ui'

export default function ViewOrderProcessing() {

  return (
    <div className="pr-6 pl-8 flex flex-col flex-1 items-center bg-opacity-0">
      <div className="w-full bg-white flex flex-col items-center rounded-lg gap-8 mb-4 py-14">
        <Icon name="magnifying-file"/>

        <div className="flex max-w-lg flex-col gap-4">
          <h1 className="font-bold text-xl text-center subpixel-antialiased">Pedido em processamento</h1>
          <StatusDescription />
        </div>

        <OrderSuccessfulMessage />
      </div>
    </div>
  )
}

function StatusDescription() {
  const orderItem = useGetOrderItem()

  if (orderItem.service.type === ServiceType.DOCUMENT_REGISTRATION) {
    return <DocRegistrationProcessing />
  }

  return (
    <p className="text-center text-sm">
      Seu pedido já está em andamento.{' '}
      {orderItem.service.type === ServiceType.AI
        ? 'Em instantes, a Extração de Dados finalizará a leitura dos documentos.'
        : 'Em breve, teremos novidades sobre sua localização.'}
    </p>
  )
}
