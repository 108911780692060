import React from 'react'

import { FeedbackMessage } from 'domain/app'
import { useGetOrderItem } from 'domain/orderItem'
import { StatusCode } from 'domain/orders/types'
import { Icon } from 'ui'

function ViewDocRegistrationStatus({ children }: { children: React.ReactNode }) {
  const orderItem = useGetOrderItem()

  if (!orderItem.fileURL && orderItem.status === StatusCode.FINALIZADO) {
    return (
      <FeedbackMessage
        title="Pedido finalizado"
        message={`
            Infelizmente, não foi possível realizar o registro do documento. 
            Verifique as informações e tente novamente, ou entre em contato para saber mais detalhes.
          `}
        alertType="error"
        icon={<Icon name="checkmark-circle" className="text-green-500 text-6xl mt-5 mb-5 text-secondary-500" />}
      />
    )
  }

  return children as JSX.Element
}

export default ViewDocRegistrationStatus
