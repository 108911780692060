import { convertToSelectedService } from 'domain/createOrder/document'
import { convertExplorerItem, convertOCR } from 'domain/myFiles'
import { convertCreateOrderModel } from 'domain/openAI/models'
import { convertResearchResults } from 'domain/orderItem/research'
import { OrderItem, OrderItemListing, OrderItemSummary, SelectedService } from 'domain/orderItem/types'
import { convertOrder } from 'domain/orders'
import { ResultCode, StatusCode } from 'domain/orders/types'
import { LocationInfoAPI } from 'domain/orders/typesAPI'
import { convertLocationToSelectOption, getServiceConfig } from 'domain/servicesCBRdoc'
import { ResearchCode, ServiceCode, ServiceType } from 'domain/servicesCBRdoc/types'
import { displayDate, stringToDate } from 'utils/dateTime'
import { formatCNPJ, formatCPF } from 'utils/formatters'

import { OrderItemAPI } from '../../typesAPI'

export function convertOrderItem(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    annotations,
    can_accept_additional_information,
    detailed_service_data,
    explorer_item,
    finished_at,
    groups,
    has_extras,
    index_in_purchase,
    next_order_id_same_purchase,
    ocr,
    previous_order_id_same_purchase,
    purchase,
    refundable,
    refundable_value,
    service,
    service_category,
    status_details,
  } = originalItem

  const { cpf, cnpj } = detailed_service_data ?? {}

  const orderItem: OrderItem = {
    ...convertOrderItemListing(originalItem, isPostPaid),
    hasExtras: has_extras,
    annotations,
    canAcceptAdditionalInformation: can_accept_additional_information,
    canAskRefund: refundable && !isPostPaid,
    cpfOrCnpj: cpf ? formatCPF(cpf) : cnpj ? formatCNPJ(cnpj) : '',
    finishedAt: finished_at,
    groups: groups ?? [],
    ocr: convertOCR(ocr),
    order: convertOrder(purchase, isPostPaid),
    refundValue: refundable_value,
    refundable: refundable,
    rejectedReason: status_details,
    serviceCategory: service_category,  
  }

  if (service.ai_default_model) {
    orderItem.service.aiDefaultModel = convertCreateOrderModel(service.ai_default_model)
  }

  if (explorer_item) {
    orderItem.explorerItem = convertExplorerItem(explorer_item)
  }

  if (purchase.orders_count) {
    orderItem.navigation = {
      currentIndex: index_in_purchase,
      nextId: next_order_id_same_purchase,
      previousId: previous_order_id_same_purchase,
      totalItems: purchase.orders_count,
    }
  }

  return orderItem.service.type === ServiceType.RESEARCH
    ? convertResearchResults(originalItem, orderItem)
    : orderItem
}

// Formato usado na listagem
export function convertOrderItemListing(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    detailed_service_data,
    estimated_at,
    extracted_summary,
    file_preview_url,
    groups,
    has_ai_extracted_data,
    has_ai_analysis_pending,
    has_consumed_ai_free_analysis,
    has_extras,
    imported_at,
    last_status_change_at,
    location_info,
    ocr,
    purchase,
    register,
    service,
    valid_until,
    verification_code,
  } = originalItem

  const orderItem: OrderItemListing = {
    ...convertOrderItemSummary(originalItem, isPostPaid),
    estimatedDeliveryAt: stringToDate(estimated_at)!,
    hasExtras: has_extras,
    fileURL: file_preview_url,
    groups: groups ?? [],
    hasConsumedAiFreeAnalysis: has_consumed_ai_free_analysis,
    hasAiExtractData: has_ai_extracted_data,
    hasAiAnalysisPending: has_ai_analysis_pending,
    isFromPassportWithoutAllData: !!imported_at && verification_code === 'importado_documento_brasil',
    locationInfo: convertLocationInfo(location_info, service.code),
    ocrId: ocr?.id,
    register,
    result: extractResult(originalItem) as any,
    submitData: detailed_service_data ?? {},
    updatedAt: last_status_change_at,
    validUntil: valid_until,
    validUntilDisplay: displayDate(valid_until),
    extractSummary: extracted_summary ?? null
  }

  if (purchase?.recurrence) {
    orderItem.recurrence = {
      id: purchase.recurrence.id,
      name: purchase.recurrence.name,
    }
  }

  return orderItem
}

export function convertLocationInfo(locationAPI: LocationInfoAPI | null, serviceCode: ServiceCode) {
  if (!locationAPI) {
    return {}
  }

  const { cities, federative_units, notaries } = locationAPI
  
  try {
    const { dynamicFields, formFields } = getServiceConfig(serviceCode)
    const allFields = { ...formFields, ...(dynamicFields ?? {}) }

    return {
      url_uf: convertLocationToSelectOption(federative_units, allFields.url_uf?.multiple),
      url_cidade: convertLocationToSelectOption(cities, allFields.url_cidade?.multiple),
      url_cartorio: convertLocationToSelectOption(notaries, allFields.url_cartorio?.multiple),
    }
  } catch {
    console.error(`Serviço ${serviceCode} não configurado.`)
    return {}
  }
}

// Formato usado em Relatórios
export function convertOrderItemSummary(originalItem: OrderItemAPI, isPostPaid: boolean) {
  const {
    ai_service_name,
    backoffice_id,
    id,
    name,
    placed_at,
    purchase_id,
    service: serviceAPI,
    service_category_id,
    status,
    total_cost,
    total_estimated_cost_postpaid_customer,
    user,
  } = originalItem

  const service = convertToSelectedService(serviceAPI, service_category_id) as SelectedService
  const isAI = serviceAPI.type === ServiceType.AI

  if (isAI && ai_service_name) {
    service.name = `IA: ${ai_service_name}`
  }

  if (serviceAPI.short_name && !isAI) {
    service.shortName = serviceAPI.short_name
  }

  const orderItem: OrderItemSummary = {
    createdAt: stringToDate(placed_at)!,
    createdAtDisplay: displayDate(placed_at),
    id,
    name,
    orderId: purchase_id as number,
    orderItemNumber: backoffice_id,
    service,
    status,
    price: isPostPaid ? total_estimated_cost_postpaid_customer ?? 0 : total_cost,
    user: {
      id: user.id,
      name: user.name,
    },
  }

  return orderItem
}

function extractResult(item: OrderItemAPI) {
  const { result, result_details, service, status } = item

  if (!result || status !== StatusCode.FINALIZADO) {
    return null
  }

  // Esta lógica deveria estar no back
  if (
    !result &&
    service.code === ResearchCode.PESQUISA_BENS &&
    result_details?.some(elem => !!elem.matricula)
  ) {
    return ResultCode.POSITIVE
  }

  return result
}
