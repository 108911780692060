import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import { Provider as JotaiProvider } from 'jotai'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ptBR from 'antd/locale/pt_BR'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { MsalProvider } from '@azure/msal-react'

import { globalScope } from 'domain/app/state/scope'
import theme from 'layouts/theme'
import store from 'state/store'
import { getAppVersion } from './config/appVersion'
import queryClient from './config/queryClient'
import Router from './routes/Router'
import envConfig from './config/env'

dayjs.locale('pt-br')

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://0bd28fbbe19e4db48777a8c0594820ab@o1052273.ingest.sentry.io/6035896',
    maxBreadcrumbs: 50,
    debug: process.env.NODE_ENV !== 'production',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
        mask: ['.mask-text'],
      }),
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    ignoreErrors: ['Non-Error promise rejection captured'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    release: 'app@' + getAppVersion(),
    // Capture Replay olny for sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  })
}

interface Props {
  microsoftInstance: any
}

export default function App({ microsoftInstance }: Props) {
  return (
    <MsalProvider instance={microsoftInstance}>
      <ConfigProvider locale={ptBR} theme={theme}>
        <Provider store={store}>
          <JotaiProvider store={globalScope}>
            <QueryClientProvider client={queryClient}>
              <GoogleReCaptchaProvider reCaptchaKey={envConfig.googleSiteKey!}>
                <Router />
              </GoogleReCaptchaProvider>
            </QueryClientProvider>
          </JotaiProvider>
        </Provider>
      </ConfigProvider>
    </MsalProvider>
  )
}
