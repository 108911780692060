import React from 'react'

import { OrderResults, ResultCode } from 'domain/orders/types'
import { SelectMultiple } from 'ui'

export default function OrdersResultsFilter() {
  return (
    <SelectMultiple
      placeholder="Selecione o resultado"
      options={data}
      name="results"
      label="Resultados"
      size="middle"
    />
  )
}

const data = Object.entries(OrderResults).map(
  ([code, label]) => ({
    label, 
    value: code as ResultCode 
  })
)
