import React, { useReducer } from 'react'

import { Button } from 'ui'
import ReportProblemModal from '../error/ReportProblemModal'

export default function ReportProblemButton({ itemId }: { itemId: number }) {
  const [reportProblemModalVisible, toggleReportProblemModalVisible] = useReducer(prev => !prev, false)

  return (
    <>
      <Button
        icon="warning"
        className="text-gray-700 font-bold"
        type="link"
        size="small"
        onClick={toggleReportProblemModalVisible}
      >
        <span className="whitespace-normal">Reportar problema</span>
      </Button>

      <ReportProblemModal
        open={reportProblemModalVisible}
        onRequestClose={toggleReportProblemModalVisible}
        orderId={itemId}
      />
    </>
  )
}
