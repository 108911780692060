import { Collapse, Form, FormListFieldData, Popconfirm } from 'antd'

import { Icon, TextButton, TextInput } from 'ui'

import FieldNameHelp from './FieldNameHelp'
import QuestionField from './QuestionField'
import QuestionHelp from './QuestionHelp'

const { Panel } = Collapse

interface Props {
  field: FormListFieldData
  deletable: boolean
  counter: number
  totalCharacters: number
  remove: (value: number | number[]) => void
}

export default function ModelQuestionsPanel({ field, deletable, counter, remove, ...props }: Props) {
  const title = Form.useWatch(['questions', counter, 'questionLabel'])
  const description = Form.useWatch(['questions', counter, 'questionToAi'])

  return (
    <Panel
      key={field.key}
      header={
        <span className="flex flex-row items-center">
          <Icon name="registro-civil" className="text-xl mr-2" /> {title || 'Título do campo'} ({counter + 1})
        </span>
      }
      className="mb-4"
      extra={
        deletable && (
          <Popconfirm
            title="Tem certeza que deseja excluir？"
            okText="Sim"
            cancelText="Não"
            onConfirm={() => remove(field.name)}
          >
            <TextButton label={<Icon name="trash" size={18} />} />
          </Popconfirm>
        )
      }
      forceRender
      {...props}
    >
      <TextInput
        label={
          <div className="flex items-center">
            Título do campo <FieldNameHelp className="ml-2 mb-1" />
          </div>
        }
        name={[counter, 'questionToShow']}
        maxLength={60}
        required
      />

      <div className="relative text-right">
        <QuestionField
          label={<div className="flex items-center">Instruções para Extração de Dados <QuestionHelp className="ml-2 mb-1" /></div>}
          name={[counter, 'questionToAi']}
          required
        />

        <p className="text-2xs text-secondary-300 mt-[-12px]">
          {description?.length ? description.length : 0}
        </p>
      </div>
    </Panel>
  )
}
