import React from 'react'

import { useGetOverviewTotalResults } from 'domain/orders/state/pagination'
import { Tag } from 'ui'
import { singularOrPlural } from 'utils/formatters'

export default function OrderItemsCountText() {
  const overviewTotalResults = useGetOverviewTotalResults()

  return <Tag className="self-center">{singularOrPlural(overviewTotalResults, 'item', 'itens')}</Tag>
}
