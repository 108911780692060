import { Spin } from 'antd'

import { AlertFilterNoResults } from 'ui'

import { useHasFilters } from '../state/filters'
import useRecurrencesQuery from '../services/useRecurrencesQuery'
import AlertNoRecurrences from './AlertNoRecurrences'
import RecurrencesTable from './RecurrencesTable'

export default function RecurrencesList() {
  const { data, isFetching, isLoading, queryKey } = useRecurrencesQuery()

  if (isLoading) {
    return (
      <div className="mx-auto my-auto w-fit">
        <Spin />
      </div>
    )
  }

  if (!data || data.length === 0) {
    return <Empty />
  }

  return <RecurrencesTable recurrences={data} loading={isFetching} queryKey={queryKey} />
}

function Empty() {
  const hasFilterApplied = useHasFilters()

  if (hasFilterApplied) {
    return (
      <AlertFilterNoResults
        title="Nenhuma recorrência encontrada com este filtro"
        description="Por favor, escolha novos critérios de pesquisa no filtro acima"
      />
    )
  }

  return (
    <AlertNoRecurrences
      title="Você ainda não possui recorrências"
      description="Agende a solicitação automática de um ou mais pedidos com intervalos de tempo configuráveis. Selecione o pedido desejado e selecione 'Criar Recorrência'"
    />
  )
}
