import React from 'react'

import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import ViewOrderResearchStatusOrPDF from './ViewOrderResearchStatusOrPDF'

interface Props {
  sidebarActions?: React.ReactNode
  children?: React.ReactNode
}

function ViewResearch({ sidebarActions, children }: Props) {
  const orderItem = useGetOrderItem()
  const noResultDetails = !orderItem.researchResults || orderItem.researchResults.length === 0

  return (
    <ViewOrderLayout icon="search" sidebarActions={sidebarActions}>
      {noResultDetails ? <ViewOrderResearchStatusOrPDF orderItem={orderItem} /> : children}
    </ViewOrderLayout>
  )
}

export default ViewResearch
