import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { CreateOrderForm } from 'domain/createOrder/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { setStorageData } from 'utils/localStorage'
import { KitCommonForm } from '../types'

export const KIT_ORDER_COMMON_DATA = 'kit_order_common'

const formCommonData = atom<KitCommonForm>({ registree: {} })

export const useCommonForm = () => useAtom(formCommonData)
export const useGetCommonForm = () => useAtomValue(formCommonData)
export const useGetRegistreeForm = () => useAtomValue(formCommonData).registree ?? {}
export const useSetCommonForm = () => useSetAtom(formCommonData)

export const useSetCommonField = () => {
  const setCommonForm = useSetAtom(formCommonData)

  return function setField(field: FieldName, value: any) {
    setCommonForm(prev => ({ ...prev, registree: { ...prev.registree, [field]: value } }))
  }
}

const serviceDetailsFormDataAtom = atomFamily((itemId: number) => {
  return atom(
    get => get(formCommonData)[itemId] ?? {} as CreateOrderForm,
    (get, set, newValue: CreateOrderForm) => {
      const formData = get(formCommonData)
      const updated: KitCommonForm = {
        ...formData, 
        [itemId]: { 
          ...formData[itemId], 
          ...newValue 
        }
      }
      set(formCommonData, updated)
    }
  )
})

export const useGetServiceDetailsForm = (itemId: number) => useAtomValue(serviceDetailsFormDataAtom(itemId))
export const useUpdateServiceDetailsForm = (itemId: number) => useSetAtom(serviceDetailsFormDataAtom(itemId))

export const useSaveCommonFormOnLocalStorage = () => {
  const debounced = useDebouncedCallback((changedValues: any, allValues: any) => {
    setStorageData(KIT_ORDER_COMMON_DATA, allValues)
  }, 1500)

  useEffect(
    () => () => {
      debounced.flush()
    },
    [debounced]
  )

  return debounced
}
