import React from 'react'
import { Dropdown, MenuProps } from 'antd'

import Icon from 'ui/Icon/Icon'
import Button from 'ui/buttons/Button'

interface Props {
  pdfURL: string | null
  onHandleExcel: () => void
}

function DownloadFileDropDown({ pdfURL, onHandleExcel }: Props) {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href={pdfURL ?? '#'}>
          em PDF
        </a>
      ),
      icon: <Icon name="file-pdf" className="mr-2 text-base" />,
      disabled: !pdfURL,
    },
    {
      key: '2',
      label: 'em Xls (Excel)',
      onClick: () => onHandleExcel(),
      icon: <Icon name="file-xls" className="mr-2 text-base" />,
    },
  ]

  return (
    <Dropdown menu={{ items }}>
      <Button type="link" className="font-bold text-gray-700" icon="download" onClick={e => e.preventDefault()} target="_blank">
        Baixar
      </Button>
    </Dropdown>
  )
}

export default DownloadFileDropDown
