import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { useDebounce } from 'use-debounce'

import { useActionState, useExplorerItem } from 'domain/myFiles/state'
import { useExplorerSearchQuery, useRenameMutation } from 'domain/myFiles/services'
import { Modal, SubmitButton, TextInput } from 'ui'

interface Props {
  onFinish?: () => void
}

export default function RenameItemModal({ onFinish }: Props) {
  const [action, setAction] = useActionState()
  const [explorerItem, setExplorerItem] = useExplorerItem()
  const [form] = Form.useForm()
  const [newName, setNemName] = useState('')
  const [debouncedValue] = useDebounce(newName, 300)

  const { isFetching } = useExplorerSearchQuery(debouncedValue, {
    onSuccess: (data: any) => {
      if (data.length > 0) {
        form.setFields([
          {
            name: 'name',
            errors: ['Nome já está em uso']
          }
        ])
      }
    }
  })

  useEffect(() => {
    form.setFieldsValue({ name: explorerItem?.name })
  }, [explorerItem, form])

  const { renameItem, loading } = useRenameMutation({
    onSuccess: (updatedItem: any) => {
      setExplorerItem(updatedItem)
      onFinish && onFinish()
      setAction(undefined)
    },
  })

  const handleSubmit = (values: any) => {
    renameItem({ ...explorerItem, name: values?.name })
  }
  
  const isFormInvalid = useMemo(() => {
    const names = Object.keys(form.getFieldsValue())
    const errors = form.getFieldsError()
    const hasError = errors.some((item) => item.errors.length > 0)
    return hasError || isFetching || form.isFieldsValidating(names)
  }, [isFetching, form.getFieldsError()])

  return (
    <Modal
      open={action === 'rename'}
      title="Renomear"
      onCancel={() => {
        setAction(undefined)
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg"
        initialValues={{ name: explorerItem?.name ?? '' }}
      >
        <TextInput 
          name="name" 
          label="Nome" 
          placeholder="Digite o nome" 
          onChange={(ev) => setNemName(ev.target.value)}
          autoFocus
          required
        />

        <SubmitButton 
          label="Renomear" 
          loading={loading || isFetching} 
          disabled={isFormInvalid} 
        />
      </Form>
    </Modal>
  )
}
