const googleSiteKey = process.env.REACT_APP_GOOGLE_SITE_KEY

if (!googleSiteKey) {
  console.log(`Variável de ambiente [REACT_APP_GOOGLE_SITE_KEY] não está definida.`)
}

const envConfig = {
  googleSiteKey,
}

export default envConfig
