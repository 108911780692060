import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { useIsCustomerPostPaid } from 'domain/customers'

import { OrderItemAPI } from '../../typesAPI'
import { convertOrderItem } from './convertOrderItem'

export default function useOrderItemQuery(orderItemId?: number, options: any = {}) {
  const isPostPaid = useIsCustomerPostPaid()

  return useQuery(
    ['order-item', orderItemId],
    async () => {
      const response: AxiosResponse<OrderItemAPI> = await AuthorizedAPI.get(`/orders/${orderItemId}`, {
        params: {
          append: [
            'ai_service_name',
            'can_accept_additional_information',
            'explorer_item.ai_data',
            'explorer_item.file',
            'has_ai_extracted_data',
            'has_consumed_ai_free_analysis',
            'index_in_purchase',            
            'next_order_id_same_purchase',
            'ocr.ai_enrich_data_available',
            'place_order_default_values',
            'previous_order_id_same_purchase',            
            'purchase.downloadable_orders_ids',
            'purchase.orders_count',
            'register',
            'refundable',
            'refundable_value',
          ],
          include: [
            'explorer_item',
            'groups',            
            'ocr',
            'ocr.pages',
            'purchase',
            'purchase.recurrence',
            'purchase.waiting_invoice_payment',
            'service',
            'service_category',
            'user',
          ],
        },
      })

      const { data } = response

      if (data) {
        return convertOrderItem(data, isPostPaid!)
      }
    },
    {
      enabled: Boolean(orderItemId && isPostPaid !== undefined),
      onError: (error: any) => {
        errorHandler(error, { code: '0x370' })
      },
      ...options,
    }
  )
}
