import { CertificateFlow } from 'domain/createOrder/certificates'
import { CreateOrderLegalEntityTabs } from 'domain/createOrder/document'
import { NumberField } from 'domain/servicesCBRdoc/fields'
import { CNPJInput, CPFInput } from 'ui'

import config from '../certidaoIPTUConfig'

export default function CreateOrderCertidaoIPTU() {
  return <CertificateFlow renderFormItem={(itemId: number) => <CertidaoIPTUForm itemId={itemId} />} />
}

const CertidaoIPTUForm = ({ itemId }: { itemId: number }) => {
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={<CPFInput {...inputProps} />}
        juridica={<CNPJInput {...inputProps} />}
      />

      <NumberField field={inscricao_imovel} itemId={itemId} />
    </>
  )
}

const { inscricao_imovel } = config.formFields
