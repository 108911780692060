import { OrderItem } from 'domain/orderItem/types'
import { ResearchResultAPI } from 'domain/orderItem/typesAPI'
import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { displayDate } from 'utils/dateTime'
import { formatMoney } from 'utils/formatters'

type PesquisaProcessosJudiciaisFields = Record<
  FieldName.CPF | FieldName.CNPJ | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

class PesquisaProcessosJudiciais extends Research {
  formFields: PesquisaProcessosJudiciaisFields = {
    cpf: Fields.cpf,
    cnpj: Fields.cnpj,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      description:
        'Retorna o envolvimento do CPF/CNPJ pesquisado em ações judiciais e seus respectivos detalhamentos.',
      id: 41,
      shortName: 'Pesquisa Processos Judiciais',
    })
  }

  convertResults = (orderItem: OrderItem, results: ResearchResultAPI[] | null) => {
    if (!results || !results.length) {
      return []
    }

    return results.map((result: any, index) => {
      const { assuntos } = result
      const converted = this._convertResult(orderItem, result, index)

      converted.assuntos = assuntos ? assuntos.join(',') : ''
      converted.data_publicacao = displayDate(result.data_publicacao)
      converted.valor = formatMoney(result.valor)
      converted.partes_envolvidas = result.partes_envolvidas?.map(
        ({ nome, documento, polo }: any) => `${nome} - ${documento} - ${polo}`
      ) ?? ['']

      return converted
    })
  }
}

const service = new PesquisaProcessosJudiciais().initialize()

export default service
