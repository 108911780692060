import React from 'react'

import Icon, { IconName } from 'ui/Icon/Icon'

interface CardButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {
  title: string
  secondIcon?: IconName
  subTitle?: string
  description?: string
  checked?: boolean
  icon?: IconName
  iconSize?: number
  isNew?: boolean
  strokeWidth?: number
}

export default function CardButton({
  title,
  icon,
  iconSize = 20,
  secondIcon,
  subTitle,
  description,
  checked,
  onClick,
  isNew,
  ...props
}: CardButtonProps) {
  return (
    <button
      className={`
        border border-gray-300 rounded text-left leading-6 relative p-8 min-h-56 cursor-pointer 
        ${checked ? 'bg-primary' : 'bg-white'} 
        hover:border-primary-500 hover:shadow-xl active:shadow-inner transition-colors duration-150`}
      onClick={onClick}
      {...props}
    >
      {icon && !isNew && (
        <Icon
          name={icon}
          size={iconSize}
          className={`text-3xl ${checked ? 'text-white' : 'text-gray-400'} absolute right-0 top-0 m-7`}
        />
      )}            

      <div className="flex flex-row gap-8 justify-between">
        <h2
          className={`text-2xl xl:text-xl 3xl:text-3xl font-display font-light ${
            checked ? 'text-white' : 'text-primary'
          } mb-5 ${icon && secondIcon ? 'mr-16' : 'mr-8'}`}
        >
          {title}
        </h2>

        {isNew && <div><span className="bg-success-500 rounded-full text-white px-6 py-1 text-xs text-center">Novidade</span></div>}
      </div>
      {subTitle && <p className={`mb-2 text-base ${checked ? 'text-white' : 'text-gray-700'}`}>{subTitle}</p>}
      {description && <p className={`text-sm ${checked ? 'text-white' : 'text-gray-500'}`}>{description}</p>}
    </button>
  )
}
