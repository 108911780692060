import { useQuery } from 'react-query'

import { AuthorizedAPI } from 'api/network/v2'
import { useGetIsHydratedFromURL } from 'domain/app'
import { AICreateOrderModelAPI } from 'domain/openAI/typesAPI'
import { convertCreateOrderModel } from './convertModel'
import { useGetSearch } from '../state/filters'
import {
  useGetCurrentPage,
  useGetPageSize,
  useSetTotalPages,
  useSetTotalResults,
} from '../state/myModels/pagination'

export default function useMyModelsQuery() {
  const isHydrated = useGetIsHydratedFromURL()
  const search = useGetSearch()
  const currentPage = useGetCurrentPage()
  const pageSize = useGetPageSize()
  const setTotalPages = useSetTotalPages()
  const setTotalResults = useSetTotalResults()

  return useQuery(
    ['ai-models', 'list', 'customer', currentPage, search, pageSize],
    async () => {
      const params: any = {
        include: 'questions,service,service.category1',
        sortBy: '-created_at,name',
        'filter[visibility]': 'private',
        'per-page': pageSize,
        page: currentPage,
      }

      if (search) {
        params['filter[name_or_id]'] = search
      }

      const response = await AuthorizedAPI.get<AICreateOrderModelAPI[]>('/ai-models', {
        params,
      })

      setTotalPages(Number(response.headers['x-pagination-page-count']))
      setTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data.map(convertCreateOrderModel)
    },
    {
      enabled: isHydrated,
    }
  )
}
