import { Form, message } from 'antd'
import { errorHandler } from 'api/errors'
import { createOrderActions, useAddCreateOrderStateToCart, useCreateOrderState } from 'domain/createOrder/document'
import { DocRegistrationFlow } from 'domain/createOrder/documentRegistration'
import { LocationTextInput, TextAreaField, UploadField } from 'domain/servicesCBRdoc/fields'
import { FormInstance } from 'rc-field-form'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Heading, SubmitButton } from 'ui'

import config from '../ataNotarialConfig'

export default function CreateOrderAtaNotarial() {
  return <DocRegistrationFlow dataStep={<DocumentRegistrationForm />} />
}

function DocumentRegistrationForm({ formInstance }: { formInstance?: FormInstance }) {
  const form: any = formInstance ?? Form.useForm()[0]
  const dispatch = useDispatch()
  const [status, setStatus] = useState<'idle' | 'loading'>('idle')
  const addToCart = useAddCreateOrderStateToCart()
  const { files, formData, multipleItemsLoadingPrice, selectedModel } = useCreateOrderState()
  const isUploading = files?.some(f => f.status === 'uploading')
  const hasUploadError = files?.some(f => f.status === 'error')
  const isLoading = multipleItemsLoadingPrice === true || status === 'loading' || isUploading

  const handleSubmit = async (formData: any) => {
    const filledForm = {
      ...formData,
    }

    dispatch(createOrderActions.updateFormData(filledForm))

    try {
      await form.validateFields()
      setStatus('loading')

      try {
        addToCart(filledForm)
      } catch (error) {
        setStatus('idle')
        errorHandler(error)
      }
    } catch {
      message.error('Verifique o preenchimento do formulário.')
    }
  }

  const initialValues = { ...formData }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Heading 
        size="3" 
        text="Detalhe a Ata Notarial" 
        className="mb-4"
      />

      <TextAreaField field={mensagem} />

      <Heading 
        size="3" 
        text="Anexar arquivos" 
        description="Envie até 20 arquivos em formato PNG, JPG e PDF. Cada arquivo deve ter, no máximo, 10MB de tamanho."
      />
      <UploadField field={arquivos} required={selectedModel === 'ja-possuo-as-evidencias'} hideLabel />

      <SubmitButton marginTop="mt-3" loading={isLoading} disabled={hasUploadError || isUploading || status === 'loading'} />

      <p className={`text-sm text-center ${hasUploadError ? 'text-error' : 'text-gray-400'}`}>
        {hasUploadError ? 'Houve um erro ao fazer o upload' : isUploading ? 'Upload em andamento...' : ''}
      </p>
    </Form>
  )
}

const { mensagem, arquivos, local_servico } = config.formFields
