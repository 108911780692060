import { useDontKnowBookPagePriceQuery } from 'domain/createOrder/certificates'
import { DontKnowBookPageFields } from 'domain/servicesCBRdoc/fields'
import { useUpdateServiceForm } from '../../state'

interface Props {
  itemId: number
}

export default function KitDontKnowBookPage({ itemId }: Props) {
  const updateForm = useUpdateServiceForm(itemId)
  const { data: prices, isFetching } = useDontKnowBookPagePriceQuery()

  return (
    <DontKnowBookPageFields
      itemId={itemId}
      price={prices?.price}
      loading={isFetching}
      onChangeDontKnow={nao_sei_livro_pagina => updateForm({ nao_sei_livro_pagina })}
    />
  )
}
