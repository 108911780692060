import { CertificateFlow } from 'domain/createOrder/certificates'
import {
  CreateOrderLegalEntityTabs,
  useLoadExtraInfos,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { AllExtraInfos } from 'domain/orderItem/types'
import {
  CNPJAndNameFields,
  DateField,
  ExtraInfoFields,
  NameField,
  TextField,
} from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoNegativaSTJConfig'

export default function CreateOrderCertidaoNegativaSTJ() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      renderFormItem={(itemId: number) => (
        <CertidaoNegativaSTJForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

const CertidaoNegativaSTJForm = ({ itemId, extraInfos, loading }: Props) => {
  const { selectedService } = useCreateOrderState()
  const inputProps = { itemId, autoFocus: itemId === 0 }

  return (
    <CreateOrderLegalEntityTabs
      itemId={itemId}
      fisica={
        <>
          <CPFInput {...inputProps} />
          <NameField itemId={itemId} field={nome_pf} />
          <DateField itemId={itemId} field={nascimento} />
          <TextField itemId={itemId} field={rg} />
          <TextField itemId={itemId} field={rg_expedidor} />

          <ExtraInfoFields
            serviceCode={selectedService.code!}
            data={extraInfos}
            loading={loading}
            itemId={itemId}
          />

          <NameField itemId={itemId} field={mae} />
        </>
      }
      juridica={<CNPJAndNameFields {...inputProps} companyField={nome_pj} />}
    />
  )
}

const { mae, nascimento, nome_pf, nome_pj, rg, rg_expedidor } = config.formFields
