import { v4 as uuidv4 } from 'uuid'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { APP_VERSION } from 'app/constants'
import { LabelInValue, LegalEntity, TempFile } from 'domain/app/types'
import { resolveLegalEntity } from 'domain/createOrder/shared/helpers'
import {
  AvailableExtras,
  CreateOrderState,
  CreateStatus,
  Extras,
  OrderDetails,
  CreateOrderFormItem,
  DeliveryFormat,
  SelectedService,
  CreateOrderForm,
  CreateOrderFormData,
  FormItems,
} from 'domain/createOrder/types'
import { getService, getServiceConfig, Fields } from 'domain/servicesCBRdoc'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { SelectedLocation, SelectedType } from 'domain/servicesCBRdoc/types'
import { useAppState } from 'state/store'
import { getStorageBoolean, getStorageData, removeStorageItem, setStorageData } from 'utils/localStorage'
import { removeEmpties } from 'utils/transformData'

export const CREATE_ORDER_PERSISTED = 'create_orders'
export const PERSIST_ORDER_FORM_DATA = 'common_form_data_enabled'

type SetFormItemField = { id: number; field: keyof CreateOrderFormItem; value: any }

export const getDefaultFormItem = (itemId: number = 0): CreateOrderFormItem => {
  return {
    availableTypes: [],
    countItems: 1,
    dontKnowBookPage: false,
    dynamicFields: [],
    id: itemId,
    itemOrder: 0,
    orderDetails: {
      totalValue: null,
      estimatedDeliveryTime: null,
    },
  }
}

export const getInitialCreateOrder = () => {
  const formItem = getDefaultFormItem()

  const initialState: CreateOrderState = {
    allNotariesSelected: false,
    availableFormats: [],
    availableFormatsInfoLoading: false,
    availableTypes: [],
    availableResearchTimes: [],
    cartItemId: uuidv4(),
    countItems: 1,
    createStatus: 'process',
    dontKnowBookPage: false,
    dynamicFields: [],
    formData: {},
    formItems: {
      [formItem.id]: formItem,
    },
    orderDetails: {
      totalValue: null,
      estimatedDeliveryTime: null,
    },
    origin: {
      description: `simples v${APP_VERSION}`,
    },
    selectedComarca: undefined,
    selectedFormat: undefined,
    selectedLocation: {},
    selectedService: {
      id: 0,
      categoryId: 0,
      name: '',
    },
  }

  return initialState
}

const initialOrPersistedOrder: CreateOrderState = getStorageData(
  CREATE_ORDER_PERSISTED,
  getInitialCreateOrder()
)

const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState: initialOrPersistedOrder,
  reducers: {
    addFormItem: (state, action: PayloadAction<{ id: number; extraData?: Partial<CreateOrderFormItem> }>) => {
      const { id, extraData } = action.payload
      let newItem = getDefaultFormItem(id)
      const service = getServiceConfig(state.selectedService.code!)
      const lastOrderItem = Object.values(state.formItems).reduce(
        (acc, item) => Math.max(acc, item.itemOrder),
        0
      )

      newItem.selectedEntityType = service.getDefaultLegalEntity()
      newItem.itemOrder = lastOrderItem + 1

      if (extraData) {
        newItem = { ...newItem, ...extraData }
      }

      state.formItems[id] = newItem

      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    deleteFormItem: (state, action: PayloadAction<number>) => {
      if (Object.keys(state.formItems!).length === 1) {
        state.formItems = {
          0: getDefaultFormItem(),
        }
      } else {
        const items = { ...state.formItems }
        const id = action.payload
        delete items[id]
        state.formItems = items
      }

      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    resetState: state => {
      removeStorageItem(CREATE_ORDER_PERSISTED)
      const preserveFormData = getStorageBoolean(PERSIST_ORDER_FORM_DATA, false)
      const initalCreateOrder = getInitialCreateOrder()

      const replicableForm = {} as any

      Object.entries(state.formData).forEach(([key, data]) => {
        if (data) {
          const replicable = Object.keys(data as any).filter(
            field => Fields[field as FieldName]?.dataScope === 'registree'
          )
          replicableForm[key] = {}
          replicable.forEach(field => {
            replicableForm[key][field] = (data as any)[field]
          })
        }
      })

      return {
        ...initalCreateOrder,
        formData: preserveFormData ? removeEmpties(replicableForm) : {},
      }
    },
    setAllNotariesSelected: (state, action: PayloadAction<boolean>) => {
      state.allNotariesSelected = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setAvailableExtras: (state, action: PayloadAction<AvailableExtras | undefined>) => {
      state.availableExtras = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setAvailableFormats: (state, action: PayloadAction<DeliveryFormat[]>) => {
      state.availableFormats = action.payload
      state.availableFormatsInfoLoading = false
    },
    setAvailableFormatsInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.availableFormatsInfoLoading = action.payload
    },
    setAvailableTypes: (state, action: PayloadAction<string[]>) => {
      state.availableTypes = action.payload
    },
    setCountItems: (state, action: PayloadAction<number>) => {
      state.countItems = Math.max(action.payload, 1) // mínimo 1
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setCreateOrder: (state, action: PayloadAction<CreateOrderState>) => {
      setStorageData(CREATE_ORDER_PERSISTED, action.payload)
      return action.payload
    },
    setCreateStatus: (state, action: PayloadAction<CreateStatus>) => {
      state.createStatus = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setDontKnowBookPage: (state, action: PayloadAction<boolean>) => {
      state.dontKnowBookPage = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setDynamicFields: (state, action: PayloadAction<FieldName[]>) => {
      state.dynamicFields = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setFiles: (state, action: PayloadAction<TempFile[]>) => {
      state.files = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setFormData: (state, action: PayloadAction<CreateOrderFormData>) => {
      state.formData = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setFormField: (state, action: PayloadAction<{ itemId?: number; field: FieldName; value: any }>) => {
      const { itemId, field, value } = action.payload

      if (itemId !== undefined) {
        if (!state.formData[itemId]) {
          state.formData[itemId] = {}
        }
        state.formData[itemId][field] = value
      } else {
        state.formData[field] = value
      }

      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setFormItemField: (state, action: PayloadAction<SetFormItemField>) => {
      const { id, field, value } = action.payload
      // @ts-ignore
      state.formItems[id][field] = value
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setFormItems: (state, action: PayloadAction<FormItems>) => {
      state.formItems = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setMultipleItemsCountItems: (state, action: PayloadAction<{ id: number; value: number }>) => {
      const { id, value } = action.payload
      state.formItems![id].countItems = value
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setMultipleItemsDynamicFields: (state, action: PayloadAction<{ id: number; value: FieldName[] }>) => {
      const { id, value } = action.payload
      state.formItems![id].dynamicFields = value
    },
    setMultipleItemsLegalEntity: (state, action: PayloadAction<{ id: number; value: LegalEntity }>) => {
      const { id, value } = action.payload
      state.formItems![id].selectedEntityType = value
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setMultipleItemsLoadingPrice: (state, action: PayloadAction<boolean>) => {
      state.multipleItemsLoadingPrice = action.payload
    },
    setMultipleItemsDontKnowBookPage: (state, action: PayloadAction<{ id: number; value: boolean }>) => {
      const { id, value } = action.payload
      state.formItems![id].dontKnowBookPage = value
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setMultipleItemsType: (state, action: PayloadAction<{ id: number; value: SelectedType }>) => {
      const { id, value } = action.payload
      state.formItems![id].selectedType = value
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setOrderDetails: (state, action: PayloadAction<OrderDetails>) => {
      state.orderDetails = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedCategory: (state, action: PayloadAction<number>) => {
      state.selectedService.categoryId = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedCity: (state, action: PayloadAction<LabelInValue | LabelInValue[] | undefined>) => {
      state.selectedLocation.url_cidade = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedComarca: (state, action: PayloadAction<string | undefined>) => {
      state.selectedComarca = action.payload
      state.formData.comarca = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedEntityType: (state, action: PayloadAction<LegalEntity>) => {
      state.selectedEntityType = action.payload

      if (action.payload == 'fisica') {
        if (state.formData.cnpj) {
          state.formData.cnpj = undefined
        }
      } else if (state.formData.cpf) {
        state.formData.cpf = undefined
      }
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedExtras: (state, action: PayloadAction<Extras | undefined>) => {
      if (
        action.payload?.tipo_apostilamento_com_traducao &&
        !(action.payload.apostilamento && action.payload.traducao_juramentada)
      ) {
        action.payload.tipo_apostilamento_com_traducao = undefined
      }

      state.selectedExtras = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedFederativeUnit: (state, action: PayloadAction<LabelInValue | LabelInValue[] | undefined>) => {
      state.selectedLocation.url_uf = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedInstance: (state, action: PayloadAction<string | undefined>) => {
      state.selectedInstance = action.payload
      state.formData.instancia = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedFormat: (state, action: PayloadAction<DeliveryFormat | undefined>) => {
      state.selectedFormat = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedLocation: (state, action: PayloadAction<SelectedLocation>) => {
      state.selectedLocation = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedModel: (state, action: PayloadAction<string | number | undefined>) => {
      state.selectedModel = action.payload
      state.formData.modelo = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedNotaries: (state, action: PayloadAction<LabelInValue[] | undefined>) => {
      state.selectedLocation.url_cartorio = action.payload
      state.formData.url_cartorio = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedRegion: (state, action: PayloadAction<string | undefined>) => {
      state.selectedRegion = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedResearchTime: (state, action: PayloadAction<any>) => {
      state.selectedResearchTime = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedService: (state, action: PayloadAction<SelectedService>) => {
      state.selectedService = action.payload

      if (state.selectedService.code) {
        const config = getService(state.selectedService.code)

        if (config.legalEntity) {
          state.selectedEntityType = resolveLegalEntity(state.selectedService.code) || undefined
          if (config.allowMultipleItems) {
            state.formItems[0].selectedEntityType =
              resolveLegalEntity(state.selectedService.code) || undefined
          }
        }
      }

      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    setSelectedType: (state, action: PayloadAction<string | LabelInValue | undefined>) => {
      state.selectedType = action.payload
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
    startNewOrder: (_, action: PayloadAction<Partial<CreateOrderState>>) => {
      return {
        ...getInitialCreateOrder(),
        ...action.payload,
      }
    },
    updateFormData: (state, action: PayloadAction<Partial<CreateOrderForm>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      }
      setStorageData(CREATE_ORDER_PERSISTED, state)
    },
  },
})

export const createOrderActions = createOrderSlice.actions
export const useCreateOrderState = () => useAppState(state => state.createOrder)

export default createOrderSlice.reducer
