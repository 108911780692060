import React from 'react'

import { OCRDocument } from 'domain/myFiles/types'
import { AlertFullPage, Icon, PDFComparison } from 'ui'

import OCRPage from './OCRPage'

interface Props {
  pdfUrl?: string
  ocr: OCRDocument | null | undefined
}

function OCRComparison({ pdfUrl, ocr }: Props) {
  if (!ocr || !ocr.pages || !pdfUrl) {
    return (
      <AlertFullPage
        title="Transcrição indisponível"
        description="Este documento não possui transcrição, ou os dados ainda estão em processamento."
        icon={<Icon name="file" className="mb-4 mx-auto text-6xl text-secondary-500" />}
      />
    )
  }

  return (
    <PDFComparison fileUrl={pdfUrl} fileName={ocr.documentName} >
      {ocr.pages.map(page => (
        <React.Fragment key={page.id}>
          <OCRPage key={page.pageNumber} ocrId={ocr.id} page={page} totalPages={ocr.totalPages} />
        </React.Fragment>
      ))}
    </PDFComparison>
  )
}

export default OCRComparison
