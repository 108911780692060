import React, { useReducer } from 'react'

import { getNoPermissionText, useHasRecurrencePermission } from 'domain/auth'
import { OrderList } from 'domain/orders/types'
import { CreateRecurrence } from 'domain/recurrences'
import { DrawerActions } from 'ui'

interface Props {
  selected: OrderList[]
  onClose: () => void
}

export default function OrdersMassRecurrence({ selected, onClose }: Props) {
  const hasPermission = useHasRecurrencePermission()
  const [openModal, toggleModal] = useReducer(prev => !prev, false)

  return (
    <>
      <DrawerActions.Button
        title={
          selected.length > 1
            ? 'Para criar uma recorrência, selecione apenas um pedido'
            : getNoPermissionText(hasPermission)
        }
        disabled={!hasPermission || selected.length > 1}
        onClick={toggleModal}
        icon="calendar"
      >
        Criar Recorrência
      </DrawerActions.Button>

      <CreateRecurrence
        items={selected}
        kind="orders"
        openModal={openModal}
        onCloseModal={toggleModal}
        onSuccess={onClose}
      />
    </>
  )
}
