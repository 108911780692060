import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document'
import { ServiceAPI } from 'domain/servicesCBRdoc/types'

function useSelectServiceClick(service: ServiceAPI, categoryId: number = 0) {
  const dispatch = useDispatch()
  const history = useHistory()

  return function onClick(e: any) {
    e.preventDefault()

    if (!categoryId && !service.category2_id) {
      categoryId = service.category1_id
    }

    dispatch(
      createOrderActions.setSelectedService({
        id: service.id,
        code: service.code,
        type: service.type,
        name: service.name,
        categoryId
      })
    )    
    
    history.push(`${RoutePaths.CREATE_ORDER}/${service.code}`)
  }
}

export default useSelectServiceClick
