import React from 'react'
import { Form } from 'antd'

import { MAX_TEXT_LENGTH } from 'domain/openAI/data'
import { Section } from 'layouts'
import { Alert, SubmitButton, Icon } from 'ui'

import { useAIFormContext } from './AIFormContext'
import ModelQuestions from './ModelQuestionsCollapse'
import useCountTotalCaracters from '../helpers/useCountTotalCaracters'
import useSaveModel from '../services/useSaveModel'

function ModelQuestionsForm({ onSubmit }: { onSubmit: (data: any) => void }) {
  const [form] = Form.useForm()
  const { saveModel, loading } = useSaveModel()
  const [modelForm, setModelForm] = useAIFormContext()
  const [caractersCounter, totalCharacters] = useCountTotalCaracters()

  const handleSubmit = async (data: any) => {
    const modelWithQuestions = { ...modelForm, ...data }
    
    const res = await saveModel(modelWithQuestions, {
      onSuccess: (res: any) => {
        onSubmit(res.data)
      },
    })

    setModelForm({ ...modelWithQuestions, id: res.data.id })
  }

  return (
    <Section heading="Campos para Extração">
      <Form
        layout="vertical"
        requiredMark={false}
        scrollToFirstError
        form={form}
        onFinish={handleSubmit}
        onValuesChange={caractersCounter}
        initialValues={modelForm}
      >
        <ModelQuestions totalCharacters={totalCharacters} />

        {totalCharacters > MAX_TEXT_LENGTH ? (
          <Alert
            type="warning"
            icon={<Icon name="warning" className="text-2xl" />}
            message={
              `O limite de ${MAX_TEXT_LENGTH} caracteres foi atingido. 
              Remova alguns campos ou diminua o tamanho dos textos.`
            }
            className="mt-4"
          />
        ) : (
          <Alert 
            message={
              `Ainda restam ${MAX_TEXT_LENGTH - totalCharacters} caracteres. 
              Você pode usar para adicionar mais campos ou melhorar a suas instruções`
            } 
            type="info" 
            icon={<Icon name="info" />} 
            className="mt-4"
          />
        )}

        <SubmitButton loading={loading} disabled={totalCharacters > MAX_TEXT_LENGTH} />
      </Form>
    </Section>
  )
}

export default ModelQuestionsForm
