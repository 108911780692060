import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoEmpresaFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.TIPO_PESSOA
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.TIPO_CERTIDAO
  | FieldName.TIPO_DOCUMENTO
  | FieldName.URL_CARTORIO
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

type CertidaoEmpresaDynamicFields = Record<
  FieldName.REGISTRO | FieldName.DATA_REGISTRO | FieldName.QUESITO,
  FieldConfig
>

const {
  DATA_REGISTRO,
  FORMATO,
  QUESITO,
  REGISTRO,
  TIPO_CERTIDAO,
  TIPO_DOCUMENTO,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName

class CertidaoEmpresaCartoriosPJ extends Certificate {
  formFields: CertidaoEmpresaFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    [TIPO_DOCUMENTO]: {
      ...Fields[TIPO_DOCUMENTO],
      stateKey: 'selectedType',
    },
    [TIPO_CERTIDAO]: {
      ...Fields[TIPO_CERTIDAO],
      stateKey: 'selectedModel',
      defaultValue: 'inteiroteor'
    },
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
    nome_pf: Fields.nome_pf,
    tipo_pessoa: {
      ...Fields.tipo_pessoa,
      optional: true
    },
    cpf: Fields.cpf,
  }

  dynamicFields: CertidaoEmpresaDynamicFields = {
    [QUESITO]: Fields[QUESITO],
    [REGISTRO]: {
      ...Fields[REGISTRO],
      apiName: 'numero_registro',
    },
    [DATA_REGISTRO]: Fields[DATA_REGISTRO],
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoFields: [TIPO_CERTIDAO, TIPO_DOCUMENTO, URL_CARTORIO, URL_CIDADE, URL_UF],
      extraInfoRequiredFieldsOnly: true,
      id: 59,
      priceByFields: [FORMATO, TIPO_CERTIDAO, TIPO_DOCUMENTO, URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Certidão de Empresa',
      steps: ['localizacao', 'dados', 'entrega'],
      legalEntity: 'indefinido',
    })
  }
}

const service = new CertidaoEmpresaCartoriosPJ().initialize()

export default service
