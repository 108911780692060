import { OrderItemListing } from 'domain/orderItem/types'

import OrderDetailsInfoTag from '../ordersList/OrderInfoTag'
import { getHasValidDate } from 'utils/validators'

interface OrderDetailsInfoProps {
  item: OrderItemListing
}

const OrderDetailsInfo = ({ item }: OrderDetailsInfoProps) => {
  const hasValidDate = getHasValidDate(item.validUntil ?? '')

  return (
    <div className="flex flex-row flex-wrap gap-1">
      {hasValidDate && item.validUntil && <OrderDetailsInfoTag>Vencido</OrderDetailsInfoTag>}
      {item.recurrence && <OrderDetailsInfoTag>Recorrente</OrderDetailsInfoTag>}
      {!hasValidDate && item.validUntil && <OrderDetailsInfoTag>Válido</OrderDetailsInfoTag>}
      {item.hasAiExtractData && <OrderDetailsInfoTag>Dados extraídos</OrderDetailsInfoTag>}
      {item.hasAiAnalysisPending && <OrderDetailsInfoTag>Dados sendo analisados pela IA</OrderDetailsInfoTag>}
    </div>
  )
}

export default OrderDetailsInfo
