import React from 'react'
import { Button, Dropdown, message } from 'antd'

import { useRefreshOverviewQuery } from 'domain/orders/state/order'
import { OrderOverview } from 'domain/orders/types'
import { useDownload } from 'utils/export'
import { Icon } from 'ui'

type Props = {
  order: OrderOverview
}

export default function DownloadFinalizedItemsButton({ order }: Props) {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'all',
            label: <DownloadAll order={order} />,
          },
          {
            key: 'not-downloaded',
            label: <FirstDownload order={order} />,
          },
        ],
      }}
    >
      <Button type="primary" disabled={order.downloadableItemsIds.length === 0} ghost>
        <span className="flex flex-row flex-nowrap items-center gap-1">
          Baixar itens finalizados <Icon name="chevron-down" />
        </span>
      </Button>
    </Dropdown>
  )
}

function FirstDownload({ order }: Props) {
  const downloadableItems = order.downloadableItemsIds.length
  const notDownloadedItemsIds = order.downloadableItemsIds.filter(
    id => !order.downloadedItemsIds.includes(id)
  )
  const notDownloadedItems = notDownloadedItemsIds.length

  return (
    <DownloadOrderItems
      order={order}
      downloadUrl={`orders/${notDownloadedItemsIds.join(',')}/download`}
      disabled={notDownloadedItems === 0}
      title={
        notDownloadedItems === 0 && downloadableItems > 0
          ? 'Todos os itens disponíveis já foram baixados'
          : undefined
      }
    >
      Baixar apenas itens não baixados ({notDownloadedItems}/{order.totalItems})
    </DownloadOrderItems>
  )
}

function DownloadAll({ order }: Props) {
  const downloadableItems = order.downloadableItemsIds.length

  return (
    <DownloadOrderItems
      order={order}
      downloadUrl={`purchases/${order.id}/download`}
      disabled={downloadableItems === 0}
    >
      Baixar todos os itens finalizados ({downloadableItems}/{order.totalItems})
    </DownloadOrderItems>
  )
}

function DownloadOrderItems({
  children,
  disabled,
  downloadUrl,
  order,
  title,
}: {
  children: React.ReactNode
  disabled?: boolean
  downloadUrl: string
  order: OrderOverview
  title?: string
}) {
  const [handleDownload, loading] = useDownload()
  const refreshQuery = useRefreshOverviewQuery()

  const onClick = async () => {
    const hide = message.loading('Preparando arquivos..')
    await handleDownload(downloadUrl, `cbrdoc-pedido-${order.orderNumber}`)
    refreshQuery()
    hide()
  }

  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={disabled || loading}
      title={title}
      type="link"
      className="px-0"
    >
      {children}
    </Button>
  )
}
