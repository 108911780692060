import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoStmCriminaisFields = Record<
  FieldName.CPF | FieldName.DATA_NASCIMENTO | FieldName.NOME_MAE | FieldName.NOME_PF,
  FieldConfig
>

const { CPF, DATA_NASCIMENTO, FORMATO, NOME_MAE, NOME_PF } = FieldName

class CertidaoSTMCriminais extends Certificate {
  formFields: CertidaoStmCriminaisFields = {
    [CPF]: Fields[CPF],
    [NOME_PF]: Fields[NOME_PF],
    [NOME_MAE]: Fields[NOME_MAE],
    [DATA_NASCIMENTO]: Fields[DATA_NASCIMENTO],
  }

  constructor() {
    super({
      id: 36,
      priceByFields: [FORMATO],
      shortName: 'STM - Criminais',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoSTMCriminais().initialize()

export default service
