import { useQuery } from 'react-query'

import { ExtraInfo, OrderItemDetails, SelectedService } from 'domain/orderItem/types'
import { checkParams } from 'utils/validators'
import { getExtraInformations } from './getExtraInfoAPI'

export interface UseExtraInformationQueryParams<Type> {
  service: Pick<SelectedService, 'categoryId' | 'id'>
  itemId?: number | string
  params?: OrderItemDetails | null
  information: ExtraInfo
  onSuccess?: (data: Type) => void
  onError?: (error: any) => void
}

export default function useExtraInformationQuery<Type>({
  service,
  itemId = 0,
  params,
  information,
  onSuccess,
}: UseExtraInformationQueryParams<Type>) {
  return useQuery(
    [`extra-info-${service.id}-${itemId}`, service.categoryId, params, information],
    async () => {
      return await getExtraInformations<Type>(service, params ?? null, information)
    },
    {
      enabled: checkParams(service, params ?? []),
      onSuccess,
    }
  )
}
