import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'

import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { convertTextToArray } from 'utils/transformData'
import { createOrderActions } from '../state'

function useUpdateSplittableQuantity(itemId: number, field: FieldName) {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()

  useEffect(() => {
    const text = form.getFieldValue([itemId, field])
    
    if (text) {
      const items = convertTextToArray(text)
      dispatch(createOrderActions.setMultipleItemsCountItems({ id: itemId, value: items.length || 1 }))
    }
  }, [])

  return function updateOrderQuantity(items: string[]) {
    dispatch(createOrderActions.setMultipleItemsCountItems({ id: itemId, value: items.length }))
  }
}

export default useUpdateSplittableQuantity
