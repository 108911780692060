import React from 'react'
import { Icon } from 'ui'

interface Props {
  title: string
  description?: string
  icon?: React.ReactNode
  children?: React.ReactNode
}

const AlertFullPage = ({ title, description, icon, children }: Props) => {
  return (
    <div className="px-7 py-24 flex-1 flex flex-col justify-center items-center text-secondary-300">
      {icon ?? <Icon name="robot" className="mb-4 mx-auto size-16" />}

      <p className="font-bold text-2xl text-center my-3">{title}</p>

      {description && <p className="font-normal text-base max-w-2xl text-center leading-6">{description}</p>}

      {children}
    </div>
  )
}

export default AlertFullPage
