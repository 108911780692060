import { useDownload } from 'utils/export'
import { OCRDocument } from 'domain/myFiles/types'
import { DocumentTabName } from 'domain/orderItem/types'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { Icon, ViewPDF } from 'ui'
import { Dropdown, Tabs } from 'antd'
import useDownloadOcrTranscription from 'domain/myFiles/services/useDownloadOcrTranscription'

interface Props {
  fileUrl: string
  ocr: OCRDocument
  onChange: React.Dispatch<React.SetStateAction<DocumentTabName>>
  openAITab?: React.ReactNode
}

export default function ViewCertificateTabs({ fileUrl, openAITab, onChange }: Props) {
  const orderItem = useGetOrderItem();
  const [handleDownload] = useDownload();
  const { handleOcrDownload } = useDownloadOcrTranscription()

  const tabItems = [
    {
      key: 'original',
      icon: <Icon name="pdf-file" />,
      label: 'Documento em PDF',
      children: (
        <>
          <div className="row flex flex-1">
            <ViewPDF fileUrl={fileUrl} />
          </div>
        </>
      ),
    },
    {
      key: 'openAI',
      icon: <Icon name="robot" />,
      label: 'Extração de Dados',
      children: openAITab,
    },
  ];

  const downloadOptions = [
    {
      disabled: !orderItem?.fileURL,
      key: '0',
      label: 'Documento em pdf',
      onClick: () => {
        const downloadUrl = `/orders/${orderItem.id}/download`
        handleDownload(downloadUrl)
      },
    },
    {
      disable: orderItem?.ocr?.id,
      key: '2',
      label: 'Transcrição em .txt',
      onClick: () => handleOcrDownload(orderItem?.ocr?.id ?? 0, 'docx'),
    },
    {
      visible: orderItem?.ocr?.id, 
      key: '3',
      label: 'Extração de dados',
      onClick: () => {
        const itemsId = orderItem?.ocr?.id || '';
        const fileName = `cbrdoc-IA-pedido-${orderItem.order.orderNumber}`
        handleDownload(`reports/ai-answers/xlsx?ocr_id=${itemsId}`, fileName)
      },
    },
  ];

  return (
    <div className="flex flex-1 relative">
      <Tabs
        defaultActiveKey="original"
        className="full-content-tabs w-full pb-0 pt-0 bg-white rounded-lg justify-start"
        tabBarStyle={{ marginLeft: '1.5rem', lineHeight: '14px'  }}
        onChange={onChange as any}
        items={tabItems}
      />
      
      <Dropdown 
        menu={{ items: downloadOptions }}
        className="absolute cursor-pointer right-3 top-2"
        dropdownRender={menu => (
          <div className="dropdown-options-primary">
            {menu}
          </div>
        )}
      >
        <div className="bg-primary gap-4 flex items-center p-2 px-3 text-white rounded-sm">
          <Icon name="download" /> <p className="leading-[15px]">Baixar</p> <Icon name="chevron-down" />
        </div>
      </Dropdown>
    </div>
  )
}
