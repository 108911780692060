import React from 'react'

import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { getDeliveryInformation } from '../helpers'

function DeliveryInfo({ order }: { order: ShoppingCartOrder }) {
  const text = getDeliveryInformation(order)

  return text ? <span title={text}>{text}</span> : <span></span>
}

export default DeliveryInfo
