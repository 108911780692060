import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { TIPO, TIPO_DOCUMENTO } = FieldName

type CertidaoSPUFields = Record<
  | FieldName.CPF
  | FieldName.CNPJ
  | FieldName.NOME_PF
  | FieldName.NUMERO_RIP
  | FieldName.TIPO
  | FieldName.TIPO_DOCUMENTO,
  FieldConfig
>

class CertidaoSPU extends Certificate {
  formFields: CertidaoSPUFields = {
    tipo: Fields.tipo,
    tipo_documento: {
      ...Fields.tipo_documento,
      stateKey: undefined,
      options: [
        { label: 'CNPJ', value: 'cnpj' },
        { label: 'CPF', value: 'cpf' },
        { label: 'Nome', value: 'nome' },
        { label: 'RIP', value: 'rip' },
      ],
      apiExtraInfo: undefined,
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
      labelInValue: true,
      dataScope: 'service',
      ignoreOnKitsAutomaticForm: true,
    },
    cnpj: {
      ...Fields.cnpj,
      showIf: ({ tipo_documento }) => tipo_documento === 'cnpj',
    },
    cpf: {
      ...Fields.cpf,
      showIf: ({ tipo_documento }) => tipo_documento === 'cpf',
    },
    nome_pf: {
      ...Fields.nome_pf,
      showIf: ({ tipo_documento }) => tipo_documento === 'nome',
    },
    numero_rip: {
      ...Fields.numero_rip,
      showIf: ({ tipo_documento }) => tipo_documento === 'rip',
      dataScope: 'registree',
    },
  }

  constructor() {
    super({
      id: 72,
      orderNamePrefixField: TIPO_DOCUMENTO,
      priceByFields: [TIPO],
      shortName: 'Certidão SPU',
      shoudAutoLoadDynamicFields: true,
      steps: ['dados'],
    })
  }
}

const service = new CertidaoSPU().initialize()

export default service
