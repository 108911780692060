import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { FeatureModal, FeatureModalConfig } from 'domain/app'
import { useResetCreateOrder } from 'domain/createOrder/document'
import { useIsCustomerPostPaid } from 'domain/customers'
import { ServiceIconName } from 'domain/servicesCBRdoc'
import { GridTwoColumns, Section } from 'layouts'
import { CardButton } from 'ui'
import { useCheckPermission } from 'domain/auth'

export default function SelectServiceTypePage() {
  const history = useHistory()
  const isPostPaid = useIsCustomerPostPaid()
  const checkPermission = useCheckPermission()
  const canExtractDataUsingAi = checkPermission('canExtractDataUsingAi') 

  useResetCreateOrder()

  return (
    <Section fullWidth className="bg-gray-100 xl:max-w-6xl">
      <GridTwoColumns>
        <CardButton
          title="Solicitar um documento"
          icon="file"
          subTitle="As principais opções de documentos e certidões para atender sua necessidade."
          description="Solicite em formato físico ou digital - nós fazemos a busca e entregamos conforme sua escolha."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_DOCUMENT)}
        />

        <CardButton
          title="Fazer uma pesquisa"
          icon={ServiceIconName.Research}
          iconSize={36}
          subTitle="Pesquise documentos em milhares de instituições de todo o Brasil."
          description="Após a busca, você pode solicitar a emissão da documentação de que precisar."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_RESEARCH)}
        />

        {canExtractDataUsingAi && (
          <CardButton
            title="Extrair Dados de Documentos"
            icon={ServiceIconName.AI}
            iconSize={32}
            subTitle="Extraia os campos mais importantes de seus documentos automaticamente"
            description="Faça upload de qualquer tipo de documento e extraia os dados mais importantes com a Extração de Dados"
            onClick={() => history.push(RoutePaths.CREATE_ORDER_AI)}
          />
        )}

        <CardButton
          title="Solicitar um kit"
          icon="folder"
          subTitle="Solicite ou crie um kit predefinido de documentos."
          description="Ideal para Due Diligence. Escolha os documentos, configure os campos fixos e crie seu kit."
          onClick={() => history.push(RoutePaths.KITS)}
          iconSize={36}          
        />

        <CardButton
          title="Solicitar uma Diligência"
          icon={ServiceIconName.Diligence}
          iconSize={32}
          subTitle="Serviço de correspondentes jurídicos em várias cidades."
          description="Por exemplo: diligências em prefeituras, cartórios, juntas comerciais e outros locais."
          onClick={() => history.push(RoutePaths.CREATE_ORDER_DUE_DILIGENCE)}
        />

        {isPostPaid && (
          <CardButton
            title="Registrar e Lavrar"
            icon={ServiceIconName.DocumentRegistration}
            iconSize={32}
            subTitle="Registre documentos e lavre suas atas notariais"
            description="Realize o registro de contratos, CPRs, averbações em matrículas, entre outros documentos, e faça a lavratura de atas notariais de forma prática e eficiente."
            onClick={() => history.push(RoutePaths.CREATE_ORDER_DOCUMENT_REGISTRATION)}
            isNew
          />
        )}
      </GridTwoColumns>

      <FeatureModal config={config} show={isPostPaid === true} />
    </Section>
  )
}

const config: FeatureModalConfig = {
  id: 'rtd',
  label: 'Nova funcionalidade',
  icon: 'stamp',
  title: 'Registre seus títulos e documentos',
  texts: [
    // eslint-disable-next-line quotes
    "Agora você pode registrar títulos e documentos através de nossa plataforma. Registre contratos, atas, CPR's, averbações em matrículas e muito mais.",
    'Experimente esta nova funcionlidade e simplifique seu processo de registro.',
  ],
  btnOK: {
    label: 'Fazer Registro',
    href: RoutePaths.CREATE_ORDER_DOCUMENT_REGISTRATION,
  },
  btnCancel: 'Testar mais tarde',
}
