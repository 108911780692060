import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import { getStorageData, setStorageData } from 'utils/localStorage'
import { ShoppingCartOrder, ShoppingCartState } from '../types'

export const SHOPPING_CART_PERSISTED = 'shoppingCart2'

const persistedShoppingCartState: ShoppingCartState = getStorageData(SHOPPING_CART_PERSISTED)

const getInitialShoppingCart = (): ShoppingCartState => {
  return {
    shoppingCartId: uuidv4(),
    totalPrice: 0,
    orders: [],
  }
}

const initialOrPersistedOrder: ShoppingCartState = persistedShoppingCartState || getInitialShoppingCart()

const shoppingCartStateSlice = createSlice({
  name: 'shoppingCartState',
  initialState: initialOrPersistedOrder,
  reducers: {
    addOrder: (state, action: PayloadAction<ShoppingCartOrder>) => {
      const newOrders = [...state.orders]
      newOrders.push(action.payload)
      state.orders = newOrders
      state.totalPrice = sumPrices(state.orders)
      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    addOrders: (state, action: PayloadAction<ShoppingCartOrder[]>) => {
      state.orders = [...state.orders, ...action.payload]
      state.totalPrice = sumPrices(state.orders)
      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    removeOrder: (state, action: PayloadAction<number>) => {
      const newOrders = [...state.orders]
      newOrders.splice(action.payload, 1)
      state.orders = newOrders
      state.totalPrice = sumPrices(state.orders)

      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    removeOrders: (state, action: PayloadAction<string[]>) => {
      const newOrders: any = state.orders
        .map((order, index) => ({ ...order, key: String(index) }))
        .filter(o => !action.payload.includes(o.key))

      state.orders = newOrders
      state.totalPrice = sumPrices(state.orders)

      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    removeOrderByCartId(state, action: PayloadAction<string>) {
      const newOrders = state.orders.filter((val: any) => val.cartItemId !== action.payload)
      state.orders = newOrders
      state.totalPrice = sumPrices(state.orders)

      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    resetState: () => {
      const initialState = getInitialShoppingCart()
      setStorageData(SHOPPING_CART_PERSISTED, initialState)
      return initialState
    },
    updateOrder: (state, action: PayloadAction<ShoppingCartOrder>) => {
      state.orders = state.orders.map(o => {
        if (o.cartItemId === action.payload.cartItemId) {
          return action.payload
        }

        return o
      })
      state.totalPrice = sumPrices(state.orders)
      setStorageData(SHOPPING_CART_PERSISTED, state)
    },
    setOrderId: (state, action: PayloadAction<number | undefined>) => {
      state.orderId = action.payload
    }
  },
})

const sumPrices = (orders: ShoppingCartOrder[]) =>
  orders.reduce((total, order) => total + (order.orderDetails.totalValue ?? 0), 0)

export const shoppingCartStateActions = shoppingCartStateSlice.actions

export default shoppingCartStateSlice.reducer
