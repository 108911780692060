import React from 'react'

import { OrderOverview } from 'domain/orders/types'
import { RowItemsContainer } from 'ui'

import DownloadFinalizedItemsButton from './actions/DownloadFinalizedItemsButton'
import DownloadAIResultsButton from './actions/DownloadAIResultsButton'
import AddItemsToOrderButton from './actions/AddItemsToOrderButton'
import DownloadDossier from './actions/DownloadDossier'

interface Props {
  order: OrderOverview
}

function OrderActions({ order }: Props) {
  return (
    <RowItemsContainer title="Ações:" className="w-full lg:min-w-100" direction="row">
      {order.type !== 'AI' && (
        <>
          <AddItemsToOrderButton orderId={order.id} disable={order.isPriceQuotationApproved} />
          <DownloadFinalizedItemsButton order={order} />
          <DownloadDossier order={order} />
        </>
      )}

      {order.hasAiExtractData && (
        <DownloadAIResultsButton orderNumber={order.orderNumber} orderId={order.id} />
      )}
    </RowItemsContainer>
  )
}

export default OrderActions
