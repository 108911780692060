import React from 'react'

interface Props {
  children: React.ReactNode
  title: string
  className?: string
  direction?: 'row' | 'col'
}

function RowItemsContainer({ children, title, className, direction = 'row' }: Props) {
  return (
    <div className={`flex flex-col ${className}`}>
      <h4 className="text-gray-600 uppercase mb-2 text-xs">{title}</h4>

      <div className={`flex flex-wrap w-max gap-2 mb-4 ${direction === 'row' ? '' : 'flex-col items-start'}`}>{children}</div>
    </div>
  )
}

export default RowItemsContainer
