import React, { useReducer } from 'react'

import { useViewOrderItem } from 'domain/orderItem/shared'
import { Button } from 'ui'

import SetExpirationDateModal from './SetExpirationDateModal'

export default function SetExpirationDate() {
  const [orderItem, refresh] = useViewOrderItem()
  const [open, toggleOpen] = useReducer(prev => !prev, false)

  return (
    <>
      <Button icon="calendar-cancel" className="text-gray-700 font-bold" type="link" onClick={toggleOpen}>
        Vencimento
      </Button>

      <SetExpirationDateModal
        open={open}
        onClose={toggleOpen}
        onSuccess={refresh}
        orderItem={orderItem}
      />
    </>
  )
}
