import { motion } from 'framer-motion'

import { ServiceAPI } from 'domain/servicesCBRdoc/types'
import { LinkCard, LinkCardProps, Tag } from 'ui'
import FavoriteStar from './ServiceLinkFavoriteStar'

export interface ServiceLinkProps extends Omit<LinkCardProps, 'extra' | 'heading' | 'icon'> {
  service: ServiceAPI
  isNew?: boolean
}

export default function ServiceLink({
  onClick,
  service,
  children,
  isNew = false,
  ...props
}: ServiceLinkProps) {
  return (
    <motion.li layout transition={transition}>
      <LinkCard
        icon={service.icon}
        heading={service.name}
        extra={
          <>

            {isNew && <div><span className="bg-success-500 rounded-full text-white px-6 py-1 text-xs text-center">Novidade</span></div>}

            {service.instant_delivery && (
              <Tag className="ml-2 mr-1 h-5 text-center leading-6">
                Entrega imediata
              </Tag>
            )}

            <FavoriteStar
              isFavorite={service.is_favorite}
              serviceId={service.id}
              serviceType={service.type}
            />
          </>
        }
        onClick={onClick}
        {...props}
      >
        {children}
      </LinkCard>
    </motion.li>
  )
}

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120,
}
