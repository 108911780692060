import React from 'react'

import { useHasPostpaidFilters } from 'domain/invoices/state'
import { AlertNoOrders } from 'domain/orders'
import { Loading } from 'ui'

import usePostpaidInvoicesQuery from '../services/usePostpaidInvoicesQuery'
import PostPaidInvoicesList from './PostPaidInvoicesList'

function PostpaidInvoices() {
  const hasFilters = useHasPostpaidFilters()

  const { data: invoices, isFetching, isLoading } = usePostpaidInvoicesQuery()

  if (isLoading) {
    return <Loading />
  }

  if (invoices?.length === 0) {
    if (hasFilters) {
      return <div className="py-7 pl-3">Não foi encontrada nenhuma fatura.</div>
    }

    return (
      <AlertNoOrders
        title="Você ainda não possui faturas para esse mês"
        description="Depois que você fizer um pedido, as faturas aparecerão aqui."
      />
    )
  }

  return <PostPaidInvoicesList invoices={invoices!} loading={isFetching} />
}

export default PostpaidInvoices
