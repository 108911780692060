import React, { useMemo } from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'

import { errorHandler } from 'api/errors'
import { useValidateExplorerItemName } from 'domain/myFiles/helpers'
import { createExplorerItem } from 'domain/myFiles/services/api'
import { Button, Modal, SubmitButton } from 'ui'

interface Props {
  parentId?: number | null
  onFolderCreated: (folder: any) => void
}

export default function CreateFolder({ parentId, onFolderCreated }: Props) {
  const [modalOpen, setModalVisible] = React.useState(false)
  const [form] = Form.useForm()
  const { items, setSearchTerm, isFetching } = useValidateExplorerItemName(form)

  const handleSubmit = async (values: any) => {
    try {
      const response = await createExplorerItem({ name: values?.name, type: 'folder', parentId: parentId })
      setModalVisible(false)
      onFolderCreated(response)
    } catch (error) {
      errorHandler(error, { code: '0x703', showBackError: true })
    }
  }

  const isFormInvalid = useMemo(() => {
    const names = Object.keys(form.getFieldsValue())
    const errors = form.getFieldsError()
    const hasError = errors.some((item) => item.errors.length > 0)
    return hasError || isFetching || form.isFieldsValidating(names) || (items && items.length > 0)
  }, [isFetching, form.getFieldsError(), items])

  return (
    <>
      <Modal open={modalOpen} title="Criar pasta" onCancel={() => setModalVisible(false)} form={form}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          scrollToFirstError
          className="max-w-lg"
        >
          <Form.Item name="name" label="Nome" rules={requiredRule}>
            <Input
              ref={(input: any) => input && input.focus()}
              maxLength={120}
              placeholder="Digite o nome da pasta"
              onChange={ev => setSearchTerm(ev.target.value)}
            />
          </Form.Item>

          <SubmitButton label="Criar" loading={isFetching} disabled={isFormInvalid} />
        </Form>
      </Modal>

      <Button
        type="primary"
        icon="folder-plus"
        iconStroke={1.5}
        iconSize={24}
        onClick={() => {
          setModalVisible(true)
        }}
        ghost
        className="px-4 mr-5 rounded font-semibold"
      >
        Criar pasta
      </Button>
    </>
  )
}

const requiredRule: Rule[] = [
  {
    required: true,
    message: 'Informe um nome para a pasta',
    validator: (_: any, value: string) => {
      if (!value.trim()) {
        return Promise.reject('Informe um nome para a pasta')
      }
      return Promise.resolve()
    }
  }
]
