import { useState, useMemo } from 'react'
import { ViewCertificateTabs } from 'domain/orderItem/certificate'
import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'
import { DocumentTabName } from 'domain/orderItem/types'

import ViewAIActions from './ViewAIActions'
import { OpenAILayout, OpenAIQuestions } from 'domain/openAI/enrich'

export default function ViewAI() {
  const [activeTab, setActiveTab] = useState<DocumentTabName>('original')
  const { explorerItem, ocr } = useGetOrderItem()
  const { aiModel, file } = explorerItem ?? {}

  if (!file) return null

  return (
    <ViewOrderLayout showSidebar sidebarActions={<ViewAIActions />}>
      <ViewCertificateTabs
        fileUrl={file.signedUrl}
        ocr={ocr!}
        openAITab={
          <OpenAILayout
            ocr={ocr}
            sidebar={<OpenAIQuestions questions={aiModel?.questions} />}
          />
        }
        onChange={setActiveTab as any}
      />
    </ViewOrderLayout>
  )
}
