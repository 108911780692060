import { atom, useAtomValue } from 'jotai'

import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { KitFieldConfig } from '../types'
import { kitAtom } from './kit'

type ServicesFieldsConfig = Partial<Record<number, {
  hasLocationStep: boolean
  fields: KitFieldConfig[]
  shoudAutoLoadDynamicFields: boolean | undefined
}>>

const servicesFieldsConfigAtom = atom<ServicesFieldsConfig>(get => {
  const kit = get(kitAtom)
  const fieldsConfig: ServicesFieldsConfig = {}

  kit.items.forEach(kitItem => {
    const serviceCode = kitItem.service.code

    if (fieldsConfig[kitItem.id] === undefined) {
      const serviceConfig = getServiceConfig<Certificate>(serviceCode)
      const hasDontKnowBookPageField = serviceConfig.formFields[FieldName.NAO_SEI_LIVRO_PAGINA]
      const serviceFields: FieldConfig[] = []
      let hasLocationStep = false

      Object.values(serviceConfig.formFields).forEach(field => {
        if (field.kind === 'location') {
          hasLocationStep = true
        } else if (
          field.dataScope === 'service' &&
          !field.ignoreOnKitsAutomaticForm &&          
          (!hasDontKnowBookPageField || (field.name !== FieldName.LIVRO && field.name !== FieldName.PAGINA))
        ) {
          serviceFields.push(field)
        }
      })

      fieldsConfig[kitItem.id] = {
        hasLocationStep,
        fields: serviceFields,
        shoudAutoLoadDynamicFields: serviceConfig.shoudAutoLoadDynamicFields
      }
    }
  })

  return fieldsConfig
})

export const useGetServicesFieldsConfig = () => useAtomValue(servicesFieldsConfigAtom)

export const useGetServiceFieldsConfig = (itemId: number) => {
  return useAtomValue(servicesFieldsConfigAtom)[itemId]
}
