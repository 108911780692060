import { Modal } from 'antd'
import { useDispatch } from 'react-redux'

import { shoppingCartStateActions } from 'domain/shoppingCart'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { ValidationError } from '../types'

function useCheckoutValidationErrorsModal() {
  const dispatch = useDispatch()  

  return function showCheckoutModalError(
    validationErrors: ValidationError[],
    orderItems: ShoppingCartOrder[]
  ) {
    Modal.error({
      icon: false,
      title: 'Ocorreu um erro ao finalizar seu pedido',
      content: (
        <div>
          <p>Os seguintes erros foram retornados:</p>

          {validationErrors?.map(res => (
            <div key={res.index} className="text-3xs border-t leading-3 border-gray-300 pt-4 mt-4">
              <h2 className="pb-2 font-bold text-base leading-5">
                {orderItems[res.index].selectedService?.name}:
              </h2>

              {res.errors && Object.values(res.errors).flat().map((message, index) => (
                <p key={index} className="text-xs pb-2">
                  {getMessage(message)}
                </p>
              ))}
            </div>
          ))}

          <p className="font-bold mt-4">Deseja remover os itens com problema?</p>
        </div>
      ),
      closable: true,
      maskClosable: true,
      okText: 'Remover',
      okType: 'danger',
      onOk() {
        const orderKeys = validationErrors.map(res => String(res.index))
        dispatch(shoppingCartStateActions.removeOrders(orderKeys))
      },
    })
  }
}

function getMessage(rawMessage: string) {
  const mark = 'detailed service data.'
  const endIndex = rawMessage.indexOf(mark)

  if (endIndex > -1) {
    const startIndex = rawMessage.indexOf(' orders.') + 1
    const remove = rawMessage.substring(startIndex, endIndex + mark.length)
    return rawMessage.replace(remove, '')
  }

  return rawMessage
}

export default useCheckoutValidationErrorsModal
