import { useGetSortableFormItems } from 'domain/createOrder/document/state'
import { ErrorBoundary } from 'ui'

import ItemPrice from './ItemPrice'
import DeliveryInfo from './MultipleItemsDeliveryInfo'
import ItemsTotalPrice from './MultipleItemsTotalPrice'
import CreateOrderSidebar from '../Sidebar'

export default function MultipleItemsSidebar() {
  const formItems = useGetSortableFormItems()

  return (
    <CreateOrderSidebar
      cardContent={
        <>
          <div className="py-1 empty:hidden">
            {formItems.map((item, index) => {
              const isDeletable = formItems.length > 1

              return (
                <ItemPrice key={item.id} item={item} itemIndex={index} deletable={isDeletable} />
              )
            })}
          </div>
          <ErrorBoundary>
            <DeliveryInfo items={formItems} />
          </ErrorBoundary>
        </>
      }
      footer={<ItemsTotalPrice />}
    />
  )
}
