import React, { useReducer } from 'react'
import { Button } from 'antd'

import { OneResultByRowCheckbox } from 'domain/reports'
import { Modal } from 'ui'
import { useDownload } from 'utils/export'

type Props = {
  orderId: number
  orderNumber: number
}

export default function DownloadOrderItemsReportButton({ orderId, orderNumber }: Props) {
  const [open, toggleModal] = useReducer(prev => !prev, false)
  const [oneResultPerRow, toggleResultOption] = useReducer(prev => !prev, false)
  const [handleDownload, loading] = useDownload()

  const onDownload = async () => {
    const fileName = `cbrdoc-dossie-pedido-${orderNumber}`
    await handleDownload(`purchases/${orderId}/report?oneResultPerRow=${oneResultPerRow}`, fileName)
    toggleModal()
  }

  return (
    <>
      <Button onClick={toggleModal} loading={loading} type="link" size="small">
        Baixar dossiê (resumo) em Excel
      </Button>

      <Modal open={open} onCancel={toggleModal} title="Exportar para Excel">
        <OneResultByRowCheckbox onToggle={toggleResultOption} />

        <Button type="primary" block onClick={onDownload} loading={loading}>
          Exportar
        </Button>
      </Modal>
    </>
  )
}
