import React from 'react'

import { PrintButton, ReportProblemButton, SidebarContainer, useGetOrderItem } from 'domain/orderItem/shared'
import { DownloadButton } from 'ui'

export default function ViewAIActions() {
  const { fileURL, id, explorerItem } = useGetOrderItem()
  const file = fileURL ?? explorerItem?.file.signedUrl

  const actions = [
    { label: <PrintButton fileURL={file} />, key: 'item-2' },
    { label: <ReportProblemButton itemId={id} />, key: 'item-4' },
  ]

  return (
    <SidebarContainer
      downloadButton={<DownloadButton fileURL={file} />}
      actions={actions}
    />
  )
}
