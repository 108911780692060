import { InputProps } from 'antd/lib/input'
import { cpf as cpfUtils } from 'cpf-cnpj-validator'

import { Fields } from 'domain/servicesCBRdoc'
import MaskedInput from './MaskedInput'

interface Props extends Omit<InputProps, 'defaultValue' | 'name' | 'value'> {
  defaultValue?: string
  itemId?: number | string
  label?: string
  value?: string
  name?: string
}

export default function CPFInput({  
  label = 'CPF',
  name = 'cpf',
  required = true, 
  itemId, 
  ...props
}: Props) {
  const validationRules = [
    {
      validator: (rule: object, value: string) => {
        if (!cpfUtils.isValid(value, true)) {
          return Promise.reject('Informe um CPF válido.')
        }

        return Promise.resolve()
      },
    },
  ]

  return (
    <MaskedInput
      mask={Fields.cpf.mask!}
      name={itemId !== undefined ? [itemId, name] : name}
      label={label}
      required={required}
      rules={required ? validationRules : undefined}
      placeholder="000.000.000-00"
      id="cpf"
      {...props}
    />
  )
}
