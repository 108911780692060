import { Spin } from 'antd'

import { LabelInValue, LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { AllExtraInfos } from 'domain/orderItem/types'
import { ResearchTimeField, SelectField } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoJusticaEstadualConfig'
import ComarcaField from './ComarcaField'
import InstanciaField from './InstanciaField'
import ModelsField from './ModelField'

interface Props {
  dynamicFields: AllExtraInfos | undefined
  loading: boolean
  service: SelectedService
  federativeUnit: string | undefined
  entityType: LegalEntity | undefined
  instance: string | undefined
  type: string | undefined
  comarca?: string
  model?: string | number | undefined
  itemId?: number
  onChangeModel?: (model: string) => void
  onChangeInstance?: (instance: string) => void
  onChangeNotaries?: (notaries: LabelInValue[]) => void
  onChangeComarca: (comarca: string) => void
  onChangeResearchTime: (researchTime: number) => void
}

export default function ModelsAndSpecificFields({
  dynamicFields,
  service,
  federativeUnit,
  entityType,
  instance,
  itemId,
  comarca,
  model,
  loading,
  onChangeComarca,
  onChangeModel,
  onChangeInstance,
  onChangeNotaries,
  onChangeResearchTime,
}: Props) {
  if (!federativeUnit) {
    return null
  }

  const Models = (
    <ModelsField
      service={service}
      federativeUnit={federativeUnit}
      legalEntity={entityType}
      instance={instance}
      comarca={comarca}
      itemId={itemId}
      onChange={onChangeModel}
    />
  )

  const hasModelFirst = federativeUnit !== 'PI' && federativeUnit !== 'PR'

  return (
    <>
      {hasModelFirst && Models}

      {(['PR', 'RJ'].includes(federativeUnit) || dynamicFields?.comarcas) && (
        <ComarcaField
          itemId={itemId}
          service={service}
          federativeUnit={federativeUnit}
          legalEntity={entityType}
          model={model}
          options={dynamicFields?.comarcas}
          onChange={onChangeComarca}
        />
      )}

      {(federativeUnit === 'PI' || dynamicFields?.instancias) && (
        <InstanciaField
          service={service}
          federativeUnit={federativeUnit}
          legalEntity={entityType}
          options={dynamicFields?.instancias}
          onChange={onChangeInstance}
          itemId={itemId}
          initialValue={instance}
        />
      )}

      {!hasModelFirst && Models}

      <Spin spinning={loading}>
        {dynamicFields?.cartorios && (
          <SelectField
            field={config.dynamicFields.url_cartorio}
            options={dynamicFields?.cartorios}
            onChange={onChangeNotaries}
            itemId={itemId}
            labelInValue
          />
        )}

        {dynamicFields?.['tempos-pesquisa'] && (
          <ResearchTimeField
            options={dynamicFields['tempos-pesquisa']}
            itemId={itemId}
            onChange={onChangeResearchTime}
          />
        )}
      </Spin>
    </>
  )
}
