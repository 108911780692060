import React, { useMemo, useState } from 'react'
import { Form, Input } from 'antd'

import { useValidateExplorerItemName } from 'domain/myFiles/helpers'
import { useRenameMutation } from 'domain/myFiles/services'
import { useExplorerItem } from 'domain/myFiles/state'
import { ExplorerItem } from 'domain/myFiles/types'
import { SubmitButton } from 'ui'

interface Props {
  onItemRenamed?: (item: ExplorerItem) => void
}

export default function RenameItem({ onItemRenamed }: Props) {
  const [form] = Form.useForm()
  const [explorerItem, setExplorerItem] = useExplorerItem()
  const [newName, setNewName] = useState(explorerItem!.name || '')
  const { items, setSearchTerm, isFetching } = useValidateExplorerItemName(form)

  const { renameItem, loading } = useRenameMutation({
    onSuccess: () => {
      const updatedItem = { ...explorerItem!, name: newName }
      setExplorerItem(updatedItem)
      onItemRenamed && onItemRenamed(updatedItem)
    },
  })

  const handleSubmit = () => {
    renameItem({ id: explorerItem!.id, name: newName })
  }

  const isFormInvalid = useMemo(() => {
    const names = Object.keys(form.getFieldsValue())
    const errors = form.getFieldsError()
    const hasError = errors.some((item) => item.errors.length > 0)
    const newValueEmpty = !newName?.trim().length 
    const isOtherItem = !!items?.find(i => i.id !== explorerItem!.id)
    return newValueEmpty || hasError || isFetching || form.isFieldsValidating(names) || isOtherItem
  }, [newName, isFetching, form.getFieldsError(), items])

  return (
    <div className="w-full">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        scrollToFirstError
        className="max-w-lg flex items-start justify-between w-full mb-3"
        initialValues={{ name: explorerItem?.name }}
      >
        <Form.Item
          label={<span className="font-normal text-gray-500">Nome do arquivo</span>}
          name="name"
          className="mb-2 mr-2 w-full"
          rules={requiredRule}
        >
          <Input
            onChange={e => {
              setNewName(e.target.value)
              setSearchTerm(e.target.value)
            }}
            placeholder="Digite o nome"
            size="small"
            className="h-11 px-2"
          />
        </Form.Item>

        <SubmitButton
          label="Renomear"
          className="h-11 text-xs mt-1"
          disabled={isFormInvalid}
          loading={loading}
          marginTop="mt-5"
        />
      </Form>
    </div>
  )
}

const requiredRule = [
  {
    required: true,
    message: 'Informe um nome',
  },
]
