import { useCreateOrderState } from 'domain/createOrder/document/state'
import useCheckSimilarOrders from 'domain/createOrder/shared/services/useCheckSimilarOrders'
import { SimilarOrdersError } from 'domain/createOrder/shared/errors'
import { checkSimilarOrdersBeforeSubmit } from 'domain/createOrder/shared/helpers'
import { SimilarOrder } from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'

export type OrderExistSameNotary = {
  itemId: number
  document?: string
  name?: string
  similarOrders: any[]
}

export default function useCheckSimilarOrderMultipleItems() {
  const checkSimilarOrders = useCheckSimilarOrders()
  const createOrderState = useCreateOrderState()
  const service = getServiceConfig(createOrderState.selectedService.code!)

  return async function checkMultipleItemsSimilarOrders() {
    let shoppingCartItems = service.convertToCartItems(createOrderState)
    const repeatedItemsOnForm = checkRepeatedItemsOnForm(shoppingCartItems)

    if (repeatedItemsOnForm.length > 0) {
      const repeatedIds = repeatedItemsOnForm.map(o => o.formItemId!)

      const filterCartItemsLeftToCheck = (cartItem: ShoppingCartOrder) => {
        const formItemId = Object.values(cartItem.formItems!)[0].id
        return !repeatedIds.includes(formItemId)
      }

      shoppingCartItems = shoppingCartItems.filter(filterCartItemsLeftToCheck)
    }

    // Checa itens similares no banco de dados
    const promises = shoppingCartItems.map(checkSimilarOrders)

    const allSimilarOrders = await Promise.allSettled(promises)
    const errorsFromAPI = allSimilarOrders
      .filter(res => res.status === 'rejected')
      .map((error: any) => error.reason.data)
      .flat()

    const allErrors = [...repeatedItemsOnForm, ...errorsFromAPI]

    if (allErrors.length > 0) {
      throw new SimilarOrdersError(allErrors)
    }
  }
}

function checkRepeatedItemsOnForm(shoppingCartItems: ShoppingCartOrder[]) {
  const errorsWithoutRepetition: Record<string, SimilarOrder> = {}

  shoppingCartItems.forEach(item => {
    try {
      checkSimilarOrdersBeforeSubmit(item, shoppingCartItems)
    } catch (error: any) {
      if (error instanceof SimilarOrdersError) {
        error.data.forEach(data => {
          if (!errorsWithoutRepetition[data.checkedValues!]) {
            errorsWithoutRepetition[data.checkedValues!] = {
              ...data,
              isRepeatedOnForm: true,
            }
          }
        })
      } else {
        console.error(error.message)
      }
    }
  })

  return Object.values(errorsWithoutRepetition)
}
