import React from 'react'
import { Button, Dropdown } from 'antd'

import { OrderOverview } from 'domain/orders/types'
import { Icon } from 'ui'

import DownloadOrderItemsReportButton from './DownloadOrderItemsReportButton'
import DownloadOrderOverviewPDFButton from './DownloadOrderOverviewPDFButton'

interface Props {
  order: OrderOverview
}

export default function DownloadDossier({ order }: Props) {
  return (
    <Dropdown
      menu={{
        items: [
          {
            key: 'excel',
            label: <DownloadOrderItemsReportButton orderId={order.id} orderNumber={order.orderNumber} />,
          },
          {
            key: 'pdf',
            label: <DownloadOrderOverviewPDFButton order={order} />,
          },
        ],
      }}
    >
      <Button type="primary" ghost>
        <span className="flex flex-row flex-nowrap items-center gap-1">
          Baixar dossiê <Icon name="chevron-down" />
        </span>
      </Button>
    </Dropdown>
  )
}
