import { convertToSelectedService } from 'domain/createOrder/document'
import { ResultCode } from 'domain/orders/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { Research } from 'domain/servicesCBRdoc/config'

import { OrderItem, PlaceOrderDefaultValuesService, SelectedService } from '../../types'
import { OrderItemAPI, PlaceOrderDefaultValuesServiceAPI } from '../../typesAPI'

export default function convertResearchResults(originalItem: OrderItemAPI, orderItem: OrderItem): OrderItem {
  const {
    auto_purchase_certificate_from_result_positive,
    auto_purchase_certificate_from_result_negative,
    place_order_default_values,
    result_details,
    service
  } = originalItem

  const serviceConfig = getServiceConfig<Research>(service.code)

  orderItem.researchResults = serviceConfig.convertResults(orderItem, result_details)

  const dataToPlaceOrderPositive = place_order_default_values[ResultCode.POSITIVE]
  const dataToPlaceOrderNegative = place_order_default_values[ResultCode.NEGATIVE]

  return {
    ...orderItem,
    researchResultsOrderSettings: {
      positive: {
        autoPurchase: auto_purchase_certificate_from_result_positive,
        placeOrder: normalizeDataToPlaceOrder(dataToPlaceOrderPositive),
      },
      negative: {
        autoPurchase: auto_purchase_certificate_from_result_negative,
        placeOrder: normalizeDataToPlaceOrder(dataToPlaceOrderNegative),
      },
    },
  }
}

function normalizeDataToPlaceOrder(
  original: PlaceOrderDefaultValuesServiceAPI
): PlaceOrderDefaultValuesService | null {
  if (!original) {
    return null
  }

  const { data, service, service_category } = original

  return {
    service: convertToSelectedService(service, service_category?.id) as SelectedService,
    formData: data,
  }
}
