import React from 'react'

import { OrderResult, OrderType, ResultCode, StatusCode } from 'domain/orders/types'
import { RowItemsContainer } from 'ui'

import FilterByStatus from './FilterByStatus'
import FilterByResult from './FilterByResult'
import FilterExpired from './FilterExpired'

export default function OrderOverviewFilters({ orderType }: { orderType: OrderType }) {
  return (
    <RowItemsContainer title="Filtrar por:">
      <FilterByStatus text="Processando" statusCode={StatusCode.PROCESSANDO} />
      <FilterByStatus text="Finalizados" statusCode={StatusCode.FINALIZADO} />
      {orderType !== OrderType.AI && (
        <>
          <FilterByResult text="Positivos" result={ResultCode.POSITIVE} />
          <FilterByResult text="Negativos" result={ResultCode.NEGATIVE} />
          <FilterByStatus statusCode={StatusCode.ACAO_NECESSARIA} />
          <FilterExpired />
        </>
      )}
    </RowItemsContainer>
  )
}
