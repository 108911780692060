import React from 'react'
import { Table } from 'antd'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { InvoicesPagination } from 'domain/invoices/components'
import {
  useResetTableSelection,
  useSetSortDirection,
  useSetPostpaidSortField,
  useTableSelection,
} from 'domain/invoices/state'
import { PostpaidInvoice } from 'domain/invoices/types'

import PostpaidInvoicesMassActions from './PostpaidInvoicesMassActions'

interface Props {
  loading: boolean
  invoices: PostpaidInvoice[]
}

function PostPaidInvoicesList({ invoices, loading }: Props) {
  const resetTableSelection = useResetTableSelection()
  const setSortField = useSetPostpaidSortField()
  const setSortDirection = useSetSortDirection()
  const [selected, setSelected] = useTableSelection()

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.columnKey ?? sorter.field)
    setSortDirection(sorter.order)
  }

  return (
    <div className="px-7 pb-7">
      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected.keys,
          hideSelectAll: false,
          preserveSelectedRowKeys: true,
          onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelected({ keys: selectedRowKeys, rows: selectedRows })
          },
        }}
        columns={columns}
        dataSource={invoices}
        onChange={handleTableChange}
        pagination={false}
        loading={loading}
        className="mask-text"
      />
      <InvoicesPagination />
      <PostpaidInvoicesMassActions onClose={resetTableSelection} />
    </div>
  )
}

const columns = [
  {
    title: 'Nome do pedido',
    dataIndex: ['orderItem', 'name'],
    key: 'order.name',
    sorter: true,
    render: (orderName: string, invoice: PostpaidInvoice) => (
      <Link to={Routes.ViewOrder(invoice.orderItem.id)}>
        <span className="font-bold text-secondary-500 hover:text-secondary-300">{orderName}</span>
      </Link>
    ),
  },
  {
    title: 'Nº do pedido',
    dataIndex: ['orderItem', 'orderItemNumber'],
    key: 'order.backoffice_id',
    sorter: true,
  },
  {
    title: 'Data do pedido',
    dataIndex: 'createdAtDisplay',
  },
  {
    title: 'Nota Fiscal',
    dataIndex: 'fiscalAmountDisplay',
    key: 'fiscal_amount',
    sorter: true,
    render: (text: string) => <span className="text-black-600 whitespace-no-wrap">{text}</span>,
  },
  {
    title: 'Nota De Débito',
    dataIndex: 'debitAmountDisplay',
    key: 'debit_amount',
    sorter: true,
    render: (text: string) => <span className="text-black-600 whitespace-no-wrap">{text}</span>,
  },
  {
    title: 'Data de envio',
    dataIndex: 'deliveredAtDisplay',
    render: (text: string) => <span className="text-black-600 text-2xs">{text}</span>,
  },
  {
    title: 'Usuário',
    dataIndex: ['orderItem', 'user', 'name'],
    render: (text: string) => <span className="text-2xs">{text}</span>,
  },
]

export default PostPaidInvoicesList
