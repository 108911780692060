import React from 'react'

import RoutePaths from 'app/routes/RoutePaths'

export default function HelpLine() {
  return (
    <div className="text-sm py-4 my-10 w-full max-w-2xl border-t border-b border-gray-400 flex justify-center items-center">
      <a href={RoutePaths.HELP_EXTERNAL} className="underline" target="_blank" rel="noopener noreferrer">
        Precisa de ajuda?
      </a>

      <div className="w-7 h-7 flex-none" />

      <a type="link" href={RoutePaths.CONTACT_EXTERNAL} className="underline" target="_blank" rel="noopener noreferrer">
        Canais atendimento
      </a>
    </div>
  )
}
