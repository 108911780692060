/* eslint-disable max-len */
import { FeedbackMessage } from 'domain/app'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { ResultCode, StatusCode } from 'domain/orders/types'
import { Icon } from 'ui'

export default function ViewCertificateStatus({ children }: { children: JSX.Element }) {
  const orderItem = useGetOrderItem()

  if (!orderItem.fileURL || orderItem.status !== StatusCode.FINALIZADO) {
    if (orderItem.result === ResultCode.POSITIVE) {
      return (
        <FeedbackMessage
          title="Pedido finalizado"
          alertType="error"
          icon={<Icon name="warning-file" className="text-6xl mt-5 mb-5 text-secondary-500" />}
        >
          <div className="my-6 mx-auto flex gap-4 p-4 py-2 bg-yellow-500 bg-opacity-15 border-2 border-yellow-500 rounded-lg w-1/2">
            <Icon name={'warning'} color="#FED77F" size={50} />
            <div className="text-sm">
              Infelizmente, para os dados informados, não é possível a emissão de certidão por meio da Internet.
              Por favor, dirija-se ao órgão emissor da certidão para mais informações.
            </div>
          </div>
        </FeedbackMessage>
      )
    }

    return (
      <FeedbackMessage
        status={orderItem.status}
        message={
          orderItem.status === StatusCode.AGUARDANDO_RESPOSTA
            ? `Estamos aguardando um retorno sobre o impedimento enviado em nosso canal de comunicação para prosseguirmos. 
          Em caso de dúvidas, por favor, entre em contato.`
            : `Não foi possível emitir a certidão desejada. 
          Verifique as informações e tente novamente, ou entre em contato para saber mais detalhes.`
        }
        alertType="error"
      />
    )
  }

  return children
}
