import React, { useRef, useReducer } from 'react'
import YouTube from 'react-youtube'

import { Button, Icon, Modal } from 'ui'

import AlertWithOrderButton from './AlertWithOrderButton'
import EmptyFiles from 'assets/images/empty-state-files.png'

interface Props {
  title: string
  description?: string
}

export default function AlertNoOrders({ title, description }: Props) {
  const videoRef = useRef<any>(null)
  const [modalReactVideoOpen, toggleModalReactVideoVisible] = useReducer(prev => !prev, false)

  return (
    <AlertWithOrderButton
      title={title}
      description={description}
      icon={<img alt="Vazio" src={EmptyFiles} height={154} className="mb-4" />}
      className="w-[300px] text-base py-2"
    >
      <div className="w-3 h-3 flex-none" />

      <Button
        size="middle"
        type="primary"
        onClick={() => {
          toggleModalReactVideoVisible()
          videoRef.current?.internalPlayer?.playVideo()
        }}
        block
        ghost
        className="w-[300px] text-base py-2"
      >
        <div className="w-full flex items-center justify-center">
          <div className="mt-1">Assistir o vídeo</div> <Icon name="play" size={24} className="text-xl ml-4" />
        </div>
      </Button>

      <Modal
        width={modalWidth}
        title="CBRDoc"
        open={modalReactVideoOpen}
        onCancel={() => {
          toggleModalReactVideoVisible()
          videoRef.current?.internalPlayer?.pauseVideo()
        }}
        className="ant-modal-center -mt-10"
        footer={null}
      >
        <YouTube
          ref={videoRef}
          videoId="bUWeqbv_IC0"
          opts={{
            height: String(modalWidth / 1.89),
            width: '100%',
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </Modal>
    </AlertWithOrderButton>
  )
}

let modalWidth = 1000

if (window.innerWidth > 800 && window.innerWidth < 1100) {
  modalWidth = window.innerWidth * 0.8
}

if (window.innerWidth < 800) {
  modalWidth = window.innerWidth * 0.95
}
