import { v4 as uuidv4 } from 'uuid'

import { MissingPriceParams } from 'domain/createOrder/shared/errors'
import { getDefaultFormItem, getInitialCreateOrder } from 'domain/createOrder/document'
import getPrice from 'domain/createOrder/shared/classes/getPrice'
import {
  CreateOrderForm,
  CreateOrderState,
  OrderDetails,
  Origin,
  SelectedService,
} from 'domain/createOrder/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import CBRdocDocument from 'domain/servicesCBRdoc/config/classes/CBRdocDocument'
import generateOrderName from './generateOrderName'
import { fieldNames } from 'domain/servicesCBRdoc/fields/components/AddressFields'

export default class CreateOrder {
  private createOrder: CreateOrderState | undefined
  protected dynamicFields?: FieldName[]
  public selectedService: SelectedService
  service: CBRdocDocument

  constructor(selectedService: SelectedService) {
    this.selectedService = selectedService
    this.service = getServiceConfig(selectedService.code!)
  }

  private transformFormDataIntoMultipleItems = (filledForm: CreateOrderForm) => {
    const formData: any = {[0]: {}}

    Object.entries(filledForm).forEach(([field, value]) => {
      const fieldConfig = this.service.getField(field)

      if (fieldConfig) {
        if (fieldConfig.dataScope === 'service') {
          formData[field] = value
        } else {
          formData[0][field] = value
        }
      }
    })

    const hasRegistreeFullAddress = !!this.service.getField(FieldName.REGISTRADO_ENDERECO_COMPLETO)
    if (hasRegistreeFullAddress) {
      Object.values(fieldNames).forEach((name) => {
        if((filledForm as any)[name]) {
          formData[0][name] = (filledForm as any)[name]
        }
      })
    }

    return formData
  }

  private transformIntoMultipleItems = () => {
    const {
      dontKnowBookPage,
      formData,
      orderDetails,
      selectedEntityType,
      selectedModel,
      selectedRegion,
      selectedResearchTime,
      selectedType,
    } = this.createOrder!

    const createOrderMultipleItems = {
      ...this.createOrder!,
      formData: this.transformFormDataIntoMultipleItems(formData),
      formItems: {
        '0': {
          ...getDefaultFormItem(),
          orderDetails,
          dontKnowBookPage,
          selectedEntityType,
          selectedModel,
          selectedRegion,
          selectedResearchTime,
          selectedType,
        },
      },
    }

    return createOrderMultipleItems
  }

  // Estático por enquanto, por causa do kit. O certo é ser protected
  static appendPrice = async (createOrders: CreateOrderState[], isPostPaid: boolean) => {
    const promises = createOrders.map(async createOrder => {
      try {
        const serviceConfig = getServiceConfig(createOrder.selectedService.code!)
        const shippingPrice = await getPrice(createOrder)

        if (shippingPrice) {
          const orderDetails: OrderDetails = {
            totalValue: isPostPaid ? shippingPrice.estimated_price_postpaid_customer : shippingPrice.price,
            estimatedDeliveryTime: shippingPrice.estimated_delivery_days,
          }

          const createOrderWithPrice = {
            ...createOrder,
            orderDetails,
          }

          if (serviceConfig.allowMultipleItems) {
            createOrderWithPrice.formItems['0'].orderDetails = createOrderWithPrice.orderDetails
          }

          return createOrderWithPrice
        }

        return createOrder
      } catch (error) {
        console.log('error', error)
        throw error
      }
    })

    const results = await Promise.allSettled(promises)
    const errors: any = results.filter(o => o.status === 'rejected')

    if (errors.length > 0) {
      console.log('errors', errors)
      throw new MissingPriceParams(errors.map(({ reason }: any) => reason))
    }

    return results.map(({ value }: any) => value)
  }

  private getSpecialField = (
    formData: CreateOrderForm,
    stateKey: keyof CreateOrderState,
    formFieldName: FieldName
  ) => {
    const specialField = Object.values(this.service.formFields).find(o => o.stateKey === stateKey)

    if (specialField) {
      const data = formData[specialField.name]

      // Em kit, o valor sempre vem como LabelInValue
      // Usamos a flag labelInValue para identificar o que deve manter LabelInValue ou pegar somente value
      if (specialField.labelInValue) {
        return typeof data === 'string' ? { value: data, label: data } : data
      }

      return data?.value ?? data
    }

    return formData[formFieldName]
  }

  private appendOrderName = () => {
    const orderName = generateOrderName(this.createOrder!)
    this.createOrder!.formData[NOME_ITEM_PEDIDO] = orderName
  }

  generateCreateOrder = (formData: CreateOrderForm, origin: Origin) => {
    this.createOrder = {
      ...getInitialCreateOrder(),
      cartItemId: uuidv4(),
      createStatus: 'review',
      dontKnowBookPage: formData[NAO_SEI_LIVRO_PAGINA] ?? false,
      dynamicFields: this.dynamicFields,
      formData,
      origin,
      selectedComarca: this.getSpecialField(formData, 'selectedComarca', COMARCA),
      selectedEntityType: formData[TIPO_PESSOA] ?? this.service.getDefaultLegalEntity(),
      selectedFormat: formData[FORMATO],
      selectedInstance: this.getSpecialField(formData, 'selectedInstance', INSTANCIA),
      selectedLocation: {
        todos_cartorios_cidade: formData[TODOS_CARTORIOS_CIDADE] ?? false,
        url_cartorio: formData[URL_CARTORIO],
        url_cidade: formData[URL_CIDADE],
        url_uf: formData[URL_UF],
      },
      selectedModel: this.getSpecialField(formData, 'selectedModel', MODELO),
      selectedRegion: this.getSpecialField(formData, 'selectedRegion', REGIAO),
      selectedResearchTime: this.getSpecialField(formData, 'selectedResearchTime', TEMPO_PESQUISA),
      selectedService: this.selectedService,
      selectedType: this.getSpecialField(formData, 'selectedType', TIPO),
    }

    if (!formData[NOME_ITEM_PEDIDO]) {
      this.appendOrderName()
    }

    if (this.service.allowMultipleItems) {
      return this.transformIntoMultipleItems()
    }

    return this.createOrder
  }
}

const {
  COMARCA,
  FORMATO,
  INSTANCIA,
  REGIAO,
  MODELO,
  NAO_SEI_LIVRO_PAGINA,
  NOME_ITEM_PEDIDO,
  TEMPO_PESQUISA,
  TIPO,
  TIPO_PESSOA,
  TODOS_CARTORIOS_CIDADE,
  URL_CARTORIO,
  URL_CIDADE,
  URL_UF,
} = FieldName
