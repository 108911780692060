import { KitItem } from 'domain/kits/types'

import { useGetServicesFieldsConfig } from '../../state'
import RemoveDocumentButton from '../kitEdition/RemoveDocumentButton'
import KitOrderServiceFields from './kitOrderServiceFields'
import KitOrderLocation from './KitOrderLocation'

interface Props {
  kitItem: KitItem
  autoFocus: boolean
}

export default function KitOrderServiceFormFields({ kitItem, autoFocus }: Props) {
  const servicesFormConfig = useGetServicesFieldsConfig()
  const kitItemConfig = servicesFormConfig[kitItem.id]

  const hasFieldsToFill =
    kitItemConfig &&
    kitItemConfig.fields?.length > 0 ||
    kitItemConfig!.hasLocationStep ||
    kitItemConfig!.shoudAutoLoadDynamicFields!

  if (!hasFieldsToFill) {
    return null
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-3 group mt-12 mb-4">
        <h3 id={'item_'+kitItem.id} className="text-secondary-500 font-bold text-lg">
          {kitItem.service.name}
        </h3>

        <RemoveDocumentButton kitItemId={kitItem.id} />
      </div>

      {kitItemConfig!.hasLocationStep && <KitOrderLocation kitItem={kitItem} autoFocus={autoFocus} />}
      
      <KitOrderServiceFields kitItem={kitItem} />
    </>
  )
}
