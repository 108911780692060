import React from 'react'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { OrderOrigin as OrderOriginType, OrderOverview } from 'domain/orders/types'

interface Props { 
  order: OrderOverview 
}

export default function OrderOrigin({ order }: Props) {
  if (order.recurrence) {
    return (
      <>
        <span>—</span>
        <span>
           A origem deste pedido foi a recorrência <b>{order.recurrence.name}</b>
        </span>
      </>
    )
  }

  if (order.originatedFromOrders.length > 0) {
    return <ResearchOrigins origins={order.originatedFromOrders} />
  }

  return null
}

function ResearchOrigins({ origins }: { origins: OrderOriginType[] }) {
  const isPlural = origins.length > 1

  return (
    <span className="flex flex-row flex-wrap gap-4 leading-4">
      <span className="md:min-w-fit mr-2">A origem deste pedido {isPlural ? 'foram as pesquisas' : 'foi a pesquisa'} </span>

      {origins.map(origin => (
        <Link key={origin.id} to={Routes.ViewOrder(origin.id)}>
          #{origin.orderNumber}
        </Link>
      ))}
    </span>
  )
}
