import { civilStatus, nationalities } from 'domain/app/data'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoSTFDistribuidorFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.DATA_NASCIMENTO
  | FieldName.ESTADO_CIVIL
  | FieldName.NACIONALIDADE
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.RG
  | FieldName.RG_EXPEDIDOR,
  FieldConfig
>

class CertidaoSTFDistribuidor extends Certificate {
  formFields: CertidaoSTFDistribuidorFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    rg: Fields.rg,
    rg_expedidor: {
      ...Fields.rg_expedidor,
      apiName: 'orgao_emissor',
    },
    mae: Fields.mae,
    pai: Fields.pai,
    nascimento: Fields.nascimento,
    // TODO: trazer esta informação do back
    estado_civil: {
      ...Fields.estado_civil,
      options: civilStatus,
      apiExtraInfo: undefined,
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
    // TODO: trazer esta informação do back
    nacionalidade: {
      ...Fields.nacionalidade,
      options: nationalities,
      apiExtraInfo: undefined,
      apiOptionsLabelKey: 'label',
      apiOptionsValueKey: 'value',
    },
  }

  constructor() {
    super({
      id: 35,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'Certidão Distribuidor',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoSTFDistribuidor().initialize()

export default service
