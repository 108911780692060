import React from 'react'

import { Icon, IconName } from 'ui'

interface Props {
  icon: IconName
  title: string
  description?: string | number
}

function OrderDetailsModalSection({ icon, title, description }: Props) {

  return (
    <div className="flex gap-2 items-start">
      <Icon name={icon} className="text-xl"/> 
      <div className="text-secondary-500 text-sm">
        <h2 className="font-bold">{title}</h2>
        <p className="mt-2">{description}</p>
      </div>
    </div>
  )
}

export default OrderDetailsModalSection
