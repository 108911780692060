import React from 'react'

import { ErrorBoundary } from 'ui'
import BasicLayout, { BasicLayoutProps } from './BasicLayout'

export interface PageLayoutProps extends BasicLayoutProps {
  extra?: React.ReactNode
  sidebar?: React.ReactNode
  children: React.ReactNode
  pageTitle: React.ReactNode
}

export default function PageLayout({ children, sidebar, ...props }: PageLayoutProps) {
  return (
    <BasicLayout innerClass="flex-col lg:flex-row lg:flex-nowrap" {...props}>
      <main className="flex flex-col flex-1 bg-gray-100 overflow-hidden h-full">{children}</main>
      <ErrorBoundary>{sidebar}</ErrorBoundary>
    </BasicLayout>
  )
}
