import React from 'react'

import { Button } from 'ui'

export default function PrintButton({ fileURL }: { fileURL: string | null | undefined }) {
  const isDisabled = !fileURL

  return (
    <Button
      icon="print"
      type="link"
      href={fileURL ?? '#'}
      target="_blank"
      className="text-gray-700 font-bold"
      rel="noopener noreferrer"
      size="small"
      disabled={isDisabled}
    >
      Imprimir
    </Button>
  )
}
