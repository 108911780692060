import { ReactElement, useMemo } from 'react'
import { Alert } from 'antd'

import { useGetOrderItem } from 'domain/orderItem'
import { Icon } from 'ui'

export default function ViewCertidaoPreviaMatriculaReport() {
  const { extractSummary } = useGetOrderItem()

  const data = useMemo(() => {
    if (!extractSummary) return null

    function extractAreaObject(area: unknown) {
      let areaValue = ''

      if (!area || typeof area !== 'object') {
        return areaValue
      }

      Object.entries(area).forEach(([key, value]: any) => {
        if (key.includes('total')) {
          areaValue = value
        }
      })
      return areaValue
    }

    const property = [
      {
        label: 'Última atualização',
        value: extractSummary.ultima_atualizacao,
      },
      {
        label: 'Tipo de ativo',
        value: extractSummary.tipo_ativo,
      },
      {
        label: 'Sub-tipo de ativo',
        value: extractSummary.subtipo_ativo,
      },
      {
        label: 'Endereço',
        value: (
          <p>
            {extractSummary.endereco}{' '}
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${extractSummary.endereco}`}
              target="_blank"
              rel="noreferrer"
              className="font-bold underline"
            >
              Ver no mapa
            </a>
          </p>
        ),
      },
      {
        label: 'Cidade',
        value: extractSummary.cidade,
      },
      {
        label: 'Estado',
        value: extractSummary.unidade_federativa,
      },
    ]

    if (extractSummary.area && typeof extractSummary.area === 'string') {
      property.push({
        label: 'Área',
        value: extractSummary.area,
      })
    } else if (extractSummary.area && typeof extractSummary.area === 'object') {
      Object.entries(extractSummary.area).forEach(([label, value]: any) => {
        property.push({
          label,
          value,
        })
      })
    }

    property.push({
      label: 'Valor do imóvel',
      value: extractSummary.valor,
    })

    return {
      summary: {
        area:
          typeof extractSummary.area === 'string'
            ? extractSummary.area
            : extractAreaObject(extractSummary.area),
        owners:
          extractSummary.nomes_proprietarios && Array.isArray(extractSummary.nomes_proprietarios)
            ? extractSummary.nomes_proprietarios.length
            : 0,
        gravames:
          extractSummary.gravames_ativos && Array.isArray(extractSummary.gravames_ativos)
            ? extractSummary.gravames_ativos.length
            : 0,
      },
      property,
      owners: [
        {
          label: 'Pessoa / Empresa',
          value:  formatStrings(extractSummary?.nomes_proprietarios),
        },
        {
          label: 'CPF/CNPJ',
          value: formatStrings(extractSummary?.documentos_proprietarios),
        },
      ],
      gravames_ativos:
        extractSummary.gravames_ativos && Array.isArray(extractSummary.gravames_ativos)
          ? extractSummary.gravames_ativos.filter(value => typeof value === 'string')
          : undefined,
      gravames_inativos:
        extractSummary.gravames_inativos && Array.isArray(extractSummary.gravames_inativos)
          ? extractSummary.gravames_inativos.filter(value => typeof value === 'string')
          : undefined,
    }
  }, [extractSummary])

  if (!data) return null
  const { summary, property, owners, gravames_inativos, gravames_ativos } = data

  return (
    <div className="px-4 pt-4">
      <section className="border-b-2  border-gray-400 mx-auto">
        <h1 className="text-secondary-500 font-bold text-lg mb-2">Resumo da matrícula</h1>
        <div className="flex items-center justify-between my-4">
          <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
            <Icon name="home" strokeWidth={2} className="text-secondary-300 mb-1" /> Área total{' '}
            <span className="text-secondary-600">{summary.area}</span>
          </div>
          <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
            <Icon name="user" strokeWidth={2} className="text-secondary-300 mb-1" /> Proprietário(os){' '}
            <span className="text-secondary-600">{summary.owners}</span>
          </div>
          <div className="text-secondary-300 text-sm font-semibold flex items-center gap-4">
            <Icon name="alert-circle" strokeWidth={2} className="text-secondary-300 mb-1" /> Ônus ativos{' '}
            <span className="text-secondary-600">{summary.gravames}</span>
          </div>
        </div>
      </section>

      <section className="border-b-2  border-gray-400  mx-auto">
        <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">Dados do imóvel</h2>

        <ul>
          {property.map(detail => (
            <PropertyDetails key={detail.label} {...detail} />
          ))}
        </ul>
      </section>

      <section className="border-b-2  border-gray-400  mx-auto">
        <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">Proprietários</h2>

        <ul>
          {owners.map(detail => (
            <PropertyDetails key={detail.label} {...detail} />
          ))}
        </ul>
      </section>

      <section className="border-b-2  border-gray-400  mx-auto">
        <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">Registros de ônus ativos</h2>

        {gravames_inativos?.length === 0 && <div className="w-full text-center my-4 font-bold">-</div>}

        <ul>
          {gravames_ativos?.map((gravame: any) => (
            <li
              key={gravame}
              className="border-2 border-gray-300 rounded-lg p-6 text-base text-secondary-300 mb-4"
            >
              {gravame}
            </li>
          ))}
        </ul>

        <h2 className="text-secondary-500 font-bold text-lg mb-2 mt-8">Registros de ônus inativos</h2>

        {gravames_inativos?.length === 0 && <div className="w-full text-center my-4 font-bold">-</div>}

        <ul>
          {gravames_inativos?.map((gravame: any) => (
            <li
              key={gravame}
              className="border-2 border-gray-300 rounded-lg p-6 text-base text-secondary-300 mb-4"
            >
              {gravame}
            </li>
          ))}
        </ul>
      </section>
      <Alert
        type="info"
        message="Essas informações foram extraídas do documento por meio de inteligência artificial. Embora tenhamos tomado todas as precauções possíveis, é importante observar que algumas informações podem não ser completamente precisas."
        showIcon
        icon={<Icon name="robot" color="#527edd" />}
        className="mt-8 mx-auto"
      />
    </div>
  )
}

function PropertyDetails({ label, value }: { label: string; value: string | ReactElement }) {
  return (
    <li className="text-secondary-300 text-sm my-4 flex gap-4">
      <p className="basis-1/4">{label}</p> <span className="text-secondary-600">{value}</span>
    </li>
  )
}

function formatStrings(data: string[] | undefined) {
  if (!data || data.length === 0) {
    return ''
  }
  return data.join(',')
}
