import { cnpj, cpf } from 'cpf-cnpj-validator'
import { LabelInValue } from 'domain/app/types'

export function formatCPF(value: string) {
  return cpf.format(value)
}

export function formatCNPJ(value: string) {
  return cnpj.format(value)
}

export function formatCPFCNPJ(value: string) {
  if (!value) return ''
  return value.length === 11 ? cpf.format(value) : cnpj.format(value)
}

export function formatNumber(value: number | string | undefined, options: any = { signDisplay: 'never' }) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  })

  return formatter.format(Number(value ?? 0))
}

export function formatMoney(
  value: number | string | undefined,
  options?: { hideSymbol?: boolean; hideDecimalsZeros?: boolean }
) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  let formattedValue = formatter.format(Number(value ?? 0))

  if (options?.hideSymbol) formattedValue = formattedValue.replace(/R\$\s/g, '')
  if (options?.hideDecimalsZeros) formattedValue = formattedValue.replace(/,00/g, '')

  return formattedValue
}

export function singularOrPlural(quantity: number, singular: string, plural?: string) {
  if (quantity === 1) return `1 ${singular}`

  const textPlural = plural ?? `${singular}s`

  return `${quantity} ${textPlural}`
}

export function normalizeString(string: string) {
  return string
    ? string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
    : ''
}

export function onlyAlphanumeric(string: string) {
  if (!string) return ''
  return String(string).replace(/[^a-z0-9]/gi, '')
}

export function onlyNumbers(string: string) {
  if (!string) return ''
  return String(string).replace(/\D+/g, '')
}

export function createSlug(str: string, separator = '-') {
  str = str.trim()
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaaaeeeeiiiioooouuuunc------'

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  return str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '') // trim - from end of text
    .replace(/-/g, separator)
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getDetailText = (text: string | LabelInValue) => {
  const detail = typeof text === 'string' ? text : text?.value as string ?? ''
  return capitalizeFirstLetter(detail.replaceAll('-', ' '))
}
