import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { SimilarOrdersError } from 'domain/createOrder/shared/errors'
import { SelectedService } from 'domain/orderItem/types'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { AnyDetailedService, ShoppingCartOrder } from 'domain/shoppingCart/types'

import useCheckSimilarOrdersOnCart from './useCheckSimilarOrdersOnCart'
import { SimilarOrder } from 'domain/createOrder/types'

export default function useCheckSimilarOrders() {
  const checkSimilarOrdersOnCart = useCheckSimilarOrdersOnCart()

  return async function checkSimilarOrders(shoppingCartItem: ShoppingCartOrder) {
    const { selectedService } = shoppingCartItem
    const apiData: any = shoppingCartItem.submitData

    const res = await getSimilarOrders(selectedService, apiData)

    if (res.most_recent_similar_order) {
      const orderItem = res.most_recent_similar_order
      
      const similarOrder: SimilarOrder = {
        id: orderItem.id,
        orderName: shoppingCartItem.submitData.name,
        kitItemId: shoppingCartItem.kitItemId!,
      }

      throw new SimilarOrdersError([similarOrder])
    }

    checkSimilarOrdersOnCart(shoppingCartItem)
  }
}

const getSimilarOrders = async (
  service: Pick<SelectedService, 'id' | 'categoryId'>,
  formData: AnyDetailedService
) => {
  const response: AxiosResponse<{ most_recent_similar_order: OrderItemAPI | null }> =
    await AuthorizedAPI.post('/orders/similar', {
      service_id: service.id,
      service_category_id: service.categoryId,
      detailed_service_data: formData,
    })
  return response.data
}
