import React from 'react'
import { Tag } from 'antd'

import { OrderStatuses, StatusCount } from 'domain/orders/types'

function OrderDetailsModalItemsStatus({ totalByStatus }: { totalByStatus: StatusCount[] }) {
  return (
    <>
      <h2 className="text-secondary-500 font-bold ">Status dos itens</h2>

      {totalByStatus.map(({ count, status }) => {
        const itemStatus = OrderStatuses[status]

        return (
          <div key={status} className="flex gap-2 items-center mt-3">
            <Tag title={itemStatus.name} color={itemStatus.colorHexa}>
              {count}
            </Tag>

            <p className="text-sm text-secondary-500">{itemStatus.name}</p>
          </div>
        )
      })}
    </>
  )
}

export default OrderDetailsModalItemsStatus
