import { useMemo } from 'react'
import { Clipboard, ErrorBoundary, Icon } from 'ui'
import { useIsCustomerPostPaid } from 'domain/customers'
import { FreeAnalysisPopup } from 'domain/openAI/enrich'
import { useGetOrderItem } from '../state/viewOrderItem'
import { Collapse } from 'antd'
import { Sidebar } from 'layouts'
import ItemAttachments from './attachments/ItemAttachments'
import ViewOrderGroups from './groups/ViewOrderGroups'

export default function ViewOrderSidebar() {
  const orderItem = useGetOrderItem();
  const isPostPaid = useIsCustomerPostPaid()

  const location = orderItem.locationInfo;

  const fullOrderInfo = useMemo(() => {
    const registry = Array.isArray(location.url_cartorio) 
      ? location.url_cartorio[0]?.label
      : location.url_cartorio?.label
    
    const city = Array.isArray(location.url_cidade)
      ? location.url_cidade[0]?.label
      : location.url_cidade?.label
    
    const state = Array.isArray(location.url_uf)
      ? location.url_uf[0]?.label
      : location.url_uf?.label

    return (
      <div className="order-item-summary p-0">
        <div>
          Cartório: <div>{registry || "Sem informação"}</div>
        </div>
        <div>
          Cidade: <div>{city || "Sem informação"}</div>
        </div>
        <div>
          Estado: <div>{state || "Sem informação"}</div>
        </div>
        <div>
          Matrícula: <div>{orderItem.submitData.matricula || "Sem informação"}</div>
        </div>
      </div>
    )
  }, [orderItem])

  const deliveryFormat: { [key: string]: string } = {
    papel: 'Correios (papel)',
    email: 'Digital (e-mail)',
    combo: 'Digital (e-mail) e Correios (papel)',
  }

  return (
    <Sidebar 
      padding=""
      width="
        bg-opacity-0 border-l-0 md:mr-6 mr-0 md:w-2/3 lg:w-6/12 xl:w-80 
        xl:min-w-80 2xl:w-100 2xl:min-w-100 rounded-lg mask-text"
    >
      <div className="bg-white rounded-lg pb-2">
        <div className="order-item-summary pb-0 p-6">
          <p className="pb-4 text-lg">
            Resumo do item #<Clipboard text={orderItem.orderItemNumber} />
          </p>
          <div className="text-wrap">{orderItem.name}</div>
          <div>
            Tipo: <div>{orderItem.service.name}</div>
          </div>
          {orderItem.submitData.formato && (
            <div>
              Tipo de entrega: <div>{deliveryFormat[orderItem.submitData.formato]}</div>
            </div>
          )}
          {orderItem.cpfOrCnpj && (
            <div>
              CNPJ: <div>{orderItem.cpfOrCnpj}</div>
            </div>
          )}
          <div>
            Solicitante: <div>{orderItem.user.name}</div>
          </div>
          <div>
            Data da solicitação: <div>{orderItem.createdAtDisplay}</div>
          </div>
          <div>
            Data da entrega:
            <div>
              {orderItem.estimatedDeliveryAt
                .toISOString()
                .split('T')[0]
                .split('-')
                .reverse()
                .join('/') ?? '-'}
            </div>
          </div>
          <div className="!pb-0">
            {isPostPaid ? "Custo estimado" : "Créditos utilizados"}:
            <div>{orderItem.order.priceDisplay}</div>
          </div>
        </div>
        {location &&
          <Collapse
            ghost
            className="view-order-collapse"
            expandIconPosition="end"
            expandIcon={({isActive}) => {
              return (
                <Icon 
                  className="transition text-primary"
                  style={{transform: isActive ? "rotate(180deg)" : ""}} 
                  name="chevron-down-alt"
                  size={16}
                />
              )
            }}
            items={[
              {
                key: "resume-data",
                label:
                  <div className="flex leading-[24px] text-primary font-bold text-sm">
                    Pedido completo
                  </div>,
                children: fullOrderInfo
              }
            ]}
          >
          </Collapse>
        }
      </div>

      <ErrorBoundary>
        {(orderItem.submitData.arquivos || orderItem.submitData.arquivo) && 
          <div className="bg-white rounded-lg p-6 pb-4 mt-4 empty:hidden">
            <ItemAttachments />
          </div>
        }
      </ErrorBoundary>

      <div className="bg-white rounded-lg p-6 mt-4">
        <ViewOrderGroups />
      </div>

      {orderItem.hasConsumedAiFreeAnalysis && <FreeAnalysisPopup position="bottom" />}
    </Sidebar>
  )
}
