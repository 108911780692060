import OrdersByPeriod from './OrdersByPeriod'
import OrdersChart from './OrdersChart'
import StatisticCards from './StatisticCards'

export default function OrdersReportTab() {
  return (
    <>
      <StatisticCards />

      <div className="py-7 bg-gray-900">
        <h2 className="uppercase text-white text-base ml-7 pb-5">Meus pedidos</h2>

        <OrdersChart />
      </div>

      <OrdersByPeriod />
    </>
  )
}
