import { useDispatch } from 'react-redux'
import { Form } from 'antd'

import { LabelInValue } from 'domain/app/types'
import { CertificateFlow } from 'domain/createOrder/certificates'
import { createOrderActions, useCreateOrderState, useLoadExtraInfos } from 'domain/createOrder/document'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'

import ModelsAndSpecificFields from './ModelsAndSpecificFields'
import CertidaoJusticaEstadualForm from './CertidaoJusticaEstadualForm'
import SelectFederativeUnit from './CreateOrderCertidaoJusticaEstadualSelectFederativeUnit'
import { AllExtraInfos } from 'domain/orderItem/types'

export default function CreateOrderCertidaoJusticaEstadual() {
  const dispatch = useDispatch()

  const {
    formItems,
    selectedLocation,
    selectedService,
    selectedType,
    selectedInstance,
    selectedComarca,
    selectedModel,
  } = useCreateOrderState()

  const { url_uf } = getLocationUrl(selectedLocation)
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <CertificateFlow
      header={<CertidaoTJHeader loading={isFetching} dynamicFields={data!} />}
      renderFormItem={(itemId: number) => (
        <CertidaoJusticaEstadualForm
          loading={isFetching}
          dynamicFields={data}
          itemId={itemId}
          service={selectedService}
          federativeUnit={url_uf as string}
          entityType={formItems[itemId]?.selectedEntityType}
          instance={selectedInstance}
          comarca={selectedComarca}
          type={selectedType as string}
          model={selectedModel}
          onChangeResearchTime={value => {
            dispatch(createOrderActions.setSelectedResearchTime(value))
          }}
        />
      )}
      noRenderOrderName
    />
  )
}

interface CertidaoTJHeaderProps {
  loading: boolean
  dynamicFields: AllExtraInfos | undefined
}

function CertidaoTJHeader({ dynamicFields, loading }: CertidaoTJHeaderProps) {
  const dispatch = useDispatch()
  const form = Form.useFormInstance()

  const {
    selectedEntityType,
    selectedLocation,
    selectedService,
    selectedType,
    selectedInstance,
    selectedComarca,
    selectedModel,
  } = useCreateOrderState()
  const { url_uf } = getLocationUrl(selectedLocation)

  return (
    <>
      <p className="mb-5 text-gray-600">
        Selecione Pessoa ou Empresa e digite o número do CPF ou CNPJ que deseja a Certidão de Justiça
        Estadual.
      </p>

      <SelectFederativeUnit form={form} />

      <ModelsAndSpecificFields
        dynamicFields={dynamicFields}
        loading={loading}
        service={selectedService}
        federativeUnit={url_uf as string}
        entityType={selectedEntityType}
        instance={selectedInstance}
        comarca={selectedComarca}
        type={selectedType as string}
        model={selectedModel}
        onChangeComarca={(value: string) => {
          dispatch(createOrderActions.setSelectedComarca(value))
        }}
        onChangeModel={(model: any) => {
          dispatch(createOrderActions.setSelectedModel(model))
          dispatch(createOrderActions.setSelectedNotaries(undefined))
        }}
        onChangeInstance={(value: string) => {
          dispatch(createOrderActions.setSelectedInstance(value))
        }}
        onChangeNotaries={(selected: LabelInValue[]) => {
          dispatch(createOrderActions.setSelectedNotaries(selected))
        }}
        onChangeResearchTime={(selected: number) => {
          dispatch(createOrderActions.setSelectedResearchTime(selected))
        }}
      />
    </>
  )
}
