import { useState } from 'react'
import { Table, Popover, Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { AssociatedGroupsTags } from 'domain/groups'
import { Group } from 'domain/groups/types'
import { useSetRecurrenceFilter } from 'domain/orders'
import { Icon, Tag } from 'ui'

import { Frequency, Recurrence, RecurrenceItem } from '../types'
import { useSetSortDirection, useSetSortField } from '../state/pagination'
import InactiveRecurrence from './InactiveRecurrence'
import RecurrencesTablePagination from './RecurrencesTablePagination'
import RecurrenceActions from './actions/RecurrenceActions'
import RecurrenceSearch from './filters/RecurrenceSearch'
import RecurrencesFilter from './filters/RecurrencesFilter'

interface Props {
  recurrences: Recurrence[]
  loading: boolean
  queryKey: any[]
}

export default function RecurrencesTable({ recurrences, loading, queryKey }: Props) {
  const history = useHistory()
  const setRecurrenceFilter = useSetRecurrenceFilter()
  const [recurrenceToInactivate, setRecurrenceToInactivate] = useState<Recurrence | undefined>(undefined)
  const setSortDirection = useSetSortDirection()
  const setSortField = useSetSortField()

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.columnKey ?? sorter.field)
    setSortDirection(sorter.order)
  }

  const getRecurrenceFrequency = (frequency: Frequency, recurrence: Recurrence) => {
    const frequencies = {
      monthly: 'Mensal',
      yearly: 'Anual',
      weekly: 'Semanal',
      every_x_days: `A cada ${recurrence.everyXDays} dias`,
    }
    return frequencies[frequency as keyof typeof frequencies]
  }

  const onRow = (recurrence: Recurrence) => {
    return {
      onClick: () => {
        setRecurrenceFilter(recurrence.id)
        history.push(RoutePaths.ORDERS)
      },
    }
  }

  const columns: any = [
    {
      title: 'Nome da recorrência',
      dataIndex: 'name',
      render: (name: string) => (
        <div className="flex items-center gap-2">
          <Icon name="document" size={22} /> <span className="font-bold">{name}</span>
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Documentos',
      dataIndex: 'items',
      render: (items: RecurrenceItem[]) => (
        <Popover
          content={
            <div className="max-w-xs">
              {items.map(({ orderItem }, index) => (
                <p key={index}>{orderItem?.name ?? ''}</p>
              ))}
            </div>
          }
          trigger="hover"
        >
          <Tag>{items.length > 1 ? `${items.length} itens` : `${items.length} item`}</Tag>
        </Popover>
      ),
    },
    {
      title: 'Grupos',
      dataIndex: 'groups',
      render: (groups: Group[]) => <AssociatedGroupsTags groups={groups} />,
    },
    {
      title: 'Responsável',
      dataIndex: ['owner', 'name'],
    },
    {
      title: 'Periodicidade',
      dataIndex: 'frequency',
      render: (frequency: Frequency, recurrence: Recurrence) =>
        recurrence.active ? (
          getRecurrenceFrequency(frequency, recurrence)
        ) : (
          <span className="font-bold text-error">Inativa</span>
        ),
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAtDisplay',
      key: 'created_at',
      sorter: true,
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      render: (id: number, recurrence: Recurrence) => {
        return (
          <RecurrenceActions
            recurrence={recurrence}
            setRecurrenceToInactivate={setRecurrenceToInactivate}
            queryKey={queryKey}
          />
        )
      },
    },
  ]

  return (
    <div className="mx-7 w-full h-full bg-gray-100">
      <div className="flex flex-row gap-8 items-start pb-4 mt-4 rounded-t bg-white pt-4 px-4">
        <RecurrenceSearch />
        <RecurrencesFilter />
      </div>
      <Spin spinning={loading}>
        <Table
          dataSource={recurrences}
          rowKey={record => record.id}
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          onRow={onRow}
          loading={loading}
          rowClassName={recurrence => (recurrence.active ? 'cursor-pointer' : '')}
        />

        <RecurrencesTablePagination />

        <InactiveRecurrence
          queryKey={queryKey}
          recurrence={recurrenceToInactivate}
          onClose={() => setRecurrenceToInactivate(undefined)}
        />
      </Spin>
    </div>
  )
}
