import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Spin } from 'antd'

import {
  AddKitItem,
  DuplicateKitItem,
  EditKitItem,
  EditKitSidebar,
  KitItemPanelHeader,
  KitRemoveItem,
  useKitQuery,
} from 'domain/kits'
import { CategoriesProvider } from 'domain/servicesCBRdoc'
import { SERVICES_WITH_FIXABLE_DATA } from 'domain/servicesCBRdoc/products'
import { PageLayout, Section } from 'layouts'

function EditKitPage() {
  const params = useParams<{ id: string }>()

  const { data: kit, isLoading, isError, queryKey } = useKitQuery(params?.id)

  const fixableItems = useMemo(() => {
    if (!kit || !kit.items) return []

    return kit.items
      .filter(item => SERVICES_WITH_FIXABLE_DATA.includes(item.service.code!))
      .sort((a, b) => (a.service.name > b.service.name ? 1 : -1))
  }, [kit?.items])

  if (isLoading) return <Spin />
  if (isError || !kit) return <p>Kit não encontrado.</p>

  return (
    <CategoriesProvider>
      <PageLayout pageTitle="Editar Kit" sidebar={<EditKitSidebar kit={kit} queryKey={queryKey} />}>
        <Section heading="Adicionar novo Documento" fullWidth>
          <AddKitItem kit={kit} queryKey={queryKey} />
        </Section>

        <Section heading="Fixar dados do pedido" fullWidth>
          {fixableItems.map((item, index) => (
            <div key={item.id} className="w-full max-w-screen-md">
              <Card
                title={
                  <KitItemPanelHeader categoryId={item.service.categoryId} serviceId={item.service.id} />
                }
                extra={<KitRemoveItem queryKey={queryKey} kit={kit} item={item} />}
                className="mb-3 w-full"
              >
                <EditKitItem kit={kit} kitItem={item} queryKey={queryKey} />
              </Card>

              {(fixableItems[index + 1] === undefined ||
                fixableItems[index + 1].service.code !== item.service.code) && (
                <DuplicateKitItem kit={kit} kitItem={item} queryKey={queryKey} />
              )}
            </div>
          ))}

          {fixableItems.length === 0 && (
            <p className="text-secondary-500 font-bold">
              Não existem dados a serem fixos para estes tipos de documentos.
            </p>
          )}
        </Section>
      </PageLayout>
    </CategoriesProvider>
  )
}

export default EditKitPage
