import React from 'react'

import { useExplorerItem } from 'domain/myFiles/state'
import OCRComparison from './OCRComparison'

export default function ViewFileOCRContent() {
  const [explorerItem] = useExplorerItem()

  return (
    <OCRComparison pdfUrl={explorerItem.file?.signedUrl} ocr={explorerItem.ocr}/>
  )
}
