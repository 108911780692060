import { Spin } from 'antd'

import useOrdersByPeriodQuery from '../services/useOrdersByPeriodQuery'
import ExportOrdersToExcelButton from './ExportOrdersToExcelButton'
import OrdersByPeriodList from './OrdersByPeriodList'
import OrdersFilters from './filters/OrdersFilters'

export default function OrdersByPeriod() {  
  const { data: orderItems, isLoading, pagination } = useOrdersByPeriodQuery()
  const hasOrders = orderItems && orderItems.length > 0

  return (
    <div className="my-7 bg-gray-100">
      <div className="flex items-center justify-between mb-3 pb-3">
        <h1 className="text-lg uppercase">Pedidos no período</h1>

        <ExportOrdersToExcelButton disabled={!hasOrders} />
      </div>

      <div className="mt-4 bg-white rounded-t py-5 px-4">
        <h2 className="text-sm text-secondary-300 mb-1 uppercase">Filtrar por:</h2>

        <OrdersFilters />
      </div>

      <Spin spinning={isLoading}>
        {orderItems && <OrdersByPeriodList orderItems={orderItems} pagination={pagination} />}
      </Spin>
    </div>
  )
}
