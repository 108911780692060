import React from 'react'
import { Button as AntdButton, ButtonProps as AntButtonProps } from 'antd'
import { ButtonType as AntButtonType } from 'antd/es/button'

import Icon, { IconName } from 'ui/Icon/Icon'

export type ButtonType = AntButtonType | 'danger' | 'success'

export interface ButtonProps extends AntButtonProps {
  icon?: IconName
  iconSize?: number
  iconColor?: string
  iconStroke?: number
  iconClassName?: string
}

function Button(
  {
    type = 'primary',
    icon,
    children,
    iconSize = 20,
    iconStroke = 1,
    iconColor,
    iconClassName,
    className,
    ...props
  }: ButtonProps,
  ref: any
) {
  return (
    <AntdButton
      type={type}
      ref={ref}
      className={icon ? `flex flex-row items-center justify-center gap-2 ${className}` : className}
      {...props}
    >
      {icon && (
        <Icon
          name={icon}
          className={iconClassName}
          strokeWidth={iconStroke}
          size={iconSize}
          color={iconColor}
        />
      )}
      <div className={icon ? 'mt-1' : ''}>{children}</div>
    </AntdButton>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button)
