import React from 'react'

import { OrderItemListing } from 'domain/orderItem/types'
import { CertificateCode } from 'domain/servicesCBRdoc/types'
import { getDetailText } from 'utils/formatters'

interface Props {
  item: OrderItemListing
}

export default function ServiceName({ item }: Props) {
  let description = ''

  if (item.service.code === CertificateCode.CERTIDAO_JUSTICA_ESTADUAL) {
    description = ' - ' + getDetailText(item.submitData.instancia)
  }

  return (
    <>{item.service.name} {description}</>
  )
}
