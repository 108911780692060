import { ThemeConfig } from 'antd'

const colorPrimary = '#527edd'
const colorPrimaryHover = '#437fff'
const colorSecondary = '#4C566F'
// const colorSecondary300 = '#8394BC'
const gray200 = '#e8ebef'
const gray600 = '#818aa5'
const outlineColor = `${colorPrimaryHover}33`
const activeShadow = `0 0 0 2px ${outlineColor}`
const controlHeight = 44
const controlHeightLG = 56
const controlHeightSM = 32

export default {
  cssVar: true,
  token: {
    colorHighlight: colorPrimaryHover,
    colorPrimary,
    colorPrimaryActive: colorPrimaryHover,
    colorPrimaryBg: colorPrimary,
    colorPrimaryHover,
    colorInfo: '#527edd',
    colorInfoBg: '#dff0f5',
    colorSuccess: '#40ca8f',
    colorSuccessBg: '#deeceb',
    colorError: '#ff7d5f',
    colorErrorBg: '#faecea',
    colorLink: colorPrimary,
    colorTextBase: '#5e6069',
    colorTextLabel: '#4C566F',
    colorWarning: '#faad14',
    colorWarningBg: '#fffaf1',
    fontFamily: 'Martel Sans',
  },
  components: {
    Alert: {
      colorInfoBg: '#527EDD29',
      colorInfoBorder: '#527edd',
    },
    Badge: {
      dotSize: 12,
      statusSize: 12,
    },
    Button: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
      colorPrimaryBorder: `${colorPrimaryHover}33`,
      colorPrimaryHover: colorPrimaryHover,
      contentFontSize: 14,
      contentFontSizeSM: 14,
      contentFontSizeLG: 16,
      controlHeight: 44,
      controlHeightLG: 54,
      controlHeightSM: 32,
      dangerShadow: '0',
      defaultShadow: '0',
      fontSize: 14,
      fontSizeLG: 16,
      fontSizeSM: 12,
      groupBorderColor: colorPrimaryHover,
      paddingInline: 17,
      paddingInlineLG: 17,
      paddingInlineSM: 9,
      primaryShadow: '0',

      // type default
      defaultBg: gray600,
      defaultColor: '#FFF',
      defaultBorderColor: gray600,
      defaultHoverBorderColor: colorSecondary,
      defaultHoverBg: colorSecondary,
      defaultHoverColor: '#FFF',
      // defaultGhostBorderColor: gray600,
      // defaultGhostColor: gray600,

      // type link
      colorLink: colorPrimary,
      colorLinkHover: colorPrimaryHover,
      colorLinkActive: colorPrimaryHover,
      linkFocusDecoration: 'none',

      // type text
      colorText: colorPrimary,
      //textHoverBg: 'transparent',
    },
    Breadcrumb: {
      linkColor: colorSecondary,
      lastItemColor: colorSecondary,
      separatorColor: colorSecondary,
      colorBgTextHover: 'transparent',
    },
    Checkbox: {
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
      colorTextLabel: colorSecondary,
    },
    Collapse: {
      headerBg: '#FFF',
      colorBorder: gray200,
      colorTextHeading: colorSecondary,
      borderRadiusLG: 2,
    },
    DatePicker: {
      activeShadow,
      borderRadius: 0,
      borderRadiusLG: 2,
      controlHeight,
      controlHeightLG,
      controlHeightSM,
      fontSize: 16,
      paddingBlock: 10,
      paddingBlockLG: 18,
      paddingBlockSM: 4,
    },
    Drawer: {
      controlHeight: 60,
      controlHeightLG: 60,
      controlHeightSM: 46,
      controlHeightXS: 32,
    },
    Dropdown: {
      paddingBlock: 10,
      borderRadius: 2,
      borderRadiusLG: 2,
    },
    Form: {
      controlHeight: 44,
      labelColor: colorSecondary,
      labelRequiredMarkColor: colorSecondary,
      verticalLabelMargin: 1,
    },
    Input: {
      activeShadow,
      activeBorderColor: colorPrimaryHover,
      addonBg: '#fff',
      borderRadius: 0,
      borderRadiusLG: 2,
      colorText: colorSecondary,
      controlHeight,
      controlHeightLG,
      controlHeightSM,
      inputFontSize: 16,
    },
    InputNumber: {
      activeShadow,
      activeBorderColor: colorPrimary,
      borderRadius: 0,
      borderRadiusLG: 2,
      colorTextLabel: colorSecondary,
      controlHeight,
      controlHeightLG,
      controlHeightSM,
    },
    Modal: {
      colorIconHover: '#000',
      colorBgTextHover: 'transparent',
      titleColor: colorSecondary,
      titleFontSize: 20,
      borderRadiusLG: 0,
      borderRadiusSM: 0,
      fontFamily:
        // eslint-disable-next-line quotes
        "MartelSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif",
    },
    Pagination: {
      itemSize: 36,
      itemSizeSM: 36,
      borderRadius: 2,
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
    },
    Radio: {
      borderRadius: 0,
      controlHeight,
      controlHeightLG,
      controlHeightSM,
    },
    Select: {
      borderRadius: 0,
      borderRadiusLG: 2,
      colorText: colorSecondary,
      controlHeight: controlHeight + 4,
      controlHeightLG,
      controlHeightSM,
      controlOutline: outlineColor,
      fontSize: 16,
      optionHeight: controlHeight - 4,
      optionFontSize: 14,
      optionSelectedColor: '#FFF',
    },
    Table: {
      cellPaddingBlock: 12,
      cellPaddingInline: 12,
      colorLink: colorSecondary,
      headerBg: gray200,
      headerColor: colorSecondary,
      headerBorderRadius: 2,
      // lineHeight: 1.8,
      rowHoverBg: '#fafafa',
      rowSelectedBg: 'transparent',
    },
    Tabs: {
      borderRadiusLG: 2,
      borderRadius: 2,
      cardBg: '#FFF',
      colorBgContainer: colorPrimary,
      cardGutter: 0,
      colorBorderSecondary: 'transparent',
      itemActiveColor: colorSecondary,
      itemHoverColor: colorPrimary,
      itemSelectedColor: colorSecondary,
      inkBarColor: colorPrimary,
      lineHeight: 0.5,
      horizontalMargin: '0 0 8px 0',
      //lineWidthBold: 3,
      padding: 10,
      paddingLG: 26,
      paddingSM: 14,
      paddingXS: 10,
      paddingXXS: 6,
    },
    Tag: {
      borderRadiusSM: 4,
    },
    Tooltip: {
      colorBgSpotlight: '#fff',
      colorTextLightSolid: colorSecondary,
      paddingSM: 24,
      paddingXS: 16,
    },
  },
} as ThemeConfig
