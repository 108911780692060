import React from 'react'
import { Dayjs } from 'dayjs'

import { daysOfWeek } from 'domain/app/data'
import { Icon } from 'ui'
import { displayDate, displayFulMonthDayYearString, displayMonthString } from 'utils/dateTime'

type Props = {
  daysOfWeek: number[]
  everyXDays: number | undefined
  frequency: string
  startsAt: Dayjs
}

function FrequencyMessage(props: Props) {
  return (
    <div className="px-3 py-3 border border-success-500 bg-success-100 flex items-center gap-2">
      <div className="grow shrink-0">
        <Icon name="calendar" className="text-green-600" size={25} />
      </div>
      <div className="shrink text-gray-700">
        <FrequencyText {...props} />
      </div>
    </div>
  )
}

function FrequencyText({ daysOfWeek, everyXDays, frequency, startsAt }: Props) {
  const initialDate = displayFulMonthDayYearString(startsAt)

  if (frequency === 'weekly') {
    return daysOfWeek.length > 0 ? (
      <WeeklyMessage initialDate={initialDate} selected={daysOfWeek} />
    ) : (
      <span>Selecione o dia da semana</span>
    )
  }

  if (frequency === 'monthly') {
    return <MonthlyMessage initialDate={initialDate} startsAt={startsAt} />
  }

  if (frequency === 'yearly') {
    return <YearlyMessage initialDate={initialDate} startsAt={startsAt} />
  }

  return <EveryXDaysMessage initialDate={initialDate} everyXDays={everyXDays} />
}

function WeeklyMessage({ selected, initialDate }: { selected: number[]; initialDate: string }) {
  const isoDaysOfWeek = selected.map(day => daysOfWeek[day].dayOfWeek)

  return (
    <span>
      A solicitação ocorrerá a cada <b>{isoDaysOfWeek?.join(', ')} </b>
      iniciando na primeira <b>{daysOfWeek[selected[0]].dayOfWeek} </b> a partir de <b>{initialDate}</b>
    </span>
  )
}

function MonthlyMessage({ initialDate, startsAt }: Pick<Props, 'startsAt'> & { initialDate: string }) {
  const splittedDate = displayDate(startsAt).split('/')

  return (
    <span>
      A solicitação ocorrerá <b>a cada dia {splittedDate[0]}</b> de cada mês, iniciando em{' '}
      <b>{initialDate}</b>
    </span>
  )
}

function YearlyMessage({ initialDate, startsAt }: Pick<Props, 'startsAt'> & { initialDate: string }) {
  const splittedDate = displayDate(startsAt).split('/')
  const month = displayMonthString(startsAt)

  return (
    <span>
      A solicitação ocorrerá
      <b>
        {' '}
        a cada dia {splittedDate[0]} do mês de {month}
      </b>
      , iniciando em <b>{initialDate}</b>
    </span>
  )
}

function EveryXDaysMessage({ initialDate, everyXDays }: Pick<Props, 'everyXDays'> & { initialDate: string }) {
  return (
    <span>
      A solicitação ocorrerá
      <b> a cada {everyXDays} dias</b>, iniciando em <b>{initialDate}</b>
    </span>
  )
}

export default FrequencyMessage
