import { CreateOrderFormData } from 'domain/createOrder/types'
import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { getValue } from 'utils/transformData'

type CertidaoDebitosMunicipaisFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.DATA_NASCIMENTO
  | FieldName.INSCRICAO_MERCANTIL
  | FieldName.INSCRICAO_MUNICIPAL
  | FieldName.NOME_MAE
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.URL_CIDADE
  | FieldName.URL_UF
  | FieldName.OBSERVACAO,
  FieldConfig
>

const { FORMATO, TIPO_PESSOA, URL_CIDADE, URL_UF } = FieldName

const showIfRJCapital = (data: CreateOrderFormData) => {
  return getValue(data.url_uf) === 'RJ' && getValue(data.url_cidade) === 'rio-de-janeiro'
}

class CertidaoDebitosMunicipais extends Certificate {
  formFields: CertidaoDebitosMunicipaisFields = {
    url_cidade: Fields.url_cidade,
    url_uf: Fields.url_uf,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    nascimento: {
      ...Fields.nascimento,
      showIf: showIfRJCapital
    },
    mae: {
      ...Fields.mae,
      showIf: showIfRJCapital
    },
    inscricao_mercantil: {
      ...Fields.inscricao_mercantil,
      showIf: (data: CreateOrderFormData) => getValue(data.url_uf) === 'PE',
      legalEntity: undefined,
    },
    inscricao_municipal: {
      ...Fields.inscricao_municipal,
      showIf: showIfRJCapital,
      legalEntity: undefined,
    },
    observacao: {
      ...Fields.observacao,
      maxLength: 2500,
    },
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CIDADE, URL_UF],
      extraInfoFields: [URL_CIDADE, URL_UF],
      id: 23,
      priceByFields: [URL_CIDADE, URL_UF, TIPO_PESSOA, FORMATO],
      shortName: 'Certidão Débitos Municipais',
      shoudAutoLoadDynamicFields: false,
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }
}

const service = new CertidaoDebitosMunicipais().initialize()

export default service
