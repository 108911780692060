import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { FORMATO, TIPO_PESSOA } = FieldName

type CertidaoNegativaSTJFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.DATA_NASCIMENTO
  | FieldName.NOME_MAE
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.RG
  | FieldName.RG_EXPEDIDOR,
  FieldConfig
>

type CertidaoNegativaSTJDynamicFields = Record<
  | FieldName.ESTADO_CIVIL
  | FieldName.NACIONALIDADE,
  FieldConfig
>

class CertidaoNegativaSTJ extends Certificate {
  formFields: CertidaoNegativaSTJFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    nascimento: Fields.nascimento,
    rg: Fields.rg,
    rg_expedidor: {
      ...Fields.rg_expedidor,
      apiName: 'orgao_emissor',
      optional: false
    },
    mae: Fields.mae,
  }

  dynamicFields: CertidaoNegativaSTJDynamicFields = {
    nacionalidade: Fields.nacionalidade,
    estado_civil: Fields.estado_civil,
  }

  constructor() {
    super({
      id: 50,
      priceByFields: [FORMATO, TIPO_PESSOA],
      shortName: 'Certidão Negativa STJ',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoNegativaSTJ().initialize()

export default service
