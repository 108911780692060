import { IconName } from 'ui'
import { ServiceType } from '../types'

export const ServiceIconName: Record<ServiceType, IconName> = {
  [ServiceType.AI]: 'robot',
  [ServiceType.CERTIFICATE]: 'registro-civil',
  [ServiceType.DOCUMENT_REGISTRATION]: 'stamp',
  [ServiceType.DILIGENCE]: 'briefcase',
  [ServiceType.RESEARCH]: 'search',
}

export const getLocationOf = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.CERTIFICATE:
      return 'do Documento'
    case ServiceType.RESEARCH:
      return 'da Pesquisa'
    case ServiceType.AI:
      return 'da Extração de Dados'
    default: return ''
  }
}
