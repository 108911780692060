import React from 'react'
import { Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderItemSummary } from 'domain/orderItem/types'
import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { Pagination } from 'ui'
import { formatMoney } from 'utils/formatters'

interface Props {
  orderItems: OrderItemSummary[]
  pagination: any
}

export default function OrdersByPeriodList({ orderItems, pagination }: Props) {
  const hasOrders = orderItems && orderItems.length > 0

  if (!hasOrders) {
    return <div className="py-3">Não foi encontrado nenhum pedido para o período selecionado.</div>
  }

  return <OrdersByPeriodTable orderItems={orderItems} pagination={pagination} />
}

function OrdersByPeriodTable({ orderItems, pagination }: Props) {
  const isPostPaid = useIsCustomerPostPaid()

  return (
    <>
      <Table
        columns={isPostPaid ? postPaidColumns : prePaidColumns}
        dataSource={orderItems}
        pagination={false}
        rowKey={getRowKey}
        onRow={record => ({
          onClick: () => window.open(Routes.ViewOrder(record.id), '_blank'),
        })}
      />

      <Pagination
        totalCount={pagination.totalCount}
        currentPage={pagination.currentPage}
        onChangePage={pageNumber => pagination.setCurrentPage(pageNumber)}
        pageSize={pagination.pageSize}
        onPageSizeChange={pageSize => pagination.setPageSize(pageSize)}
      />
    </>
  )
}

const getRowKey = (record: any) => record.orderItemNumber

const columns: ColumnType<OrderItemSummary>[] = [
  {
    title: 'Nome do pedido',
    dataIndex: 'name',
  },
  {
    title: 'Nº do pedido',
    dataIndex: 'orderItemNumber',
    render: (value, record) => {
      return (
        <Link
          to={Routes.ViewOrder(record.id)}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >
          {value}
        </Link>
      )
    },
  },
  {
    title: 'Data do pedido',
    dataIndex: 'createdAtDisplay',
  },
  {
    title: 'Documento',
    dataIndex: ['service', 'name'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: StatusCode) => <span>{OrderStatuses[status].name}</span>,
  },
  {
    title: 'Solicitante',
    dataIndex: ['user', 'name'],
  },
]

const prePaidColumns: any = [
  ...columns,
  {
    title: 'Créditos utilizados',
    dataIndex: 'price',
    render: formatMoney,
  },
]

const postPaidColumns: any = [
  ...columns,
  {
    title: 'Custo estimado',
    dataIndex: 'price',
    render: formatMoney,
  },
]
