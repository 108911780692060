import { Form } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import {
  useGetLegalEntity,
  useLoadKitDynamicFields,
  useServiceForm,
} from 'domain/createOrder/kit'
import { AllExtraInfos } from 'domain/orderItem/types'
import { KitItem } from 'domain/kits/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'

import AlertDocumentsRequired from './AlertDocumentsRequired'
import ModelsAndSpecificFields from './ModelsAndSpecificFields'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoJusticaEstadual({ kitItem }: Props) {
  const { id, service } = kitItem
  const legalEntity = useGetLegalEntity()
  const [{ comarca, modelo, tipo, url_uf }, updateForm] = useServiceForm(id)
  const selectedInstance = Form.useWatch(getFieldName(FieldName.INSTANCIA, kitItem.id))
  const federativeUnit = url_uf?.value as string
  const { data, isFetching } = useLoadKitDynamicFields(kitItem)

  return (
    <>
      <ModelsAndSpecificFields
        itemId={id}
        dynamicFields={data as AllExtraInfos}
        loading={isFetching}
        service={service}
        federativeUnit={federativeUnit}
        entityType={legalEntity}
        instance={selectedInstance}
        comarca={comarca}
        type={tipo}
        model={modelo}
        onChangeModel={modelo => updateForm({ modelo })}
        onChangeComarca={comarca => updateForm({ comarca })}
        onChangeInstance={instancia => updateForm({ instancia })}
        onChangeNotaries={url_cartorio => updateForm({ url_cartorio })}
        onChangeResearchTime={tempo_pesquisa => updateForm({ tempo_pesquisa })}
      />

      <AlertDocumentsRequired
        entityType={legalEntity}
        federativeUnit={federativeUnit}
        instance={selectedInstance}
      />
    </>
  )
}
