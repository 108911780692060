import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useAddToCart } from 'domain/createOrder/shared/services'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { CreateOrderFormData } from 'domain/createOrder/types'

export default function useAddCreateOrderStateToCart() {
  const history = useHistory()
  const createOrderState = useCreateOrderState()
  const addToCart = useAddToCart()

  return function addOrder(formData?: CreateOrderFormData) {
    addToCart(formData ? { ...createOrderState, formData } : createOrderState)
    history.push(RoutePaths.SHOPPING_CART)
  }
}
