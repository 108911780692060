import { Rule } from 'antd/lib/form'

import TextInput, { TextInputProps } from './TextInput'

export interface EmailInputProps extends Omit<TextInputProps, '' | 'label' | 'minLength'> {
  label?: string
  aditionalRules?: Rule[] | null
  validationTrigger?: string | string[] | undefined
}

export default function EmailInput({
  label = 'E-mail',
  name = 'email',
  required = true,
  aditionalRules = null,
  placeholder = 'E-mail',
  disabled,
  ...props
}: EmailInputProps) {
  const validationRules: Rule[] = [
    {
      validator: async (_: object, value: string) => {
        if (required && !value && !disabled) {
          return Promise.reject('Informe um e-mail válido.')
        }

        const email = value?.trim() ?? ''

        if (email && !regex.test(email)) {
          return Promise.reject('Informe um e-mail válido.')
        }

        return Promise.resolve()
      },
    }, 
  ]

  return (
    <TextInput
      label={label}
      name={name}
      required={required}
      rules={aditionalRules ? [...validationRules, ...aditionalRules] : validationRules}
      placeholder={placeholder}
      maxLength={200}
      disabled={disabled}
      {...props}
    />
  )
}

const regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
