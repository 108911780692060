import { useMemo } from 'react'

import { OCRDocument } from 'domain/myFiles/types'
import { useServiceAIDefaultModelAndQuestionsQuery } from 'domain/openAI/models'
import { AIModel } from 'domain/openAI/types'

import useAIEnrichQuery from '../services/useAIEnrichQuery'
import OpenAIEnrichmentButton from './OpenAIEnrichmentButton'
import OpenAILayout from './OpenAILayout'
import OpenAIQuestions from './OpenAIQuestions'
import { useCheckPermission } from 'domain/auth'
import { Icon } from 'ui'

type Props = {
  aiModel?: AIModel
  ocr?: OCRDocument
  fileUrl: string
  serviceId: number
}

export default function OpenAIEnrichment({ aiModel, ocr, serviceId, fileUrl }: Props) {
  const checkPermission = useCheckPermission()
  const canExtractDataUsingAi = checkPermission('canExtractDataUsingAi') 
  const { data: defaultModel, isFetching: isLoadingDefaultData } = useServiceAIDefaultModelAndQuestionsQuery(
    serviceId,
    {
      enabled: !aiModel?.hasAnswers,
    }
  )

  const {
    data: answers,
    isFetching,
    refetch: enrich,
  } = useAIEnrichQuery(ocr?.id, defaultModel?.id, { enabled: false })

  const alreadyHasAnswers = answers !== undefined && answers.length > 0

  const questions = useMemo(() => {
    if (answers && answers.length > 0) {
      return answers
    }

    if (aiModel?.hasAnswers) {
      return aiModel.questions
    }

    return defaultModel?.questions
  }, [defaultModel?.questions, answers, aiModel])

  return (
    <OpenAILayout
      ocr={ocr}
      sidebar={
        <div className={!alreadyHasAnswers ? "py-20" : "pt-20" }>
          <div className="md:w-2/3 lg:w-7/12 mx-auto flex flex-col items-center justify-center">
            <Icon name="robot" className="size-16" />

            <p className="text-secondary-500 my-4 text-base">
              {!alreadyHasAnswers && "Faça um teste gratuito clicando no botão abaixo. "}
              Para habilitar o módulo de inteligência
              artificial e receber planilhas e relatórios de todos os seus documentos, entre em contato com
              nosso time pelo telefone: 4020-1716
            </p>

            <div className="px-3 py-2 rounded w-full justify-between flex flex-col">
              <div className={`flex justify-center mb-2 ${alreadyHasAnswers && "h-0 invisible"}`}>
                <OpenAIEnrichmentButton
                  handleEnrichment={enrich}
                  isFetching={isFetching || isLoadingDefaultData}
                  disabled={!canExtractDataUsingAi || alreadyHasAnswers || !defaultModel?.id}
                  availability={ocr?.aiEnrichmentAvailability}
                />
              </div>

            </div>
          </div>
          {questions && <OpenAIQuestions questions={questions} />}
        </div>
      }
    />
  )
}
