import { ReactNode } from 'react'
import { Form, Input, InputProps } from 'antd'
import { NamePath } from 'antd/lib/form/interface'

import { LoadingIcon } from 'ui'

export interface TextInputProps extends Omit<InputProps, 'name'> {
  itemId?: number | string
  label: string | ReactNode
  rules?: Record<string, any>[]
  name?: NamePath
  hideOptional?: boolean
  validationTrigger?: string | string[] | undefined
  loading?: boolean
}

export default function TextInput({
  label,
  name,
  required,
  type = 'text',
  maxLength = 255,
  style,
  rules = [],
  hideOptional,
  validationTrigger,
  loading = false,
  ...props
}: TextInputProps) {
  const validationRules = [
    { transform: (value: string) => value.trim() },
    { whitespace: true, message: 'O campo não pode conter apenas espaços.' },
    { required, message: 'Campo obrigatório.' },
    { maxLength, message: `O campo não pode ter mais que ${maxLength} caracteres.` },
    ...rules,
  ]

  return (
    <Form.Item
      name={name}
      label={getLabel(label, required, hideOptional)}
      rules={validationRules}
      style={style}
      validateFirst
      validateTrigger={validationTrigger}
    >
      <Input
        type={type}
        maxLength={maxLength}
        data-hj-allow
        disabled={loading}
        suffix={<div>{loading && <LoadingIcon />}</div>}
        {...props}
      />
    </Form.Item>
  )
}

function getLabel(label: string | ReactNode, required?: boolean, hideOptional?: boolean) {
  if (typeof label !== 'string') {
    return label
  }

  return `${label} ${!required && !hideOptional ? '(opcional)' : ''}`
}
