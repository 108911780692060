import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { MATRICULA, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type CertidaoPreviaMatriculaFields = Record<
  FieldName.URL_CARTORIO | FieldName.URL_CIDADE | FieldName.URL_UF | FieldName.MATRICULA,
  FieldConfig
>

class CertidaoPreviaMatricula extends Certificate {
  formFields: CertidaoPreviaMatriculaFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    matricula: {
      ...Fields.matricula,
      label: 'Matrícula(s)',
      orderNameText: 'Matrícula',
      placeholder: 'Informe uma matrícula por linha',
    },
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 55,
      orderNameField: MATRICULA,
      priceByFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Prévia de Matrícula',
      steps: ['localizacao', 'dados', 'entrega'],
      splittableField: MATRICULA,
      splittableFieldInOrderName: true
    })
  }
}

const service = new CertidaoPreviaMatricula().initialize()

export default service
