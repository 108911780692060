import React, { useEffect, useMemo, useState } from 'react'
import { Collapse, Form, Tabs, message } from 'antd'
import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { getServiceConfig, useCategoriesContext } from 'domain/servicesCBRdoc'
import { CBRdocDocument } from 'domain/servicesCBRdoc/config'
import { CategoryAPI, ServiceType } from 'domain/servicesCBRdoc/types'
import { Section } from 'layouts'
import { normalizeString } from 'utils/formatters'
import Icon from 'ui/Icon/Icon'
import { SearchInput } from 'ui'

import { useCreateKit } from './CreateKitContext'
import ServicePanel from './ServicesPanel'

type TabsType = 'all' | ServiceType

export default function CreateKitStep2() {
  const { items } = useCreateKit()
  const categories = useCategoriesContext()
  const [filter, setFilter] = useState('')
  const history = useHistory()
  const [tab, setTab] = useState<TabsType>('all')
  const [activeKey, setActiveKey] = useState<number | undefined>(undefined)

  const categoriesToShow = useMemo(() => {
    const showCategories: CategoryAPI[] = []

    if (categories) {
      const normalizedFilter = normalizeString(filter)

      categories.forEach(category => {
        const servicesToShow = category.services.filter(service => {
          const config = getServiceConfig<CBRdocDocument>(service.code)

          if (!config) {
            return false
          }
          
          return config.isKitAvailable && normalizeString(service.name).includes(normalizedFilter)
        })

        if (servicesToShow.length > 0) {
          showCategories.push({ ...category, services: servicesToShow })
        }
      })
    }

    if (tab === 'all') {
      return showCategories
    }

    return showCategories.filter(category => category.services_type === tab)
  }, [categories, filter, tab])

  const onFinish = () => {
    if (items.length > 0) {
      history.push(Routes.CreateKitStep('fixar-dados'))
    } else {
      message.error('Selecione ao menos um serviço.')
    }
  }

  useEffect(() => {
    if (categoriesToShow.length > 0) {
      setActiveKey(categoriesToShow[0].id)
    }
  }, [categoriesToShow])

  return (
    <Section heading="Documentos do Kit" fullWidth>
      <Tabs className="custom-tabs mb-4" items={tabItems} activeKey={tab} onChange={setTab as any} />
      <p className="text-secondary-500 font-normal text-sm mb-4">
        Selecione os documentos que farão parte do kit.
      </p>

      <Form id="kit-register-form" onFinish={onFinish}>
        <div className="flex justify-start">
          <SearchInput
            placeholder="Pesquisar por..."
            onChange={e => setFilter(e.target.value)}
            className="mb-7 md:w-96"
            allowClear
          />
        </div>

        <Collapse
          accordion
          expandIconPosition="right"
          className="spaced-collapse"
          expandIcon={({ isActive }) => (
            <Icon name={isActive ? 'chevron-up' : 'chevron-down'} className="text-primary" />
          )}
          activeKey={activeKey}
          onChange={setActiveKey as any}
        >
          {categoriesToShow.map(category => (
            <ServicePanel key={category.id} category={category} />
          ))}
        </Collapse>
      </Form>
    </Section>
  )
}

const tabItems = [
  {
    key: 'all',
    label: 'Todos',
  },
  {
    key: ServiceType.CERTIFICATE,
    label: (
      <>
        <Icon name="registro-civil" className="text-xl mr-3" />
        Documentos
      </>
    ),
  },
  {
    key: ServiceType.RESEARCH,
    label: (
      <>
        <Icon name="search" className="text-xl mr-3" />
        Pesquisas
      </>
    ),
  },
  {
    key: ServiceType.DILIGENCE,
    label: (
      <>
        <Icon name="diligencias" className="text-xl mr-3" />
        Diligências
      </>
    ),
  },
]
