import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import { PrintConfig } from 'domain/app/types'

export function resolveFileName(responseHeaders: any, fallbackFileName: string) {
  const contentDisposition = responseHeaders['content-disposition']
  let filenameFromServer = null

  if (contentDisposition) {
    // TODO: remover contentDisposition.match(/filename="(.*)"/) || temporário -> v1
    const match = contentDisposition.match(/filename="(.*)"/) || contentDisposition.match(/filename=(.*)/)

    if (match) {
      // @ts-ignore
      filenameFromServer = decodeURI(match[1])
    }
  }

  if (filenameFromServer) {
    return filenameFromServer
  }

  const mimeType = responseHeaders['content-type']

  if (mimeType || !fallbackFileName.includes('.')) {
    const extension = mimeType === 'application/zip' ? 'zip' : 'pdf'

    return `${fallbackFileName}.${extension}`
  }

  return fallbackFileName
}

export function saveAs(blob: any, fileName: string) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = url
  link.download = fileName
  document.body.appendChild(link)

  link.click()
  document.body.removeChild(link)
}

type ExportToPDFParams = {
  body: any
  columns: PrintConfig[]
  fileName: string
  orientation?: 'portrait' | 'landscape'
  mode?: 'print'
  styles?: any
}

export function exportToPDF({ body, columns, fileName, orientation = 'portrait', mode, styles = {} }: ExportToPDFParams) {
  const doc = new jsPDF({
    orientation,
  })

  autoTable(doc, {
    body,
    columns,
    theme: 'striped',
    didDrawPage: function (data) {
      const logoCBRdoc = require('assets/images/logoPDFbase64')
      doc.addImage(logoCBRdoc.image, 'PNG', data.settings.margin.left, 15, 70, 10)
    },
    margin: { top: 32, bottom: 18 },
    ...styles,
  })

  if (mode === 'print') {
    window.open(doc.output('bloburl'), '_blank')
  } else {
    doc.save(`${fileName}.pdf`)
  }
}
