import { requiredRule } from 'app/config/validationRules'
import { CreateOrderParamsFields } from 'domain/createOrder/types'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoJusticaEstadualFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ | FieldName.URL_UF,
  FieldConfig
>

type CertidaoJusticaEstadualDynamicFields = Record<
  | FieldName.COMARCA
  | FieldName.DATA_NASCIMENTO
  | FieldName.ESTADO_CIVIL
  | FieldName.GENERO
  | FieldName.INSTANCIA
  | FieldName.IPTU
  | FieldName.MODELO
  | FieldName.NACIONALIDADE
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NATURALIDADE
  | FieldName.NATUREZA
  | FieldName.RG
  | FieldName.RG_EXPEDIDOR
  | FieldName.RG_UF
  | FieldName.REGISTRADO_ENDERECO_CEP
  | FieldName.REGISTRADO_ENDERECO_COMPLETO
  | FieldName.REPRESENTANTE_LEGAL
  | FieldName.TEMPO_PESQUISA
  | FieldName.TIPO_CERTIDAO
  | FieldName.URL_CARTORIO,
  FieldConfig
>

class CertidaoJusticaEstadual extends Certificate {
  checkDynamicFieldsOnKit = true
  priceWithDynamicFields = true

  formFields: CertidaoJusticaEstadualFields = {
    url_uf: Fields.url_uf,
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  dynamicFields: CertidaoJusticaEstadualDynamicFields = {
    rg: Fields.rg,
    rg_expedidor: {
      ...Fields.rg_expedidor,
      optional: true,
    },
    rg_uf_expedicao: Fields.rg_uf_expedicao,
    mae: Fields.mae,
    pai: {
      ...Fields.pai,
      optional: false,
    },
    nascimento: Fields.nascimento,
    naturalidade: Fields.naturalidade,
    nacionalidade: {
      ...Fields.nacionalidade,
      kind: 'select',
      apiExtraInfo: 'nacionalidades',
    },
    estado_civil: {
      ...Fields.estado_civil,
      apiExtraInfo: 'estados-civis',
    },
    genero: Fields.genero,
    natureza: Fields.natureza,
    modelo: Fields.modelo,
    comarca: {
      ...Fields.comarca,
      dataScope: 'service',
    },
    instancia: {
      ...Fields.instancia,
      apiExtraInfo: undefined,
      options: [
        { url: 'primeiro-grau', nome: '1ª Grau' },
        { url: 'segundo-grau', nome: '2ª Grau' },
      ],
    },
    iptu: {
      ...Fields.iptu,
      optional: false,
      validationRules: requiredRule,
    },
    tempo_pesquisa: Fields.tempo_pesquisa,
    registrado_endereco_cep: {
      ...Fields.registrado_endereco_cep,
      optional: false,
    },
    registrado_endereco_completo: Fields.registrado_endereco_completo,
    tipo_certidao: {
      ...Fields.tipo_certidao,
      vertical: true,
    },
    representante_legal: Fields.representante_legal,
    url_cartorio: {
      ...Fields.url_cartorio,
      apiExtraInfo: 'cartorios',
      multiple: true,
    },
  }

  private federativeUnitsWithDisctrict = ['PR', 'RJ']
  private federativeUnitWithModel = ['RJ', 'MG']

  constructor() {
    super({
      id: 26,
      shortName: 'Certidão TJ',
      steps: ['dados', 'entrega'],
    })
  }

  getAvailableFormatsParams = (
    {
      dynamicFields,
      formItems,
      selectedComarca,
      selectedLocation,
      selectedModel,
      selectedInstance,
      selectedEntityType,
    }: CreateOrderParamsFields,
    itemId = 0
  ) => {
    if (!dynamicFields || dynamicFields.length === 0) {
      return null
    }

    const params = this.extractLocationUrls(selectedLocation)

    const federativeUnit = params.url_uf

    params.tipo_pessoa = formItems[itemId]?.selectedEntityType ?? selectedEntityType ?? 'fisica'

    if (
      dynamicFields.includes(FieldName.COMARCA) ||
      this.federativeUnitsWithDisctrict.includes(federativeUnit)
    ) {
      params.comarca = selectedComarca
    }

    // @ts-ignore
    if (federativeUnit === 'PI' || dynamicFields.includes('instancia')) {
      params.instancia = selectedInstance as string
    }

    if (dynamicFields.includes(FieldName.MODELO) || this.federativeUnitWithModel.includes(federativeUnit)) {
      params.modelo = selectedModel as string
    }

    if (dynamicFields.includes(FieldName.URL_CARTORIO)) {
      params.url_cartorio = this.getUrlLocation(FieldName.URL_CARTORIO, selectedLocation.url_cartorio)
    }

    return params
  }

  getExtraInfoParams = (createOrder: CreateOrderParamsFields | undefined, itemId = 0) => {
    const {
      dynamicFields,
      formItems,
      selectedLocation,
      selectedModel,
      selectedInstance,
      selectedComarca,
      selectedEntityType,
    } = createOrder!

    const params = this.extractLocationUrls(selectedLocation)
    const federativeUnit = params.url_uf

    params.tipo_pessoa = formItems[itemId]?.selectedEntityType ?? selectedEntityType ?? 'fisica'

    if (this.federativeUnitWithModel.includes(federativeUnit)) {
      params.modelo = selectedModel as string
    } else if (federativeUnit === 'PI') {
      params.instancia = selectedInstance
    }

    if (this.federativeUnitsWithDisctrict.includes(federativeUnit)) {
      params.comarca = selectedComarca
    }

    if (dynamicFields?.includes(FieldName.COMARCA) && selectedComarca) {
      params.comarca = selectedComarca
    }

    return params
  }

  getPriceParams = (createOrder: CreateOrderParamsFields, itemId = 0) => {
    const params = this.getAvailableFormatsParams(createOrder)

    if (!params) {
      return null
    }

    const federativeUnit = params.url_uf
    const { formItems, selectedModel, selectedResearchTime } = createOrder

    if (federativeUnit === 'RJ') {
      if (selectedModel === 'titulos-documentos') {
        params.tempo_pesquisa = formItems[itemId]?.selectedResearchTime ?? selectedResearchTime
      }
    }

    params.formato = createOrder.selectedFormat

    return params
  }
}

const service = new CertidaoJusticaEstadual().initialize()

export default service
