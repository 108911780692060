import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { SelectNotaryCategory } from 'domain/createOrder/certificates'
import { PageLayout } from 'layouts'

import { useLoadAvailableFormats } from '../services'
import { useCreateOrderSteps } from '../state'
import CreateOrderActionBar from './CreateOrderActionBar'
import FormatAndExtrasStep from './FormatAndExtrasStep'
import Steps from './Steps'
import UploadStep from './UploadStep'
import SelectLocationStep from './SelectLocationStep'

export interface CreateOrderFlowProps {
  children?: React.ReactNode
  dataStep?: React.ReactNode
  locationStep?: React.ReactNode
  uploadStep?: React.ReactNode
  sidebar: JSX.Element
}

export default function CreateOrderFlow({
  dataStep,
  locationStep,
  uploadStep,
  sidebar,
  children
}: CreateOrderFlowProps) {
  const { path } = useRouteMatch()
  const steps = useCreateOrderSteps()

  useLoadAvailableFormats()

  return (
    <PageLayout pageTitle="Fazer pedido" extra={<Steps />} sidebar={sidebar}>
      <CreateOrderActionBar />

      <div className="bg-gray-100 flex-1 mt-4">
        <Switch>
          {steps.categoria && (
            <Route path={[path, `${path}/categoria`]} exact>
              <SelectNotaryCategory />
            </Route>
          )}

          {steps.localizacao && (
            <Route path={steps.categoria ? `${path}/localizacao` : [path, `${path}/localizacao`]} exact>
              {locationStep ?? <SelectLocationStep />}
            </Route>
          )}

          {steps.upload && (
            <Route path={[path, `${path}/upload`]} exact>
              {uploadStep ?? <UploadStep />}
            </Route>
          )}

          {steps.dados && (
            <Route path={steps.localizacao ? `${path}/dados` : [path, `${path}/dados`]} exact>
              <div className="lg:max-w-xl p-7">{dataStep}</div>
            </Route>
          )}

          {children}

          {steps.entrega && (
            <Route path={`${path}/entrega`} exact>
              <FormatAndExtrasStep />
            </Route>
          )}
        </Switch>
      </div>
    </PageLayout>
  )
}
