import { PeriodString } from 'domain/app/types'
import { CustomerAPI } from 'domain/customers/types'
import { GroupAPI } from 'domain/groups/types'
import { AIModel, EnrichmentAvailability } from 'domain/openAI/types'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { UserAPI } from 'domain/users/types'

export enum ExplorerItemType {
  ORDER = 'order',
  FOLDER = 'folder',
  UPLOADED_FILE = 'uploaded_file',
  AI = 'ai',
}

export interface ExplorerFilters {
  ai?: boolean
  name?: string
  dates?: PeriodString
  endDate?: string
  groupsIds?: number[]
  ownerId?: number
  type?: ExplorerItemType
  servicesIds?: number[]
}

type SortField = 'last_operation_at' | 'name' | 'type'

export type SortBy = SortField | `-${SortField}`

export type FileData = {
  contentType: string
  signedUrl: string
}

export interface ExplorerItem {
  aiModel?: Pick<AIModel, 'questions'> & { hasAnswers: boolean }
  aiFreeAnalysisConsumed: boolean
  aiRequestPending: boolean
  breadcrumb?: ExplorerItem[]
  childrenCount?: string
  createdAt: string
  customerId: number
  customer?: CustomerAPI
  file: FileData
  groups?: GroupAPI[]
  history?: ExplorerHistoryItem[]
  id: number
  lastOperationAt: string
  name: string
  orderItemId: number | null
  ownerId: number
  parentId?: number
  serviceId?: number
  type: ExplorerItemType
  updatedAt: string
  ocr: OCRDocument
  order?: OrderItemAPI
  owner?: UserAPI  
}

export type ExplorerItemsSearchResult = Pick<ExplorerItem, 'id' | 'name' | 'orderItemId' | 'type'>
export type RecentExplorerItems = Pick<ExplorerItem, 'history' | 'id' | 'name' | 'orderItemId'>

export interface ExplorerList {
  breadcrumb: ExplorerItem[]
  children?: ExplorerItem[]
}

export type ExplorerAPI = ExplorerItem | ExplorerList

export interface GetExplorerListParams {
  parentId?: number | null
  type?: ExplorerItemType
}

export interface GetExplorerRecentParams {
  type?: ExplorerItemType
  perPage?: number
  expand?: string
}

export interface ExplorerSearchGroupedResult {
  folders: ExplorerItem[]
  orders: ExplorerItem[]
  recent_viewed: ExplorerItem[]
}

export interface SearchExplorerGroupedParams {
  name: string
}

export interface CreateExplorerItemParams {
  name: string
  type?: string
  parentId?: number | null
}

export interface MoveExplorerItemParams {
  id: string
  parentId?: number | 'root'
}

export interface ExplorerHistoryItem {
  id: number
  user_id: number
  tag_id: number | null
  item_id: number
  operation: ExplorerHistoryOperation
  operation_time: string | null
  description: string
  data_before: string | null
  data_after: string | null
}

export enum ExplorerHistoryOperation {
  CREATE = 'create',
  VIEW = 'view',
  MOVE = 'move',
  DELETE = 'delete',
  RENAME = 'rename',
  UPLOAD = 'upload',
  CREATE_TAG = 'create-tag',
  DELETE_TAG = 'delete-tag',
  UPDATE_TAG = 'update-tag',
  ADD_TAG_TO_ITEM = 'add-tag-to-item',
  REMOVE_TAG_FROM_ITEM = 'remove-tag-from-item',
}

export type ExplorerAction = 'move' | 'rename' | 'share' | 'tags' | undefined

export type ExplorerActionItem = {
  action?: ExplorerAction
  explorerItem?: ExplorerItem
}

export type GroupAction = 'edit' | 'delete' | undefined

export type GroupActionItem = {
  action?: GroupAction
  current?: GroupAPI
}

export type StorageUsage = {
  B: number
  MB: number
  GB: number
}

export interface GetMyFilesParams {
  parentId?: number
  filters?: ExplorerFilters
  sortBy?: SortBy
  page: number
  pageSize: number
}

export interface MyFilesPagination {
  page: number
  pageCount: number
  pageSize: number
  totalCount: number
}

export type OCRPage = {
  content: string
  id: number
  pageNumber: number
}

export interface OCRDocument {
  aiEnrichmentAvailability: EnrichmentAvailability
  id: number
  pages: OCRPage[]
  totalPages: number
  documentName?: string
}
