import React, { useReducer } from 'react'

import { useHasPermission } from 'domain/auth'
import { Button } from 'ui'
import ShareOrderModal from '../share/ShareOrderModal'

export default function ShareOrderButton({ itemId, fileURL }: { itemId: number; fileURL: string | null }) {
  const canShareOrders = useHasPermission('canShareOrders')
  const [open, toggleOpen] = useReducer(prev => !prev, false)  

  if (!canShareOrders) return null

  return (
    <>
      <Button
        icon="share"
        className="text-gray-700 font-bold"
        type="link"
        onClick={toggleOpen}
        disabled={!fileURL}
        size="small"
      >
        Compartilhar
      </Button>

      <ShareOrderModal open={open} onClose={toggleOpen} orderId={itemId} />
    </>
  )
}
