import { useReducer, useState } from 'react'

import { AuthorizedAPI } from 'api/network/v2'
import { errorHandler } from 'api/errors'
import { useGetPeriodFilter } from 'domain/reports/filters'
import { Button, Modal } from 'ui'
import { resolveFileName, saveAs } from 'utils/export'

import OneResultByRowCheckbox from './OneResultByRowCheckbox'

interface Props {
  disabled: boolean
}

export default function ExportOrdersToExcelButton({ disabled }: Props) {
  const [open, toggleOpen] = useReducer(prev => !prev, false)
  const [loading, setLoading] = useState(false)
  const [oneResultPerRow, toggleResultOption] = useReducer(prev => !prev, false)
  const period = useGetPeriodFilter()

  const onClick = async () => {
    try {
      setLoading(true)

      const dates = period.split(',')

      const response = await AuthorizedAPI.get('/reports/orders/xlsx', {
        responseType: 'blob',
        params: {
          begin_date: dates[0],
          end_date: dates[1],
          one_result_per_row: oneResultPerRow,
        },
      })

      const fileName = resolveFileName(response.headers, 'orders.xlsx')

      setLoading(false)
      saveAs(response.data, fileName)
    } catch (error) {
      errorHandler(error, { code: '0x412' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button type="primary" icon="file-xls" ghost disabled={disabled} onClick={toggleOpen}>
        Exportar para Excel
      </Button>

      <Modal open={open} onCancel={toggleOpen} title="Exportar para Excel">
        <OneResultByRowCheckbox onToggle={toggleResultOption} />

        <Button type="primary" block onClick={onClick} loading={loading}>
          Exportar
        </Button>
      </Modal>
    </>
  )
}
