import { useMemo } from 'react'

import { LegalEntity } from 'domain/app/types'
import { CreateOrderLegalEntityTabs, OrderNameField, useCreateOrderState } from 'domain/createOrder/document'
import { SelectedService } from 'domain/createOrder/types'
import { AllExtraInfos } from 'domain/orderItem/types'
import { CNPJAndNameFields, NameField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../certidaoJusticaEstadualConfig'
import AlertDocumentsRequired from './AlertDocumentsRequired'
import CertidaoJusticaEstadualFields from './CertidaoJusticaEstadualFields'

interface Props {
  itemId: number
  service: SelectedService
  federativeUnit: string | undefined
  entityType: LegalEntity | undefined
  instance: string | undefined
  type: string | undefined
  comarca?: string
  model?: string | number | undefined
  onChangeResearchTime?: (time: number) => void
  loading: boolean
  dynamicFields: AllExtraInfos | undefined
}

export default function CertidaoJusticaEstadualForm({
  dynamicFields,
  loading,
  federativeUnit,
  entityType,
  itemId,
  instance,
  onChangeResearchTime,
}: Props) {
  const { formItems } = useCreateOrderState()

  const disableTab = useMemo(() => {
    const hasMultipleItems = Object.keys(formItems).length > 1

    if (hasMultipleItems) {
      return entityType === 'fisica' ? 'juridica' : 'fisica'
    }

    return undefined
  }, [formItems])

  return (
    <>
      <CreateOrderLegalEntityTabs
        itemId={itemId}
        fisica={
          <>
            <CPFInput itemId={itemId} />
            <NameField itemId={itemId} field={nome_pf} />
          </>
        }
        juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
        disableTab={disableTab}
      />

      <CertidaoJusticaEstadualFields
        itemId={itemId}
        dynamicFields={dynamicFields}
        legalEntity={entityType!}
        loading={loading}
        onChangeResearchTime={onChangeResearchTime}
      />

      <OrderNameField itemId={itemId} />

      <AlertDocumentsRequired entityType={entityType} federativeUnit={federativeUnit} instance={instance} />
    </>
  )
}

const { nome_pf, nome_pj } = config.formFields
