import React from 'react'

import { ResearchResultsFieldName } from 'domain/orderItem/types'
import { Fields } from 'domain/servicesCBRdoc'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ViewInfo } from 'ui'

interface Props {
  result: any
}

function ViewOrderResultDetails({ result }: Props) {
  return (
    <ul>
      {Object.entries(result).map(([field, value]) => (
        <ResultDetails key={field} field={field as FieldName} result={value} />
      ))}
    </ul>
  )
}

function ResultDetails({ field, result }: { field: FieldName | ResearchResultsFieldName; result: any }) {
  const label = Fields[field as FieldName]?.label ?? ResultDetailsLabels[field as ResearchResultsFieldName]
  const info = result?.label ?? result

  if (!label || !info) {
    return null
  }

  return <ViewInfo label={label} value={info} />
}

const ResultDetailsLabels: Record<string, string> = {
  data: 'Data',
  livro_pagina: 'Livro/Página',
  nome_das_partes: 'Partes envolvidas',
  resultado: 'Resultado',
  valor_total: 'Valor total',
}

export default ViewOrderResultDetails
