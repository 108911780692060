import React from 'react'
import { Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

import { ExportAI } from 'domain/orderItem/ai'
import { Button } from 'ui'

interface Props {
  actions: ItemType[]
  downloadButton: React.ReactElement
}

export default function SidebarContainer({ actions, downloadButton }: Props) {
  return (
    <div className="flex justify-between items-center h-[61px] max-h-full px-3 border-l border-b border-gray-300 bg-gray-200">
      {downloadButton}

      <ExportAI />

      <Dropdown menu={{ items: actions }}>
        <Button type="link" icon="more-vertical" className="text-gray-700 font-bold">
          Outras ações
        </Button>
      </Dropdown>
    </div>
  )
}
