import { useEffect, useMemo } from 'react'
import { Form, Spin } from 'antd'

import { getFieldName } from 'domain/createOrder/shared'
import { SelectedService } from 'domain/createOrder/types'
import { useExtraInformationQuery } from 'domain/orderItem/shared'
import { RadioGroupButtonsField } from 'domain/servicesCBRdoc/fields'
import { getLocationUrl } from 'domain/servicesCBRdoc/helpers/location'
import { SelectedLocation } from 'domain/servicesCBRdoc/types'

import config from '../certidaoImovelConfig'
import { RealStateSearchFor, SearchForLabels } from '../types'

interface Props {
  service: SelectedService
  location: SelectedLocation
  itemId?: number
  type: string
  onChange?: (value: string) => void
}

export default function CertidaoImovelSearchFor({
  itemId,
  service,
  type,
  location,
  onChange,
}: Props) {
  const form = Form.useFormInstance()
  const locationUrls = getLocationUrl(location)

  const { data, isFetching } = useExtraInformationQuery<RealStateSearchFor[]>({
    service,
    information: 'opcoes-busca',
    params: {
      ...locationUrls,
      tipo: type,
    },
    itemId,
  })

  const options = useMemo(() => {
    if (!data) return []

    return data.map(value => ({
      value, 
      label: SearchForLabels[value],
    }))
  }, [data])

  useEffect(() => {
    if (data) {
      const searchForName = getFieldName(buscar_por.name, itemId)
      const currentValue = form.getFieldValue(searchForName)

      if (!currentValue || !data.includes(currentValue)) {
        const selectValue = data.includes('matricula') ? 'matricula' : (data as RealStateSearchFor[])[0]
        form.setFieldValue(searchForName, selectValue)
        onChange && onChange(selectValue)
      } else if (currentValue) {
        onChange && onChange(currentValue)
      }
    }
  }, [data])

  if (!location.url_cartorio) return null

  return (
    <Spin spinning={isFetching}>
      <RadioGroupButtonsField
        onChange={onChange}
        field={buscar_por}
        itemId={itemId}
        options={options}
        className="w-full"
      />
    </Spin>
  )
}

const { buscar_por } = config.formFields
