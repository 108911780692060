import React, { useReducer } from 'react'
import { Button } from 'antd'

import Icon from 'ui/Icon/Icon'

interface Props {
  qrCode: string
  pixKey: string
}

export default function PixViewData({ qrCode, pixKey }: Props) {
  const [isCopiedToClipboard, setCopiedToClipboard] = useReducer(prev => !prev, false)
  return (
    <div className="mb-4 flex flex-col gap-4 items-center">
      <div className="border-2 border-gray-600 rounded-md w-fit mt-4 mx-auto max-w-xs">
        <img src={qrCode} alt="QRCode Pix" className="m-0 w-full" />
      </div>

      <Button
        type="primary"
        className="mx-auto border-transparent rounded-lg bg-success-500 hover:bg-success-500/80"
        onClick={() => {
          navigator.clipboard.writeText(pixKey)
          setCopiedToClipboard()
        }}
      >
        Copiar chave PIX
      </Button>

      {isCopiedToClipboard && (
        <div className="mx-auto mb-2 text-xs text-gray-600">
          <Icon name="check" className="text-base mr-2" />
          Chave copiada!
        </div>
      )}
    </div>
  )
}
