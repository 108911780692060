import { Form, Spin } from 'antd'
import { useDispatch } from 'react-redux'

import { LabelInValue } from 'domain/app/types'
import {
  SelectLocationFields,
  createOrderActions,
  useCreateOrderState,
  useLoadExtraInfos,
} from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { AllExtraInfos } from 'domain/orderItem/types'
import { SelectField } from 'domain/servicesCBRdoc/fields'

import config from '../pesquisaDividaAtivaPGEConfig'
import RegisterInput from './RegisterInput'

export default function CreateOrderPesquisaDividaAtivaPGE() {
  const { data, isFetching } = useLoadExtraInfos()

  return (
    <ResearchFlow
      header={<SelectLocationFields autoFocus />}
      renderFormItem={(itemId: number) => (
        <PesquisaDividaAtivaPGEForm itemId={itemId} extraInfos={data} loading={isFetching} />
      )}
    />
  )
}

interface Props {
  itemId: number
  loading: boolean
  extraInfos: AllExtraInfos | undefined
}

function PesquisaDividaAtivaPGEForm({ itemId, extraInfos, loading }: Props) {
  const form = Form.useFormInstance()
  const dispatch = useDispatch()
  const { formItems } = useCreateOrderState()
  const selectedType = formItems[itemId]?.selectedType

  const onChangeType = (value: LabelInValue) => {
    dispatch(createOrderActions.setMultipleItemsType({ id: itemId, value }))
    form.setFieldValue([itemId, inscricao.name], undefined)
  }

  return (
    <>
      <Spin spinning={loading}>
        <SelectField
          itemId={itemId}
          field={tipo}
          extraInfos={extraInfos}
          onChange={onChangeType}
          labelInValue
        />
      </Spin>

      {selectedType && <RegisterInput itemId={itemId} selectedType={selectedType as LabelInValue} />}
    </>
  )
}

const { inscricao, tipo } = config.formFields
