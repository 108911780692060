import React from 'react'

import { AccountBalance } from 'domain/customers'
import { useIsCustomerPostPaid } from 'domain/customers/'
import { GoBackButton, PageLayout, Sidebar } from 'layouts'
import { ActionsBar } from 'ui'

interface Props {
  children: JSX.Element
  hideBackButton?: boolean
  pageTitle?: string
}

function BalanceSidebarLayout({ pageTitle = 'Fazer Pedido', children, hideBackButton }: Props) {
  const isPrePaid = !useIsCustomerPostPaid()

  return (
    <PageLayout
      pageTitle={pageTitle}
      sidebar={
        isPrePaid && (
          <Sidebar>
            <AccountBalance buttonPlacement="bottom" className="w-80" />
          </Sidebar>
        )
      }
    >
      {!hideBackButton && (
        <ActionsBar>
          <GoBackButton />
        </ActionsBar>
      )}

      {children}
    </PageLayout>
  )
}

export default BalanceSidebarLayout
