import React, { useState } from 'react'
import { Form } from 'antd'

import { LabelInValue } from 'domain/app/types'
import { Service } from 'domain/servicesCBRdoc/types'
import { LineSelect, NumberInput, SubmitButton } from 'ui'

import { STATES_SIMULATOR } from '../data'
import { SimulationInputItem } from '../types'

interface Props {
  servicesList?: Service[]
  setSimulationInput: React.Dispatch<React.SetStateAction<SimulationInputItem[]>>
  loading: boolean
}

export default function FormPriceSimulationSimplified({ servicesList, setSimulationInput, loading }: Props) {
  const [form] = Form.useForm()
  const [validationErrors, setValidationErrors] = useState<any>({})

  const checkValidationErrors = (formData: any) => {
    const errors: any = {}

    if (!formData.serviceIds || formData.serviceIds.length === 0) {
      errors.serviceIds = true
    }

    if (!formData.location) {
      errors.location = true
    }

    setValidationErrors(errors)

    return Object.keys(errors).length === 0
  }

  const onFinish = (formData: { quantity: number; serviceIds: LabelInValue[]; location: LabelInValue }) => {
    const noErrors = checkValidationErrors(formData)

    if (noErrors) {
      const data: any =
        formData.serviceIds?.map(({ value }) => ({
          //id: index + 1,
          service_id: value,
          quantity: formData.quantity ?? 1,
        })) ?? []

      const simulation: SimulationInputItem[] = [
        {
          id: 1,
          url_uf: formData.location!.value as string,
          data,
        },
      ]

      setSimulationInput(simulation)
    }
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={checkValidationErrors}
      className="font-bold mt-7 text-center sm:text-lg"
    >
      <Form.Item>
        <span>Preciso solicitar </span>
        <NumberInput
          name="quantity"
          size="small"
          min={1}
          max={999}
          className="text-center w-24 text-lg sm:w-32 border-0 border-b border-gray-500 p-0"
          placeholder="quantidade"
          noStyle
          required
        />
        <span> unidade(s)</span>
      </Form.Item>

      <Form.Item
        help={validationErrors.serviceIds ? 'Selecione o tipo de documento.' : undefined}
        validateStatus={validationErrors.serviceIds ? 'error' : undefined}
      >
        <div className="flex items-center justify-center mx-auto relative w-fit z-20">
          <div>de </div>
          <LineSelect
            name="serviceIds"
            placeholder="tipo de documento"
            label="Escolha até 3 documentos."
            allowClear
            options={servicesList}
            fieldNames={fieldNamesService}
            mode="multiple"
            multipleLimit={3}
            required
            noStyle
          />
        </div>
      </Form.Item>

      <Form.Item
        help={validationErrors.location ? 'Selecione o Estado.' : undefined}
        validateStatus={validationErrors.location ? 'error' : undefined}
      >
        <div className="flex items-center justify-center mx-auto relative w-fit z-20">
          <div>localizada(s) </div>
          <LineSelect
            name="location"
            noStyle
            required
            placeholder="Estado"
            options={STATES_SIMULATOR}
            fieldNames={fieldNamesUF}
            allowClear
          />
        </div>
      </Form.Item>

      <SubmitButton label="Ver simulação de valor" loading={loading} className="font-bold" />
    </Form>
  )
}

const fieldNamesService = { label: 'name', value: 'id' }
const fieldNamesUF = { label: 'name', value: 'code' }
