import React from 'react'
import { Form, message } from 'antd'

import { useStartAIOrder } from 'domain/createOrder/ai'
import { MAX_TEXT_LENGTH } from 'domain/openAI/data'
import { AICreateOrderModel, AIModelForm } from 'domain/openAI/types'
import { Alert, Heading, SubmitButton, TextInput, Icon } from 'ui'

import useCountTotalCaracters from '../helpers/useCountTotalCaracters'
import useSaveModel from '../services/useSaveModel'
import { useAIFormContext } from './AIFormContext'
import DocumentTypeSelect from './DocumentTypeSelect'
import ModelQuestions from './ModelQuestionsCollapse'

export default function EditModelForm() {
  const [form] = Form.useForm()
  const [model, setModel] = useAIFormContext()
  const initialActiveItem = model.questions?.[0]?.id ?? 0
  const { saveModel, loading } = useSaveModel()
  const startIAOrder = useStartAIOrder()
  const service = Form.useWatch('service', form)
  const [caractersCounter, totalCharacters] = useCountTotalCaracters()

  const onFinish = async (data: AIModelForm) => {
    const updated = { ...model, ...data }

    await saveModel(updated, {
      onSuccess: () => {
        message.success('Os dados foram salvos com sucesso.')
        startIAOrder(updated as AICreateOrderModel)
      },
    })

    setModel(updated)
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      scrollToFirstError
      className="p-7 md:max-w-4xl lg:max-w-lg"
      form={form}
      initialValues={model}
      onFinish={onFinish}
      onValuesChange={caractersCounter}
    >
      <Heading size="3" text="Dados do Modelo" />

      <TextInput name="modelName" label="Dê um título para o modelo" maxLength={60} required autoFocus />

      <DocumentTypeSelect name="service" />

      {(!service || service.value === 'other') && (
        <TextInput name="documentName" label="Nome do documento" maxLength={120} required />
      )}

      <Heading size="3" text="Instruções" />

      <ModelQuestions totalCharacters={totalCharacters} initialActiveItem={initialActiveItem} />

      {totalCharacters > MAX_TEXT_LENGTH ? (
        <Alert
          type="warning"
          icon={<Icon name="warning" className="text-2xl" />}
          message={`O limite de ${MAX_TEXT_LENGTH} caracteres foi atingido. 
            Remova alguns campos ou diminua o tamanho dos textos.`}
          className="mt-4"
        />
      ) : (
        <Alert
          message={`Ainda restam ${MAX_TEXT_LENGTH - totalCharacters} caracteres. 
            Você pode usar para adicionar mais campos ou melhorar a suas instruções`}
          type="info"
          icon={<Icon name="info" />}
          className="mt-4"
        />
      )}

      <SubmitButton
        label="Salvar e Continuar"
        loading={loading}
        disabled={totalCharacters > MAX_TEXT_LENGTH}
      />
    </Form>
  )
}
