import React from 'react'

import { Button } from 'ui'
import { useDownload } from 'utils/export'

interface Props {
  itemId: number
  disabled?: boolean
}

export default function DownloadFile({ itemId, disabled }: Props) {
  const [handleDownload, isFileLoading] = useDownload()

  const onClick = async () => {
    const downloadUrl = `/orders/${itemId}/download`
    await handleDownload(downloadUrl)
  }

  return (
    <Button
      type="link"
      icon="download"
      className="no-underline text-gray-700 font-bold"
      disabled={disabled}
      onClick={onClick}
      loading={isFileLoading}
    >
      Baixar
    </Button>
  )
}
