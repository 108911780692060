import { ServiceLink, ServiceLinkProps, useSelectServiceClick } from 'domain/createOrder/shared'
import { getService } from 'domain/servicesCBRdoc'

interface Props extends Omit<ServiceLinkProps, 'onClick'> {
  categoryId?: number
}

export default function DocumentLink({ service, categoryId, children, bold }: Props) {
  const onClick = useSelectServiceClick(service, categoryId)
  const { displayIsNewTag } = getService(service.code)

  return (
    <ServiceLink service={service} bold={bold} onClick={onClick} isNew={displayIsNewTag}>
      {children}
    </ServiceLink>
  )
} 
