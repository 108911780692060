import React from 'react'
import { ItemType } from 'antd/es/menu/hooks/useItems'

import { useCurrentUser } from 'domain/auth'
import {
  PrintButton,
  RecurrenceButton,
  RedoOrderButton,
  ReportProblemButton,
  ShareOrderButton,
  SidebarContainer,
  useGetOrderItem,
} from 'domain/orderItem/shared'
import { StatusCode } from 'domain/orders/types'

import SetExpirationDate from './SetExpirationDate'
import DownloadFile from './DownloadFile'

export default function ViewCertificateActions() {
  const user = useCurrentUser()
  const orderItem = useGetOrderItem()
  const { id, fileURL } = orderItem

  const actions: ItemType[] = [
    { label: <ShareOrderButton itemId={id} fileURL={fileURL} />, key: 'share' },
    { label: <PrintButton fileURL={fileURL} />, key: 'print' },
    { label: <RedoOrderButton orderItem={orderItem} />, key: 'redo' },
    { label: <RecurrenceButton orderItem={orderItem} />, key: 'recurrence' },
    { label: <ReportProblemButton itemId={id} />, key: 'problem' },
  ]

  if (orderItem.status === StatusCode.FINALIZADO && orderItem.user.id === user.id) {
    actions.unshift({ label: <SetExpirationDate />, key: 'expiration' })
  }

  return (
    <SidebarContainer downloadButton={<DownloadFile itemId={id} disabled={!fileURL} />} actions={actions} />
  )
}
