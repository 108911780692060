import { MaskedField } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoNegativaDebitosTributariosPGEConfig'

interface Props {
  federativeUnit: string
  itemId?: number
}

export default function InscricaoEstadualField({ federativeUnit, itemId }: Props) {
  if (!UFS_WITH_IE_REQUIRED.includes(federativeUnit)) {
    return null
  }
  
  return <MaskedField field={inscricao_estadual} itemId={itemId} />
}

const UFS_WITH_IE_REQUIRED = ['AC', 'AL', 'CE', 'DF']
const { inscricao_estadual } = config.formFields
