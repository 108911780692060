import React from 'react'

import { OrderAllResearchResultsButton } from 'domain/createOrder/researchResults'
import {
  PrintButton,
  RedoOrderButton,
  RecurrenceButton,
  ReportProblemButton,
  ShareOrderButton,
  SidebarContainer,
  useGetOrderItem,
} from 'domain/orderItem/shared'
import { ExcelGenerator } from 'domain/orderItem/types'
import ExportFile from './ExportFile'

export default function ViewResearchActions({ excel }: { excel: ExcelGenerator }) {
  const orderItem = useGetOrderItem()
  const { id, fileURL } = orderItem

  const items = [
    { label: <OrderAllResearchResultsButton />, key: 'item-0' },
    { label: <ShareOrderButton itemId={id} fileURL={fileURL} />, key: 'item-1' },
    { label: <PrintButton fileURL={orderItem.fileURL} />, key: 'item-2' },
    { label: <RedoOrderButton orderItem={orderItem} />, key: 'item-3' },
    { label: <ReportProblemButton itemId={id} />, key: 'item-4' },
    { label: <RecurrenceButton orderItem={orderItem} />, key: 'item-5' },
  ]  

  return (
    <SidebarContainer actions={items} downloadButton={<ExportFile excel={excel} orderItem={orderItem} />} />
  )
}
