import { useRef } from 'react'

import { LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { ExtraInfoSelect, SelectField } from 'domain/servicesCBRdoc/fields'

import config from '../certidaoJusticaEstadualConfig'

interface Props {
  service: SelectedService
  federativeUnit: string | undefined
  legalEntity: LegalEntity | undefined
  itemId?: number
  model?: string | number
  onChange: (comarca: string) => void
  options?: any[]
}

function ComarcaField({
  service,
  legalEntity,
  federativeUnit,
  model,
  options,
  itemId,
  ...props
}: Props) {
  const isDynamic = useRef(!options)

  if (!isDynamic.current) {
    return <SelectField field={comarca} options={options} itemId={itemId} {...props} />
  }

  const params: any = {
    tipo_pessoa: legalEntity,
    url_uf: federativeUnit,
  }

  if (federativeUnit === 'RJ') {
    params.modelo = model
  }

  return <ExtraInfoSelect field={comarca} service={service} params={params} itemId={itemId} {...props} />
}

const { comarca } = config.dynamicFields

export default ComarcaField
