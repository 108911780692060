import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Certificate  } from 'domain/servicesCBRdoc/config'

type CertidaoImprobabilidadeAdmFields = Record<FieldName.CPF | FieldName.CNPJ, FieldConfig>

class CertidaoImprobidadeAdm extends Certificate {
  formFields: CertidaoImprobabilidadeAdmFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
  }

  constructor() {
    super({
      id: 67,
      shortName: 'Improbidade Adm.',
      steps: ['dados', 'entrega'],
    })
  }
}

const service = new CertidaoImprobidadeAdm().initialize()

export default service
