import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { AuthorizedAPI } from 'api/network/v2'
import { useIsCustomerPostPaid } from 'domain/customers'
import { useGetOverviewFilters } from 'domain/orders/state/filtersOverview'
import {
  useGetOverviewCurrentPage,
  useGetOverviewPageSize,
  useGetOverviewSortBy,
  useSetOverviewTotalPages,
  useSetOverviewTotalResults,
} from 'domain/orders/state/pagination'
import { convertOrderItemListing } from 'domain/orderItem'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'
import { getFilters } from 'utils/transformData'

import QueryKeys from './OrdersQueryKeys'

export default function useOrderItemsQuery(orderId: number | string | undefined) {
  const filters = useGetOverviewFilters()
  const overviewCurrentPage = useGetOverviewCurrentPage()
  const overviewPageSize = useGetOverviewPageSize()
  const overviewSortBy = useGetOverviewSortBy()
  const setOverviewTotalPages = useSetOverviewTotalPages()
  const setOverviewTotalResults = useSetOverviewTotalResults()
  const isPostPaid = useIsCustomerPostPaid()

  return useQuery(
    QueryKeys.orderItems(orderId, overviewCurrentPage!, filters, overviewPageSize, overviewSortBy),
    async () => {
      const response: AxiosResponse<OrderItemAPI[]> = await AuthorizedAPI.get('/orders', {
        params: {
          append: ['ai_service_name', 'has_ai_extracted_data', 'register'],
          include: ['groups', 'ocr', 'service', 'service_category', 'user'],
          'per-page': overviewPageSize,
          sort: overviewSortBy,
          page: overviewCurrentPage,
          ...getFilters({
            purchase_id: orderId,
            result: filters.results.join(','),
            status: filters.statuses.join(','),
            expired: filters.isExpired,
          }),
        },
      })

      setOverviewTotalPages(Number(response.headers['x-pagination-page-count']))
      setOverviewTotalResults(Number(response.headers['x-pagination-total-count']))

      return response.data?.map(item => convertOrderItemListing(item, isPostPaid!))
    },
    {
      enabled: Boolean(orderId && isPostPaid !== undefined && overviewCurrentPage),
      keepPreviousData: true,
    }
  )
}
