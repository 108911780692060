import { convertToSelectedService } from 'domain/createOrder/document'
import { SelectedService } from 'domain/orderItem/types'
import { DiligenceCode } from 'domain/servicesCBRdoc/types'
import { Kit, KitAPI, KitItem, KitLegalEntity } from '../types'

function convertEntityType(input: 'PF' | 'PJ' | null): KitLegalEntity {
  if (input === 'PF') return 'fisica'
  if (input === 'PJ') return 'juridica'
  return 'indefinido'
}

function convertKit(kit: KitAPI): Kit {
  const items: KitItem[] = []

  kit.items?.forEach(item => {
    if (item.service.code) {
      const itemData = {
        id: item.id,
        defaultData: item.default_data,
        ownerId: kit.owner?.id,
        service: convertToSelectedService(item.service, item.service_category_id) as SelectedService,
        createdAt: item.created_at,
      }

      items.push(itemData)
    }
  })

  const hasOnlyDiligencies =
    kit.items &&
    kit.items.filter(i => DILIGENCIES.includes(i.service.code as any)).length === kit.items.length

  return {
    id: kit.id,
    name: kit.name,
    legalEntity: convertEntityType(kit.restrict_entity_type_to),
    items,
    owner: {
      id: kit.owner?.id,
      name: kit.owner?.name,
    },
    customerId: kit.customer_id,
    createdAt: new Date(kit.created_at).toISOString(),
    hasSpreadsheet: kit.spreadsheet_purchase_available,
    hasLegalEntity: hasOnlyDiligencies ? false : true,
  }
}

const DILIGENCIES = Object.values(DiligenceCode)

export default convertKit
