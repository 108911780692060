import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoNegativaDebitosTributariosPGEFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.INSCRICAO_ESTADUAL
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.URL_UF,
  FieldConfig
>

const { URL_UF } = FieldName

class CertidaoNegativaDebitosTributariosPGE extends Certificate {
  formFields: CertidaoNegativaDebitosTributariosPGEFields = {
    url_uf: Fields.url_uf,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    cnpj: Fields.cnpj,
    nome_pj: Fields.nome_pj,
    inscricao_estadual: {
      ...Fields.inscricao_estadual,
      ignoreOnKitsAutomaticForm: true,
    },
  }

  constructor() {
    super({
      id: 46,
      priceByFields: [URL_UF],
      shortName: 'PGE - Débitos Tributários',
      steps: ['dados'],
    })
  }
}

const service = new CertidaoNegativaDebitosTributariosPGE().initialize()

export default service
