import { NamePath } from 'antd/es/form/interface'

import { getFieldName } from 'domain/createOrder/shared'
import { TextInput, TextInputProps } from 'ui'
import { FieldConfig } from '../types'

interface Props extends Omit<TextInputProps, 'label' | 'name'> {
  field: FieldConfig
  itemId?: number | string
  name?: NamePath
}

export default function TextField({
  field,
  itemId,
  required = !field.optional,
  rules = field.validationRules,
  maxLength = field.maxLength,
  name,
  ...props
}: Props) {
  return (
    <TextInput
      label={field.label}
      name={getFieldName(name ?? field.name, itemId)}
      required={required}
      rules={rules}
      maxLength={maxLength}
      {...props}
    />
  )
}
