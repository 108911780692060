import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document/state'
import { DontKnowBookPageFields, DontKnowBookPageFieldsProps } from 'domain/servicesCBRdoc/fields'

export default function CreateOrderDontKnowBookPageFields({
  itemId,
  ...props
}: Omit<DontKnowBookPageFieldsProps, 'onChangeDontKnow'>) {
  const dispatch = useDispatch()

  return (
    <DontKnowBookPageFields
      {...props}
      itemId={itemId}
      onChangeDontKnow={checked => {
        dispatch(
          createOrderActions.setMultipleItemsDontKnowBookPage({
            id: itemId,
            value: checked,
          })
        )
      }}
    />
  )
}
