import { Spin } from 'antd'

import { LegalEntity } from 'domain/app/types'
import { AllExtraInfos } from 'domain/orderItem/types'
import { AddressFields, ExtraInfoFields } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CertificateCode } from 'domain/servicesCBRdoc/types'

interface Props {
  legalEntity: LegalEntity
  loading: boolean
  dynamicFields: AllExtraInfos | undefined | void
  itemId: number
  onChangeResearchTime?: (time: number) => void
}

export default function CertidaoJusticaEstadualFields({
  dynamicFields,
  itemId,
  loading,
  legalEntity,
}: Props) {
  const hasRegistreeFullAddress = dynamicFields?.['campos-necessarios']?.includes(
    REGISTRADO_ENDERECO_COMPLETO
  )

  if (hasRegistreeFullAddress) {
    exceptions.push(REGISTRADO_ENDERECO_CEP)
  }

  return (
    <Spin spinning={loading}>
      <ExtraInfoFields
        legalEntity={legalEntity}
        serviceCode={CertificateCode.CERTIDAO_JUSTICA_ESTADUAL}
        data={dynamicFields}
        exceptions={exceptions}
        loading={loading}
        itemId={itemId}
      />

      {hasRegistreeFullAddress && <AddressFields autoFocus={false} itemId={itemId} />}
    </Spin>
  )
}

const {
  CPF,
  CNPJ,
  COMARCA,
  INSTANCIA,
  MODELO,
  NOME_PF,
  NOME_PJ,
  REGISTRADO_ENDERECO_CEP,
  REGISTRADO_ENDERECO_COMPLETO,
  URL_CARTORIO,
} = FieldName

const exceptions = [
  COMARCA,
  CPF,
  CNPJ,
  INSTANCIA,
  MODELO,
  NOME_PF,
  NOME_PJ,
  REGISTRADO_ENDERECO_COMPLETO,
  URL_CARTORIO,
]
