import { useDispatch } from 'react-redux'

import { createOrderActions } from 'domain/createOrder/document/state'
import { CreateOrderFormItem } from 'domain/createOrder/types'
import { AddFormItemButton } from 'ui'

interface Props {
  disabled?: boolean
  extraData?: Partial<CreateOrderFormItem>
  label: string
  minItemId?: number
  quantity?: number
  setActiveItem: React.Dispatch<React.SetStateAction<number | undefined>>
}

export default function CreateOrderAddFormItemButton({
  label,
  setActiveItem,
  disabled,
  quantity = 1,
  extraData,
  minItemId = 0
}: Props) {
  const dispatch = useDispatch()

  const handleClick = (itemId: number) => {
    const activeItemId = itemId + minItemId

    for (let i = 0; i < quantity; i++) {
      dispatch(createOrderActions.addFormItem({ id: itemId + i, extraData }))
    }

    setActiveItem(activeItemId)
  }

  return <AddFormItemButton label={label} disabled={disabled} onSuccess={handleClick} />
}
