import React from 'react'
import { Button, message } from 'antd'

import { PrintConfig } from 'domain/app/types'
import { useOrderItemDownloadPDF } from 'domain/orders/services'
import { OrderOverview } from 'domain/orders/types'
import { exportToPDF } from 'utils/export'

interface Props {
  order: OrderOverview
}

export default function DownloadOrderOverviewPDFButton({ order }: Props) {
  const { data } = useOrderItemDownloadPDF(order.id)

  const handleExport = () => {
    const fileName = `Items_Pedido_${order.name}-${order.orderNumber}`
    if (data) {
      exportToPDF({ body: data, fileName, columns, styles: { columnStyles } })
    } else {
      message.error({
        content: 'Não foi possível baixar seu resumo.',
      })
    }
  }

  return (
    <Button onClick={handleExport} type="link" size="small">
      Baixar dossiê (resumo) em PDF
    </Button>
  )
}

const columns: PrintConfig[] = [
  {
    header: 'Nome da compra',
    dataKey: 'Nome da compra',
  },
  {
    header: 'Nº do pedido',
    dataKey: 'Nº do pedido',
  },
  {
    header: 'Data do pedido',
    dataKey: 'Data do pedido',
  },
  {
    header: 'Documento',
    dataKey: 'Documento',
  },
  {
    header: 'Status',
    dataKey: 'Status',
  },
]

const columnStyles = {
  'Nome da compra': { cellWidth: 'auto', minCellWidth: 40 },
  'Nº do pedido': { minCellWidth: 25 },
  Registro: { minCellWidth: 25 },
  'Data do pedido': { minCellWidth: 25 },
  Documento: { minCellWidth: 30 },
  Status: { minCellWidth: 20 },
}
