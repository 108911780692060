import { AIQuestion } from 'domain/openAI/types'
import { useState } from 'react'

function useCountTotalCaracters(): [(_: any, form: any) => void, number] {
  const [totalCharacters, setTotalCaracters] = useState(0)

  const caractersCounter = (changedValues: any, form: any) => {
    const total = form.questions.reduce(
      (acc: number, o: AIQuestion) => {
        if (o && o.questionToAi && o.questionToAi.length) {
          return acc + o.questionToAi.length
        }

        return acc
      },
      0
    )
    setTotalCaracters(prev => isNaN(total) ? prev : total)
  }

  return [caractersCounter, totalCharacters]
}

export default useCountTotalCaracters
