import React from 'react'
import { Icon } from 'ui'

import PixViewData from './PixViewData'

interface Props {
  qrCode: string
  pixKey: string
}

export default function Pix({ qrCode, pixKey }: Props) {
  return (
    <div className="flex flex-col">
      <h1 className="flex flex-row items-center gap-2">
        <Icon name="checkmark-circle" size={30} className="text-green-500" />
        <span className="font-bold mt-2 text-2xl">Agora é só efetuar o pagamento!</span>
      </h1>

      <p className="my-2 text-center">Você tem 30 minutos para efetuar o pagamento.</p>
      <p className="text-center">Após este prazo esta chave PIX será cancelada.</p>

      <PixViewData qrCode={qrCode} pixKey={pixKey} />

      <div className="mt-8">
        <div className="flex items-start mb-4">
          <div className="bg-gray-300 min-w-[36px] rounded-full text-secondary-500 font-bold text-base p-2 flex items-center justify-center relative after:absolute after:w-1 after:bg-gray-300 after:h-9 after:-bottom-9">
            1
          </div>
          <p className="ml-2 font-normal text-xs text-secondary-500">
            Para pagar, tenha em mãos seu celular, abra o aplicativo do seu banco, localize a opção PIX
          </p>
        </div>
        <div>
          <div className="bg-gray-300 min-w-[36px] rounded-full text-secondary-500 font-bold text-base p-2 flex items-center justify-center relative after:absolute after:w-1 after:bg-gray-300 after:h-28 after:-bottom-28 after:right-8">
            2
          </div>
          <p className="mr-10 my-3 text-xs text-secondary-500">
            Use a câmera do seu celular para fazer a leitura do QR Code, confira os dados do pedido para
            confirmar que está tudo certo e realize o pagamento. Se você Clicou no botão ”Copiar chave PIX”,
            basta colar o código, avançar para a próxima etapa, conferir os dados e pagar.
          </p>
        </div>
        <div>
          <div className="bg-gray-300 min-w-[36px] rounded-full text-secondary-500 font-bold text-base p-2 flex items-center justify-center relative">
            3
          </div>
          <p className="mr-10 my-3 text-xs text-secondary-500">
            Após realizar o pagamento, você receberá a por e-mail a confirmação da compra.
          </p>
        </div>
      </div>
    </div>
  )
}
