import { AIShoppingStep } from 'domain/createOrder/types'
import { KitOrderStepURI } from 'domain/createOrder/kit/types'
import { ServiceCode } from 'domain/servicesCBRdoc/types'

import RoutePaths from './RoutePaths'

const Routes = {
  CreateDueDiligenceStep: (step: string) => {
    return `${RoutePaths.CREATE_ORDER_DUE_DILIGENCE}/${step}`
  },
  CreateKitStep: (step: 'selecionar-documentos' | 'fixar-dados') => {
    return `${RoutePaths.CREATE_KIT}/${step}`
  },
  CreateOrder: (serviceCode: ServiceCode) => `${RoutePaths.CREATE_ORDER}/${serviceCode}`,
  CreateOrderAI: (step: AIShoppingStep) => `${RoutePaths.CREATE_ORDER_AI}/${step}`,
  CreateOrderKit: (kitId: string | number, step: KitOrderStepURI = KitOrderStepURI.SERVICE_DATA) => {
    return RoutePaths.CREATE_ORDER_KIT.replace(':id', kitId as unknown as string) + step
  },
  CreateOrderSheet: (serviceCode: ServiceCode) => `${RoutePaths.CREATE_ORDER_SHEET}/${serviceCode}`,
  CreateOrderStep: (serviceCode: ServiceCode, step: string) => {
    return `${RoutePaths.CREATE_ORDER}/${serviceCode}/${step}`
  },
  EditAIModel: (id: number | string) => RoutePaths.AI_EDIT_MODEL.replace(':id', id as string),
  EditKit: (id: number | string) => RoutePaths.EDIT_KIT.replace(':id', id as string),
  EditRecurrence: (id: number | string) => RoutePaths.RECURRENCY_EDIT.replace(':id', id as string),
  MyFilesFolder: (id: number | string) => `${RoutePaths.MY_FILES}/pastas/${id}`,
  Order: (id: number | string) => RoutePaths.ORDER_ITEMS.replace(':id', id as string),
  ViewFile: (id: number | string) => RoutePaths.VIEW_FILE.replace(':id', id as string),
  ViewOrder: (id: number) => RoutePaths.VIEW_ORDER.replace(':id', id as any),
}

export default Routes
