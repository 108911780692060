import React from 'react'

import { useGetOrderItem } from 'domain/orderItem/shared'
import { Button } from 'ui'
import { useDownload } from 'utils/export'

function ExportAI() {
  const orderItem = useGetOrderItem()
  const [handleDownload, loading] = useDownload()

  const handleClick = async () => {
    const fileName = `cbrdoc-IA-item-pedido-${orderItem.id}`
    handleDownload(`reports/ai-answers/xlsx?ocr_id=${orderItem.ocr!.id}`, fileName)
  }

  if (!orderItem.hasAiExtractData) {
    return null
  }

  return (
    <Button
      type="link"
      title="Exportar dados extraídos pela Extração de Dados"
      className="text-gray-700 font-bold"
      loading={loading}
      icon="export"
      onClick={handleClick}
    >
      Exportar
    </Button>
  )
}

export default ExportAI
