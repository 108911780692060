import React from 'react'

import { TotalPrice, TotalPriceForPrePaidCustomer } from 'domain/createOrder/shared/components'
import { useCreateOrderState } from 'domain/createOrder/document/state'
import { useIsCustomerPostPaid } from 'domain/customers'

function MultipleItemsTotalPrice() {
  const { formItems, multipleItemsLoadingPrice } = useCreateOrderState()
  const isPostPaid = useIsCustomerPostPaid()

  const totalPrice = Object.values(formItems!).reduce((acc, item) => {
    const { countItems, orderDetails } = item
    const price = orderDetails?.totalValue

    if (!price) return acc

    return acc + price * (countItems || 1)
  }, 0)
  
  if (!totalPrice) return null

  if (isPostPaid) {
    return <TotalPrice totalPrice={totalPrice} loading={multipleItemsLoadingPrice} />
  }

  return <TotalPriceForPrePaidCustomer totalPrice={totalPrice} loading={multipleItemsLoadingPrice} />
}

export default MultipleItemsTotalPrice
