import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { GenericError } from 'api/errors'
import { RoutePaths } from 'app/routes'
import { SimilarOrdersError } from 'domain/createOrder/shared/errors'
import { useCheckSimilarOrders } from 'domain/createOrder/shared/services'
import { SimilarOrder } from 'domain/createOrder/types'
import { useIsCustomerPostPaid } from 'domain/customers'
import { KitItem } from 'domain/kits/types'
import { getServiceConfig } from 'domain/servicesCBRdoc'
import { shoppingCartStateActions } from 'domain/shoppingCart'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { removeStorageItem } from 'utils/localStorage'

import KitCreateOrder from '../../classes/KitCreateOrder'
import { KIT_ORDER_CURRENT_ID, useGetAllDynamicFields, useGetKit } from '../../state'
import { CreateOrderKit } from '../../types'
import getFormat from './getFormat'

type KitFormData = Pick<CreateOrderKit, 'formCommonData' | 'formServicesData'>

export interface AddToCartParams extends KitFormData {
  addAnotherPerson?: boolean
  items?: KitItem[]
  checkSimilars?: boolean
}

type Params = {
  onChangeStatus: (message: string) => void
}

export default function useAddKitToCart({ onChangeStatus }: Params) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const kit = useGetKit()
  const dynamicFields = useGetAllDynamicFields()
  const isPostPaid = useIsCustomerPostPaid()
  const checkSimilarOrders = useCheckSimilarOrders()

  const redirectToCart = () => {
    removeStorageItem(KIT_ORDER_CURRENT_ID)
    history.push(RoutePaths.SHOPPING_CART)
  }

  const addKitToCart = useCallback(
    (function addKitToCartGenerator() {
      let cartItems: any[] = []

      async function addItemsToCartAndRedirectOrReset({
        addAnotherPerson,
        checkSimilars,
        ...formData
      }: AddToCartParams) {
        try {
          setLoading(true)

          await addItemsToCart({ addAnotherPerson, checkSimilars, ...formData })
        } finally {
          setLoading(false)
          cartItems = []
        }
      }

      async function addItemsToCart({ checkSimilars, ...formData }: AddToCartParams) {
        let similarOrders: any = []
        generateCreateOrderState(formData)
        await appendFormat()
        await appendPrice()

        splitItems()

        if (checkSimilars) {
          similarOrders = await checkKitSimilarOrders()
        }

        dispatch(shoppingCartStateActions.addOrders(cartItems))

        if (similarOrders.length > 0) {
          throw new SimilarOrdersError(similarOrders)
        }
      }

      const generateCreateOrderState = ({
        items,
        ...formData
      }: Omit<AddToCartParams, 'addAnotherPerson' | 'form'>) => {      
        cartItems = (items ?? kit.items).map(kitItem => {
          return new KitCreateOrder(
            {
              kitLegalEntity: kit.legalEntity,
              requiredDynamicFields: dynamicFields?.[kitItem.id]?.map(field => field.name) ?? [],
              ...formData,
            },
            { ...kitItem, kitId: kit.id }
          ).generate()
        })
      }

      const appendFormat = async () => {
        onChangeStatus('Obtendo formatos disponíveis...')

        const promises = cartItems.map(async createOrder => {
          const selectedFormat = await getFormat(createOrder)

          return {
            ...createOrder,
            selectedFormat,
          }
        })

        const results = await Promise.allSettled(promises)
        const errors: any = results.filter(o => o.status === 'rejected')

        if (errors.length > 0) {
          throw new GenericError(
            'Falha na consulta de formatos disponíveis',
            errors.map(({ reason }: any) => reason)
          )
        }

        cartItems = results.map(({ value }: any) => value)
      }

      const appendPrice = async () => {
        onChangeStatus('Obtendo preços e prazos de entrega...')
        cartItems = await KitCreateOrder.appendPrice(cartItems, isPostPaid!)
      }

      const splitItems = () => {
        const ordersToAdd: ShoppingCartOrder[] = []

        cartItems.forEach(createOrder => {
          const config = getServiceConfig(createOrder.selectedService.code!)
          const items = config.convertToCartItems(createOrder)

          items.forEach(item => ordersToAdd.push(item))
        })

        cartItems = ordersToAdd
      }

      const checkKitSimilarOrders = async () => {
        onChangeStatus('Verificando pedidos similares...')

        const promises = cartItems.map(async cartItem => {
          try {
            await checkSimilarOrders(cartItem)
          } catch (error) {
            if (error instanceof SimilarOrdersError) {
              return error.data?.[0] as SimilarOrder
            }

            throw error
          }
        })

        const results = await Promise.allSettled(promises)
        const similarOrders = results.map(({ value }: any) => value).filter(Boolean)

        if (similarOrders.length) {
          const similarOrdersKitItemsIds = similarOrders.map(({ kitItemId }) => kitItemId)
          cartItems = cartItems.filter(item => !similarOrdersKitItemsIds.includes(item.kitItemId))
        }

        return similarOrders
      }

      return addItemsToCartAndRedirectOrReset
    })(),
    [dynamicFields, isPostPaid, kit]
  )

  return {
    addToCart: addKitToCart,
    redirectToCart,
    loading,
  }
}
