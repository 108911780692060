import { Divider } from 'antd'

import { OrderOverview } from 'domain/orders/types'
import { Clipboard, Modal, ModalProps } from 'ui'
 
import Section from './OrderDetailsModalSection'
import ItemsStatus from './OrderDetailsModalItemsStatus'

interface Props extends ModalProps {
  order: OrderOverview
}

function OrderDetailsModal({ order, open, onCancel }: Props) {
  return (
    <Modal
      open={open}
      title={order.name}
      subTitle={<>nº do pedido: <Clipboard text={order.orderNumber} /></>}
      onCancel={onCancel}
      footer={null}
    >
      <Section icon="folder" title="Quantidade de itens" description={order.totalItems} />

      <Divider />

      <ItemsStatus totalByStatus={order.totalItemsByStatus} />

      <Divider />

      <Section icon="calendar" title="Data solicitação" description={order.createdAtDisplay} />

      <Divider />

      <Section icon="user" title="Solicitante" description={order.user.name} />

      <Divider />

      <Section
        icon="money"
        title="Valor da compra"
        description={order.priceQuotationDisplay || order.priceDisplay}
      />
    </Modal>
  )
}

export default OrderDetailsModal
