import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { Routes } from 'app/routes'
import { EditableOrderItemName } from 'domain/orderItem'
import { OrderItemListing } from 'domain/orderItem/types'
import { SelectedItems, StatusCode } from 'domain/orders/types'

import OrderItemStatus from './OrderItemStatus'
import ServiceName from './itemsDetails/ServiceName'
import OrderResult from '../ordersList/OrderResult'
import OrderDetailsInfo from './OrderDetailsInfo'

interface Props {
  items: OrderItemListing[]
  loading: boolean
  selectedIds: number[]
  setSelected: (data: SelectedItems) => void
}

export default function OrderItemsTable({ items, loading, selectedIds, setSelected }: Props) {
  const history = useHistory()

  const columns: ColumnsType<OrderItemListing> = [
    {
      title: 'Nome do item',
      dataIndex: 'name',
      render: (itemName, item) => {
        return (
          <div className="flex flex-row flex-wrap gap-3" onClick={e => e.stopPropagation()}>
            <EditableOrderItemName
              itemId={item.id}
              itemName={
                <Link to={Routes.ViewOrder(item.id)}>
                  <span className="font-bold text-secondary-500 hover:text-secondary-300">{itemName}</span>
                </Link>
              }
              initialValue={itemName}
              ownerId={item.user.id}
              orderId={item.orderId}
            />
          </div>
        )
      },
    },
    {
      title: 'Identificação',
      dataIndex: 'register',
      className: 'min-w-36',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAtDisplay',
    },
    {
      title: 'Item/Tipo',
      dataIndex: 'service',
      render: (_, item) => <ServiceName item={item} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, item) => <OrderItemStatus statusCode={item.status} />,
    },
    {
      title: 'Resultado',
      dataIndex: 'result',
      className: 'min-w-32',
      render: (_, item) => <OrderResult result={item.result} />,
    },
    {
      title: 'Inf. adicionais',
      dataIndex: 'aditionalInfo',
      render: (_, item) => <OrderDetailsInfo item={item} />,
    },
  ]

  const rowSelection = {
    selectedRowKeys: selectedIds,
    hideSelectAll: false,
    preserveSelectedRowKeys: true,
    columnWidth: 36,
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrderItemListing[]) => {
      setSelected({ ids: selectedRowKeys as number[], items: selectedRows })
    },
  }

  return (
    <Table
      columns={columns}
      dataSource={items}
      loading={loading}
      onRow={record => ({
        onClick: () => history.push(Routes.ViewOrder(record.id)),
      })}
      pagination={false}
      rowKey={record => record.id}
      rowClassName={resolveRowStyle}
      rowSelection={rowSelection}
      className="mask-text"
    />
  )
}

const resolveRowStyle = (record: OrderItemListing) => {
  return record.status === StatusCode.ACAO_NECESSARIA ? 'danger' : ''
}
