import React from 'react'
import * as Sentry from '@sentry/browser'
import { ErrorBoundary } from 'react-error-boundary'

import { useLogout } from 'domain/auth'

function CustomErrorBoundary({ children }: { children: React.ReactNode }) {
  const logout = useLogout()

  return (
    <ErrorBoundary
      fallback={
        <div className="p-7 leading-loose flex flex-col items-center" role="alert">
          <h1 className="text-lg font-bold text-red-600 mb-3">Algo deu errado</h1>

          <p className="mb-3 font-bold">Você pode tentar os seguintes procedimentos:</p>

          <ol className="list-decimal ml-4">
            <li>
              <a
                href="https://www.google.com/search?q=como+limpar+o+cache+do+navegador"
                rel="noopener noreferrer"
                target="_blank"
              >
                Limpar o cache do navegador:
              </a>
              <ul className="list-disc ml-4">
                <li>Navegadores Windows e Linux: CTRL + F5</li>
                <li>Apple Safari: SHIFT + botão recarregar barra de ferramentas</li>
                <li>Chrome e Firefox para Mac: CMD + SHIFT + R</li>
              </ul>
            </li>
            <li>
              Fazer login novamente (
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  logout()
                }}
              >
                clique aqui
              </a>
              )
            </li>
            <li>Entre em contato conosco</li>
          </ol>
        </div>
      }
      onError={(error, errorInfo: any) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('error', error)
        } else {
          Sentry.captureException(error)
          Sentry.captureMessage(errorInfo)
        }
      }}
      onReset={details => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default CustomErrorBoundary
