import { Tabs } from 'antd'
import { OpenAIEnrichment } from 'domain/openAI/enrich'
import { useGetOrderItem } from 'domain/orderItem'
import ViewCertificateActions from 'domain/orderItem/certificate/components/ViewCertificateActions'
import ViewCertificateStatus from 'domain/orderItem/certificate/components/ViewCertificateStatus'
import { ViewOrderLayout } from 'domain/orderItem/shared'
import { useMemo, useState } from 'react'
import { ViewPDF } from 'ui'
import ViewCertidaoPreviaMatriculaReport from './ViewCertidaoPreviaMatriculaReport'

export default function ViewCertidaoPreviaMatricula() {
  const { fileURL, ocr, service, extractSummary } = useGetOrderItem()
  const [activeTab, setActiveTab] = useState('report')

  const tabItems = useMemo(() => {
    const items = []

    if (extractSummary) {
      items.push({
        label: 'Ficha',
        key: 'report',
        children: <ViewCertidaoPreviaMatriculaReport />,
      })
    }

    return [
      ...items,
      {
        label: 'Original',
        key: 'original',
        children: (
          <>
            <div className="row flex flex-1 h-full">
              <ViewPDF fileUrl={fileURL!} />,
            </div>
          </>
        ),
      },
      {
        label: 'Extração de Dados',
        key: 'openAI',
        children: <OpenAIEnrichment fileUrl={fileURL!} ocr={ocr!} serviceId={service.id} />,
      },
    ]
  }, [fileURL, ocr, service, extractSummary])

  return (
    <ViewOrderLayout
      sidebarActions={<ViewCertificateActions />}
      showSidebar
    >
      <ViewCertificateStatus>
        <Tabs
          defaultActiveKey={activeTab}
          className="full-content-tabs w-full py-2 bg-white rounded-lg justify-start"
          tabBarStyle={{ marginLeft: '1rem' }}
          items={tabItems}
          onChange={setActiveTab}
        />
      </ViewCertificateStatus>
    </ViewOrderLayout>
  )
}
