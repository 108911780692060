import { Form } from 'antd'
import { AuthorizedAPI } from 'api/network/v2'
import { MAX_FILE_SIZE } from 'app/config/files'
import { RoutePaths } from 'app/routes'
import { TempFile } from 'domain/app/types'
import { getFieldName } from 'domain/createOrder/shared'
import { useState } from 'react'
import { UploadDragger } from 'ui'
import { FieldConfig } from '../types'

interface Props {
  field: FieldConfig
  itemId?: number | string
  onChange?: (files: TempFile[]) => void
  required?: boolean
  hideLabel?: boolean
}

export default function UploadField({ field, itemId, onChange, required = false, hideLabel = false }: Props) {
  const [fileList, setFileList] = useState([] as TempFile[])
  const name = getFieldName(field.name, itemId)

  const customRequest = async ({ file, onError, onProgress, onSuccess }: any) => {
    try {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event: any) => {
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }

      const form = new FormData()
      form.append('files[]', file)

      const { data } = await AuthorizedAPI.post<string[]>(RoutePaths.UPLOAD, form, config)
      const uploadId = data[0]

      if (uploadId) {
        const index = fileList.findIndex(o => o.uid === file.uid)

        if (index > -1) {
          file.fileUploadId = uploadId
          onSuccess(file)
        }
      }
    } catch (error) {
      onError(error)
    }
  }

  return (
    <Form.Item
      name={name}
      label={hideLabel ? undefined : field.label}
      rules={required ? requiredRules : undefined}
    >
      <UploadDragger
        fileList={fileList}
        setFileList={setFileList}
        accept=".pdf,.png,.jpg,.jpeg"
        customRequest={customRequest}
        maxFileFize={MAX_FILE_SIZE}
        maxCount={20}
        className="mb-5"
        onChange={onChange}
      />
    </Form.Item>
  )
}

const requiredRules = [{ required: true, message: 'Campo obrigatório.' }]
