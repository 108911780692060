import React from 'react'
import { useHistory } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { useHasPermission } from 'domain/auth'
import { AlertFullPage, Button, Icon } from 'ui'

interface Props {
  title: string
  description?: string
  className?: string
  children?: React.ReactNode
  icon?: React.ReactNode
}

export default function AlertWithOrderButton({
  title,
  description,
  children,
  className = 'w-48 mx-auto',
  icon,
}: Props) {
  const history = useHistory()
  const canOrderServices = useHasPermission('canOrderServices')

  return (
    <AlertFullPage title={title} icon={icon} description={description}>
      {canOrderServices && (
        <Button
          size="middle"
          type="primary"
          className={className}
          block
          onClick={() => history.push(RoutePaths.CREATE_ORDER)}
        >
          <div className="w-full flex items-center justify-center">
            <div>Fazer pedido</div> <Icon name="paper-plane" className="text-xl ml-2" />
          </div>
        </Button>
      )}

      {children}
    </AlertFullPage>
  )
}
