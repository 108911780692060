import React from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { Button, ButtonProps } from 'ui'

interface Props extends Omit<ButtonProps, 'icon'> {
  to?: string
  state?: unknown
  hideLabel?: boolean
  onClick?: () => void
}

interface StateProps {
  redirectTo?: RoutePaths
}

export default function GoBackButton({ to, onClick, state, hideLabel, ...props }: Props) {
  const history = useHistory()
  const location = useLocation<StateProps>()

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (to) {
      history.push(to, state)
    } else if (location.state?.redirectTo) {
      history.push(location.state.redirectTo)
    } else {
      history.goBack()
    }
  }

  return (
    <Button icon="arrow-left" size="small" type="link" onClick={handleClick} {...props}>
      {!hideLabel && 'Voltar'}
    </Button>
  )
}
