import React, { useReducer } from 'react'
import { Button } from 'antd'

import { OrderOverview } from 'domain/orders/types'

import OrderDetailsModal from './OrderDetailsModal/OrderDetailsModal'
import { Icon } from 'ui'

function OrderDetails({ order }: { order: OrderOverview }) {
  const [openModal, toggleModal] = useReducer(prev => !prev, false)

  return (
    <>
      <Button type="link" onClick={toggleModal} className="inline-flex">
        <Icon name="menu-left" className="mr-2" size={18} />
        Detalhes do pedido
      </Button>

      <OrderDetailsModal order={order} open={openModal} onCancel={toggleModal} />
    </>
  )
}

export default OrderDetails
