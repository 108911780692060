import React from 'react'
import { Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload'

import { SpreadsheetError } from 'domain/createOrder/types'
import Icon from 'ui/Icon'

const { Dragger } = Upload

interface Props extends DraggerProps {
  error: SpreadsheetError | undefined
}

function SpreadsheetDragger({ error, ...props }: Props) {
  return (
    <Dragger name="spreadsheet" showUploadList={showUploadList} className="bg-white p-2" {...props}>
      {error ? (
        <>
          <Icon name="alert" className="text-5xl text-gray-500 mb-3" />
          <p className="text-xs text-gray-600 mb-3">
            Não foi possível fazer o upload da planilha. Por favor, tente novamente.
          </p>

          {error.message.map((text, index) => (
            <p key={index} className="text-xs text-red-500 mb-2">
              {text}
            </p>
          ))}
        </>
      ) : (
        <>
          <Icon name="file-xls" className="text-5xl text-gray-500 mb-3" />
          <p className="text-xs text-gray-600">Arraste sua planilha para cá</p>
          <p className="text-xs my-2 text-center">ou</p>
          <p className="text-xs text-primary underline text-center">Escolha um arquivo do seu computador</p>
        </>
      )}
    </Dragger>
  )
}

const showUploadList = {
  showDownloadIcon: true,
  downloadIcon: <Icon name="checkmark-circle" className="text-green-500 mr-2" />,
  showRemoveIcon: true,
  removeIcon: <Icon name="trash" />,
}

export default SpreadsheetDragger
