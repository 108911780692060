import { Form } from 'antd'

import { LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { ExtraInfoRadioGroup, RadioGroupFieldProps } from 'domain/servicesCBRdoc/fields'
import config from '../certidaoJusticaEstadualConfig'

interface Props extends Omit<RadioGroupFieldProps, 'field'> {
  service: SelectedService
  federativeUnit: string
  legalEntity: LegalEntity | undefined
  instance?: string | null
  comarca?: string
}

function ModelField({
  service,
  federativeUnit,
  legalEntity,
  itemId,
  instance,
  comarca,
  onChange,
  ...props
}: Props) {
  const form = Form.useFormInstance()

  const params: any = {
    tipo_pessoa: legalEntity,
    url_uf: federativeUnit,
  }

  if (federativeUnit === 'PI') {
    params.instancia = instance
  }

  if (federativeUnit === 'PR') {
    params.comarca = comarca
  }

  return (
    <ExtraInfoRadioGroup
      field={modelo}
      itemId={itemId}
      service={service}
      params={params}
      onChange={value => {
        const notaryField = itemId !== undefined ? `${itemId}[${url_cartorio.name}]` : url_cartorio.name
        form.setFieldValue(notaryField, undefined)
        onChange && onChange(value)
      }}
      {...props}
    />
  )
}

const { url_cartorio, modelo } = config.dynamicFields!

export default ModelField
