import { EntityTypeTabs } from 'domain/servicesCBRdoc/fields'
import { useGetKit, useSetLegalEntity } from '../../state'

export default function LegalEntityField() {
  const kit = useGetKit()
  const setLegalEntity = useSetLegalEntity()

  if (kit.legalEntity === 'indefinido' && kit.hasLegalEntity) {
    return <EntityTypeTabs onChange={setLegalEntity as any} />
  }

  return null
}
