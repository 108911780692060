import { CreateOrderState } from 'domain/createOrder/types'
import { Research } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type PesquisaDividaAtivaPGEFields = Record<
  FieldName.INSCRICAO | FieldName.TIPO | FieldName.URL_UF,
  FieldConfig
>

type PesquisaDividaAtivaPGEDynamicFields = Record<
  FieldName.CPF | FieldName.CNPJ | FieldName.INSCRICAO_ESTADUAL,
  FieldConfig
>

const { INSCRICAO, TIPO, URL_UF } = FieldName

class PesquisaDividaAtiva extends Research {
  formFields: PesquisaDividaAtivaPGEFields = {
    url_uf: Fields.url_uf,
    tipo: {
      ...Fields.tipo,
      labelInValue: true,
    },
    inscricao: {
      ...Fields.inscricao,
      ignoreOnKitsAutomaticForm: true,
    },
  }
  dynamicFields: PesquisaDividaAtivaPGEDynamicFields = {
    cpf: {
      ...Fields.cpf,
      apiName: INSCRICAO,
    },
    cnpj: {
      ...Fields.cnpj,
      apiName: INSCRICAO,
    },
    inscricao_estadual: {
      ...Fields.inscricao_estadual,
      apiName: INSCRICAO,
      legalEntity: undefined,
    },
  }

  constructor() {
    super({
      description:
        'Consulta dados de débitos de pessoas físicas ou jurídicas junto à Fazenda Pública, através do número da certidão de dívida ativa (CDA), CNPJ, CPF, Inscrição Estadual ou Renavam.',
      extraInfoFields: [URL_UF],
      id: 52,
      legalEntity: 'indefinido',
      orderNameField: INSCRICAO,
      orderNamePrefixField: TIPO,
      priceByFields: [TIPO, URL_UF],
      shortName: 'Pesquisa PGE - Dívida Ativa',
    })
  }

  protected getSubmitData = (createOrder: CreateOrderState) => {
    const { formData } = createOrder
    const { name, tipo: tipoSingle } = formData['0']
    //quando for de kit o tipo vem antes e eh uma string
    const { tipo: tipoKit } = formData
    let tipo = tipoSingle ? tipoSingle : tipoKit
    tipo = typeof tipo === 'string' ?  tipo : tipo.value
    const inscricaoField = tipo === 'cpf' ? 'cpf' : tipo === 'cnpj' ? 'cnpj' : 'inscricao'

    return {
      formato: 'email',
      inscricao: formData['0'][inscricaoField],
      name,
      tipo: tipo,
      url_uf: formData.url_uf.value,
    }
  }
}

const service = new PesquisaDividaAtiva().initialize()

export default service
