import React from 'react'

import { LegalEntity } from 'domain/app/types'
import { Alert } from 'ui'

interface Props {
  entityType: LegalEntity | undefined
  federativeUnit: string | undefined
  instance: string | undefined
}

function AlertDocumentsRequired({ entityType, federativeUnit, instance }: Props) {
  const showWarning = federativeUnit === 'RJ' && instance === 'segundo-grau'

  if (!showWarning) return null

  return <Alert type="warning" description={Message[entityType ?? 'fisica']} message="Atenção" />
}

const Message: { [key in LegalEntity]: string } = {
  juridica:
    'Para solicitação de certidões de segundo grau no RJ, é obrigatória a cópia do contrato social ou ato constitutivo da empresa, cópia do cadastro nacional de PJ extraído do site da Receita Federal, procuração assinada por um dos sócios ou administrador responsável constante no Contrato ou Ato e RG e CPF do sócio ou administrador',
  fisica:
    'Para solicitação de certidões de segundo grau no RJ, é obrigatória a cópia de RG e CPF do requerente ou procuração/autorização contendo assinatura do requerente',
}

export default AlertDocumentsRequired
