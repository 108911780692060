import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily, atomWithStorage } from 'jotai/utils'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { AllItemsDynamicFieldsConfig } from '../types'

export const KIT_ORDER_DYNAMIC_FIELDS = 'kit_order_dynamic_fields2'

export const dynamicFieldsAtom = atomWithStorage<AllItemsDynamicFieldsConfig>(KIT_ORDER_DYNAMIC_FIELDS, {})

const serviceDynamicFields = atomFamily((kitItemId: number) =>
  atom(
    get => get(dynamicFieldsAtom)[kitItemId],
    (get, set, newValue: FieldConfig[]) => {
      const prev = get(dynamicFieldsAtom)
      set(dynamicFieldsAtom, { ...prev, [kitItemId]: newValue })
    }
  )
)

export const useGetAllDynamicFields = () => useAtomValue(dynamicFieldsAtom)
export const useGetDynamicFields = (kitItemId: number) => useAtomValue(serviceDynamicFields(kitItemId))
export const useSetDynamicFields = (kitItemId: number) => useSetAtom(serviceDynamicFields(kitItemId))
