import React from 'react'

import MaskedInput, {MaskedInputProps} from './MaskedInput'

interface Props extends Omit<MaskedInputProps, 'mask' | 'maskOptions' | 'type'> {
  label?: string
  defaultValue?: string
  value?: string
}

function PhoneInput({ label = 'Telefone', ...props }: Props) {
  return <MaskedInput type="tel" label={label} mask={masks} maskOptions={maskOptions} {...props} />
}

const masks = [
  {
    mask: '(00) 0 0000-0000',
    lazy: false,
  },
  {
    mask: '(00) 0000-0000',
    lazy: false,
  },
]

const maskOptions = {
  dispatch: function (_: any, dynamicMasked: any) {
    const digit = dynamicMasked.unmaskedValue[2]
    const isCellPhone = digit === '9' || digit === '8'
    return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1]
  },
}

export default PhoneInput
