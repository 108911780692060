import { useRef } from 'react'
import { Spin } from 'antd'
import { useParams } from 'react-router-dom'

import { FreeAnalysisPopup } from 'domain/openAI/enrich'
import { OrderOverview as OrderOverviewType } from 'domain/orders/types'
import { OrderOverviewPageParams } from 'domain/orders/types'
import { useOrderItemsQuery } from 'domain/orders/services'
import { useGetOverviewFilters } from 'domain/orders/state/filtersOverview'
import { useGetOverviewTotalResults } from 'domain/orders/state/pagination'
import { useGetIsRedirecting } from 'domain/orders/state/redirecting'
import { BasicLayout, GoBackButton } from 'layouts'
import { ActionsBar } from 'ui'
import { removeFalsyEntries } from 'utils/transformData'

import useOverviewInitialValuesFromURL from '../../state/useOverviewInitialValuesFromURL'
import AlertNoOrdersWithSelectedFilters from '../AlertNoOrdersWithSelectedFilters'
import OrderOverviewFilters from './filters/OrderOverviewFilters'
import CountText from './OrderItemsCountText'
import OrderActions from './OrderActions'
import OrderPriceQuotation from './actions/OrderPriceQuotation'
import OrderDetails from './OrderDetails'
import OrderItemsList from './OrderOverviewList'
import OrderOrigin from './OrderOrigin'

interface Props {
  order: OrderOverviewType
}

export default function OrderOverview({ order }: Props) {
  const topRef = useRef<HTMLDivElement>(null)
  const isRedirecting = useGetIsRedirecting()
  const filters = useGetOverviewFilters()
  const params = useParams<OrderOverviewPageParams>()
  const hasFilterApplied = removeFalsyEntries(filters).length > 0
  const overviewTotalResults = useGetOverviewTotalResults()

  useOverviewInitialValuesFromURL()

  const { data: orderItems, isFetching, isLoading } = useOrderItemsQuery(params.id)

  return (
    <BasicLayout
      pageTitle={order.name}
      subTitle={
        <>
          <div className="flex flex-row gap-4 items-center">
            <span className="md:min-w-fit mt-1">Nº do Pedido: {order.orderNumber}</span>
            <CountText />
          </div>
          <OrderOrigin order={order} />
        </>
      }
      innerClass="flex-col"
    >
      <div ref={topRef} />

      <ActionsBar>
        <GoBackButton />
        <OrderDetails order={order} />
      </ActionsBar>

      <main className="p-7 flex-1">
        <Spin spinning={isFetching || isRedirecting}>
          <div className="flex flex-wrap flex-col gap-2 justify-between items-start">
            <OrderActions order={order} />
            <OrderOverviewFilters orderType={order.type} />
          </div>

          {overviewTotalResults === 0 && hasFilterApplied ? (
            <AlertNoOrdersWithSelectedFilters />
          ) : (
            <OrderItemsList items={orderItems!} isLoading={isLoading} topRef={topRef} />
          )}
        </Spin>

        <OrderPriceQuotation order={order} />
      </main>

      {order.hasConsumedAiFreeAnalysis && <FreeAnalysisPopup position="top" />}
    </BasicLayout>
  )
}
