import React from 'react'
import { ColumnsType } from 'antd/lib/table'

import { OrderList } from 'domain/orders/types'
import { Avatar } from 'ui'

import OrderStatus from './OrderStatus'
import LinkToOrder from './LinkToOrder'
import EditOrderNameButton from './actions/EditOrderNameButton'
import OrderDetailsInfo from './OrderInfo'

const tableColumns: ColumnsType<OrderList> = [
  {
    title: 'Nº Pedido',
    dataIndex: 'orderNumber',
    width: 120,
    render: orderNumber => {
      return <div className="flex flex-row gap-3">{orderNumber}</div>
    },
  },
  {
    title: 'Nome do Pedido',
    dataIndex: 'name',
    className: 'min-w-max',
    render: (name, order) => {
      return (
        <div onClick={e => e.stopPropagation()} className="flex flex-row items-center gap-3 group">
          <LinkToOrder orderId={order.id}>
            <span className="font-bold">{name}</span>
          </LinkToOrder>
          <EditOrderNameButton order={order} />
        </div>
      )
    },
  },
  {
    title: 'Criado em',
    dataIndex: 'createdAtDisplay',
    width: 106,
  },
  {
    title: 'Responsável',
    dataIndex: ['user', 'name'],
    key: 'user',
    className: 'text-center',
    render: (username: string) => {
      return (
        <Avatar username={username} title={username} size={40} className="flex align-center justify-center" />
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    className: 'text-start',
    render: (_, order) => (
      <OrderStatus
        totalItems={order.totalItems}
        totalFinalized={order.totalConcludedItems}
        statusCode={order.statusCode}
      />
    ),
  },
  {
    title: 'Inf. Adicionais',
    dataIndex: 'aditionalInfo',
    render: (_, order) => <OrderDetailsInfo order={order} />,
  },
]

export default tableColumns
