import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { RoutePaths } from 'app/routes'
import { useIsCustomerPostPaid } from 'domain/customers'
import { shoppingCartStateActions } from 'domain/shoppingCart'

interface Params {
  orderId: number
  disable?: boolean
}

export default function AddItemsToOrderButton({ orderId, disable = false }: Params) {
  const dispatch = useDispatch()
  const history = useHistory()
  const isPostPaid = useIsCustomerPostPaid()

  const handleAddItems = () => {
    dispatch(shoppingCartStateActions.setOrderId(orderId))
    history.push(RoutePaths.CREATE_ORDER)
  }

  if (!isPostPaid || disable) {
    return null
  }

  return (
    <Button type="primary" ghost onClick={handleAddItems}>
      Adicionar mais itens ao pedido
    </Button>
  )
}
