import { useState } from 'react'
import { useQuery } from 'react-query'
import { Form, Input, Spin } from 'antd'
import { Rule } from 'antd/lib/form'
import Axios from 'axios'

import { FederativeUnitSelect, ZipCodeInput } from 'ui'
import { FieldName } from '../types'


interface Props {
  autoFocus?: boolean
  required?: boolean
  itemId?: number | string
}

export default function AddressFields({
  autoFocus = true,
  required = false,
  itemId,
}: Props) {
  const form = Form.useFormInstance()
  const [cep, setCep] = useState('')

  const getFieldName = (field: keyof typeof fieldNames) => {
    return itemId !== undefined
      ? [itemId, fieldNames[field]]
      : fieldNames[field]
  }

  const addressViaCep = useQuery(
    ['address-via-cep', cep],
    async () => {
      const response = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      return response.data
    },
    {
      enabled: cep.length === 8,
      onSuccess: data => {
        if (data) {
          const fields = {
            [fieldNames.street]: data.logradouro,
            [fieldNames.city]: data.localidade,
            [fieldNames.state]: data.uf,
            [fieldNames.neighborhood]: data.bairro,
          }

          form.setFieldsValue(itemId !== undefined ? { [itemId]: fields } : fields)
        }
      },
    }
  )  

  return (
    <>
      <ZipCodeInput
        name={getFieldName('zipCode')}
        label="CEP"
        size="middle"
        required
        onChange={(value: string) => {
          if (value.length === 8) {
            setCep(value)
          }
        }}
        autoFocus={autoFocus}
      />

      <Spin spinning={addressViaCep.status === 'loading'}>
        <Form.Item
          name={getFieldName('street')}
          label="Endereço"
          rules={requiredAddress}
          required={required}
        >
          <Input maxLength={120} />
        </Form.Item>

        <Form.Item
          name={getFieldName('number')}
          label="Número"
          rules={requiredNumber}
          className="w-40"
          required={required}
        >
          <Input maxLength={8} />
        </Form.Item>

        <Form.Item name={getFieldName('complement')} label="Complemento">
          <Input maxLength={60} />
        </Form.Item>

        <Form.Item
          name={getFieldName('neighborhood')}
          label="Bairro"
          rules={requiredNeighborhood}
          required={required}
        >
          <Input maxLength={80} />
        </Form.Item>

        <Form.Item name={getFieldName('city')} label="Cidade" rules={requiredCity} required={required}>
          <Input maxLength={120} />
        </Form.Item>

        <FederativeUnitSelect name={getFieldName('state')} labelInValue={false} required />
      </Spin>
    </>
  )
}

const {
  CEP,
  ENDERECO_BAIRRO,
  ENDERECO_CIDADE,
  ENDERECO_COMPLEMENTO,
  ENDERECO_ESTADO,
  ENDERECO_LOGRADOURO,
  ENDERECO_NUMERO,
} = FieldName

export const fieldNames = {
  zipCode: CEP,
  street: ENDERECO_LOGRADOURO,
  city: ENDERECO_CIDADE,
  complement: ENDERECO_COMPLEMENTO,
  state: ENDERECO_ESTADO,
  neighborhood: ENDERECO_BAIRRO,
  number: ENDERECO_NUMERO
}

const requiredAddress = [
  {
    required: true,
    message: 'Informe o endereço.',
  },
]

const requiredNumber: Rule[] = [
  {
    required: true,
    message: 'Informe o número.',
  },
  () => ({
    validator: (_, value: string) => {
      if (value.length > 8) return Promise.reject('Número muito grande.')

      return Promise.resolve()
    },
  }),
]

const requiredNeighborhood = [
  {
    required: true,
    message: 'Informe o bairro.',
  },
]

const requiredCity = [
  {
    required: true,
    message: 'Informe a cidade.',
  },
]
