import { Form } from 'antd'
import { useHistory } from 'react-router-dom'

import { Routes } from 'app/routes'
import { Fields } from 'domain/servicesCBRdoc'
import { TextField } from 'domain/servicesCBRdoc/fields'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { Section } from 'layouts'
import { SubmitButton } from 'ui'

import { useGetKit, useSaveServiceFormOnLocalStorage, useServicesForm } from '../state'
import { KitOrderStepURI } from '../types'
import ServicesFormFields from './fields/KitOrderServiceFormFields'
import LegalEntityField from './fields/LegalEntityField'

export default function KitOrderServicesStep() {
  const [form] = Form.useForm()
  const kit = useGetKit()
  const [initialValues, setServicesForm] = useServicesForm()
  const saveOnLocalStorage = useSaveServiceFormOnLocalStorage()
  const history = useHistory()

  const onFinish = (formServicesData: any) => {
    setServicesForm(formServicesData)
    history.push(Routes.CreateOrderKit(kit.id, KitOrderStepURI.COMMON_DATA))
  }

  return (
    <Section heading="Dados de seu Kit de Certidões">
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={saveOnLocalStorage}
        requiredMark={false}
        scrollToFirstError
      >
        <LegalEntityField />
        <TextField field={Fields[FieldName.NOME_ITEM_PEDIDO]} />

        {kit.items.map((item, index) => {
          return <ServicesFormFields key={item.id} kitItem={item} autoFocus={index === 0} />
        })}

        <hr className="my-5" />

        <SubmitButton label="Continuar" />
      </Form>
    </Section>
  )
}
