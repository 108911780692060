import React from 'react'

import Button from './Button'

export default function DownloadButton({ fileURL }: { fileURL: string | undefined | null }) {
  return (
    <Button
      type="link"
      icon="download"
      href={fileURL ?? '#'}
      target="_blank"
      className="no-underline text-gray-700 font-bold"
      disabled={!fileURL}
    >
      Baixar
    </Button>
  )
}
