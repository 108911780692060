import { useAddToCart } from 'domain/createOrder/shared/services'
import { CreateOrderState } from 'domain/createOrder/types'
import { useIsCustomerPostPaid } from 'domain/customers'
import { OrderItem, ResearchResult } from 'domain/orderItem/types'
import ResearchResultOrder from '../classes/ResearchResultOrder'

function useAddResearchResultToCart() {
  const addToCart = useAddToCart()
  const isPostPaid = useIsCustomerPostPaid()

  const addResearchResultToCart = async (result: ResearchResult, orderItem: OrderItem) => {
    const createOrder = await new ResearchResultOrder(orderItem, result).generate(isPostPaid!)

    if (createOrder) {
      addToCart(createOrder as CreateOrderState)
    }
  }

  return addResearchResultToCart
}

export default useAddResearchResultToCart
