import { LegalEntity } from 'domain/app/types'
import { StorageUsage } from 'domain/myFiles/types'

export type ProfileAPI = {
  address_neighborhood: string
  address_public_place: string
  address_number: string
  address_city: string
  address_uf: string
  address_complement: string
  address_zip_code: string
  backoffice_email: string
  phone: string
}

export interface CustomerAPI extends ProfileAPI {
  ai_enabled: 0 | 1
  analytics_enabled_until: string | null
  auto_purchase_certificate_from_research_result_available: 0 | 1
  auto_purchase_certificate_from_research_result_default: 0 | 1
  corporate_name: string
  demands_approval_purchases_over_value?: number
  document_number: string
  entity_type: 'PJ' | 'PF'
  explorer_item_file_upload_enabled: boolean
  group_mandatory_on_purchase: boolean
  id: number
  max_users: number
  name: string
  postpaid: boolean
  used_storage?: StorageUsage
  users_count?: number
}

export enum CanAutoPurchaseResearchOption {
  NO = 'no',
  YES = 'yes',
  YES_DEFAULT = 'yes-default'
}

type CustomerPermissions = {
  autoPurchaseFromResearch: CanAutoPurchaseResearchOption
  canUploadFiles: boolean
  canUseAI: boolean
}

export interface Customer {
  address: string
  addressNumber: string
  addressComplement?: string
  addressNeighborhood: string
  addressCity: string
  addressState: string
  addressZipcode: string
  analyticsEnabled: boolean
  analyticsUntil: Date | null
  cnpj?: string
  cpf?: string
  companyName: string
  contactPerson: string
  email: string
  entityType: LegalEntity
  id: number
  isPostpaid: boolean | undefined
  maxUsers: number
  orderGroupRequired: boolean
  permissions: CustomerPermissions
  phone: string
  usersCount: number
  valueForOrderApproval: number
}

export type ProfileForm = Pick<
  Customer,
  | 'companyName'
  | 'contactPerson'
  | 'cpf'
  | 'cnpj'
  | 'address'
  | 'addressNumber'
  | 'addressComplement'
  | 'addressNeighborhood'
  | 'addressCity'
  | 'addressState'
  | 'addressZipcode'
> & { phone?: string }

export interface UseCustomerQueryParams {
  expand?: 'account_balance'
  refreshDeps?: any
}

export interface CustomerWithBalanceAPI extends CustomerAPI {
  account_balance?: number
}

export interface CreateAccountForm {
  corporate_name: string
  email: string
  name: string
  phone: string
  password: string
  password_repeat: string
  question_services: string
}

export interface CreateAccountAPI {
  customer: {
    entity_type: 'PJ' | 'PF'
    corporate_name: string
    document_number: string
  }
  quiz: {
    question_services: string
  }
  user: {
    email: string
    name: string
    password: string
    phone: string
  }
}
