import React, { useState } from 'react'

import { errorHandler } from 'api/errors'
import { useRedoOrder } from 'domain/createOrder/redo'
import { OrderItem } from 'domain/orderItem/types'
import { Button } from 'ui'

interface Props {
  orderItem: OrderItem
}

export default function RedoOrderButton({ orderItem }: Props) {
  const [loading, setLoading] = useState(false)
  const addToCart = useRedoOrder()

  const handleClick = async () => {
    try {
      setLoading(true)
      await addToCart(orderItem)
    } catch (error) {
      setLoading(false)
      errorHandler(error, {
        code: '0x789',
      })
    }
  }

  return (
    <Button
      type="link"
      icon="repeat"
      className="text-gray-700 font-bold"
      onClick={handleClick}
      size="small"
      loading={loading}
    >
      Refazer pedido
    </Button>
  )
}
