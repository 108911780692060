import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Dropdown, Form, Menu, Select } from 'antd'
import { SelectProps } from 'antd/lib/select'

import Icon from 'ui/Icon/Icon'

interface Props extends SelectProps<any> {
  label?: string
  name: string
  multipleLimit?: number
  placeholder?: string
  noStyle?: boolean
  required?: boolean
  options: any[] | undefined
}

function LineSelect({
  multipleLimit = 1,
  label,
  placeholder,
  name,
  options,
  required,
  mode,
  fieldNames,
  ...props
}: Props) {
  const selectedData = Form.useWatch(name)
  const selectRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(false)

  const validOptions = useMemo(() => {
    if (mode !== 'multiple' || !selectedData || selectedData.length < multipleLimit) {
      return options
    }

    return options?.map(option => ({
      ...option,
      disabled: !selectedData.some((selected: any) => selected.value === option[fieldNames!.value ?? 'value']),
    }))
  }, [options, selectedData, mode, multipleLimit])

  const DropdownMenu = () => {
    const items = [
      {
        label: (
          <>
            {label && <div className="text-gray-600 text-xs mb-2">{label}</div>}
            <Form.Item name={name} rules={required ? requiredRule : undefined} {...props}>
              <Select
                ref={selectRef}
                showSearch
                showAction={['focus', 'click']}
                options={validOptions}
                fieldNames={fieldNames}
                onBlur={() => setIsOpen(false)}
                className="w-full"
                mode={mode}
                labelInValue
              />
            </Form.Item>
          </>
        ),
        key: '0',
      },
    ]

    return (
      <Menu
        items={items}
        selectable
        className="w-full min-w-56 bg-white"
        onClick={e => e.domEvent.stopPropagation()}
      />
    )
  }

  useEffect(() => {
    if (mode === 'multiple' && selectedData && selectedData.length === multipleLimit) {
      setIsOpen(false)
    }
  }, [selectedData, mode, multipleLimit])

  return (
    <Dropdown dropdownRender={DropdownMenu} trigger={['click']} open={isOpen} onOpenChange={setIsOpen}>
      <a
        onClick={e => e.preventDefault()}
        className="font-normal text-sm text-primary border-b border-gray-500 pl-2 cursor-pointer"
      >
        <ViewSelectedData data={selectedData} placeholder={placeholder} />{' '}
        <Icon name="chevron-down" className="ml-2 text-sm text-primary" />
      </a>
    </Dropdown>
  )
}

function ViewSelectedData({ data, placeholder }: any) {
  if (!data || data.length === 0) {
    return <span className="font-normal text-gray-500">{placeholder}</span>
  }

  if (Array.isArray(data)) {
    return (
      <span>
        {data[0].label}
        {data.length > 1 && <span>, +{data.length - 1}</span>}
      </span>
    )
  }

  return <span>{data.label}</span>
}

const requiredRule = [{ required: true, message: 'Campo obrigatório' }]

export default LineSelect
