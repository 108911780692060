import React, { useMemo } from 'react'
import { Spin, Tabs } from 'antd'

import { useResetCreateOrder } from 'domain/createOrder/document'
import { sortServiceByFavorites } from 'domain/createOrder/shared/helpers'
import { CategoryTabLabel, useAvailableServicesQuery } from 'domain/servicesCBRdoc'
import { CategoryAPI, ServiceAPI, ServiceType, ServiceCode, isServiceCode } from 'domain/servicesCBRdoc/types'
import ServiceTabsItems from './ServiceTabsItems'

interface ServiceTabsProps {
  type: ServiceType
  children?: React.ReactNode
}

export default function ServiceTabs({ type, children }: ServiceTabsProps) {
  const { data: categories, isLoading } = useAvailableServicesQuery(type)

  useResetCreateOrder()

  if (!categories) return <Spin spinning />

  return (
    <Spin spinning={isLoading}>
      <ShowServiceTabs categories={categories} type={type} />
      {children}
    </Spin>
  )
}

function ShowServiceTabs({ categories, type }: Props) {
  const tabs = useMemo(() => {
    const tabItems: any = categories.map(category => ({
      label: <CategoryTabLabel category={category} />,
      key: category.code,
      children: <ServiceTabsItems type={type} category={category} services={category.services} />,
    }))

    tabItems.unshift({
      label: 'Todos',
      key: 'all-services',
      children: <AllServicesTab type={type} categories={categories} />,
    })

    const hasJustOneCategory = tabItems.length === 2

    if (hasJustOneCategory) {
      tabItems.pop()
    }

    return tabItems
  }, categories)

  if (tabs.length === 1) {
    return tabs[0].children
  }

  return (
    <Tabs items={tabs} className="[&>div.ant-tabs-nav]:bg-gray-200 [&>div.ant-tabs-nav]:pt-2 [&>div.ant-tabs-nav]:px-7" />
  )
}

function AllServicesTab({ type, categories }: Props) {
  const allServices = useFlatAllServices(categories)

  return <ServiceTabsItems type={type} services={allServices} autoFocus />
}

function useFlatAllServices(categories: CategoryAPI[]) {
  return useMemo(() => {
    const services: ServiceAPI[] = []
    const servicesAlreadyAdded: any = {}

    categories.forEach(category => {
      category.services.forEach(service => {
        const serviceExists = isServiceCode(service.code)
        if (!servicesAlreadyAdded[service.code] && serviceExists) {
          services.push(service)
          servicesAlreadyAdded[service.code] = true
        }
      })
    })

    return services.sort(sortServiceByFavorites)
  }, [categories])
}

interface Props extends ServiceTabsProps {
  categories: CategoryAPI[]
  loading?: boolean
}
