import { useEffect, useLayoutEffect, useState } from 'react'

export const SMALL_WIDTH_BREAKPOINT = 1490

export const useViewWidth = () => {
  const [viewWidth, setViewWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [viewWidth])
  return viewWidth
}

export const useIsSmallDevice = () => {
  const width = useViewWidth()
  const [isSmallDevice, setIsSmallDevice] = useState(false)

  useEffect(() => {
    setIsSmallDevice(width < SMALL_WIDTH_BREAKPOINT)
  }, [width])

  return isSmallDevice
}

export function useScrollTo(id: string) {
  useLayoutEffect(() => {
    document.getElementById(id)!.scroll(0, 0)
  }, [])
}
