import React from 'react'

import {
  PrintButton,
  ReportProblemButton,
  ShareOrderButton,
  SidebarContainer,
  useGetOrderItem,
} from 'domain/orderItem/shared'
import { DownloadButton } from 'ui'

function ViewDocRegistrationActions() {
  const orderItem = useGetOrderItem()
  const { id, fileURL } = orderItem

  const actions = [
    { label: <ShareOrderButton itemId={id} fileURL={fileURL} />, key: 'share' },
    { label: <PrintButton fileURL={fileURL} />, key: 'print' },
    { label: <ReportProblemButton itemId={id} />, key: 'problem' },
  ]

  return <SidebarContainer downloadButton={<DownloadButton fileURL={fileURL} />} actions={actions} />
}

export default ViewDocRegistrationActions
