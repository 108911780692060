import React from 'react'
import { FormInstance } from 'antd'
import { useDispatch } from 'react-redux'

import { SelectLocationFields, createOrderActions } from 'domain/createOrder/document'
import config from '../certidaoJusticaEstadualConfig'

interface Props {
  form: FormInstance
}

export default function CreateOrderCertidaoJusticaEstadualSelectFederativeUnit({ form }: Props) {
  const dispatch = useDispatch()

  return (
    <SelectLocationFields
      onChange={() => {
        form.setFieldsValue({
          [comarca.name]: undefined,
          [modelo.name]: undefined,
          [url_cartorio.name]: undefined,
          [instancia.name]: undefined,
        })

        dispatch(createOrderActions.setSelectedInstance(undefined))
        dispatch(createOrderActions.setSelectedModel(undefined))
        dispatch(createOrderActions.setSelectedComarca(undefined))
        dispatch(createOrderActions.setSelectedNotaries(undefined))
      }}
      autoFocus
    />
  )
}

const { comarca, modelo, instancia, url_cartorio } = config.dynamicFields
